import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';

import Label from '../Label';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30,
    alignItems: 'center',
  },
  labelWrapper: {
    flex: '1 0 auto',
    marginRight: 'auto',
  },
  input: {
    flex: '0 0 40px',
    width: '50px',
    height: '35px',
    margin: '0 10px',

    '& input': {
      appearance: 'textfield',
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 300,
      width: '50px',
      height: '35px',
      paddingLeft: 5,
      paddingRight: 5,

      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0,
      },
    },
  },
}));

const SmallNumberField = ({ name, id, label, values, suffix, ...props }) => {
  const classes = useStyles();

  const value = values[name];

  return (
    <FormControl fullWidth className={classes.container}>
      <div className={classes.labelWrapper}>
        {/* eslint-disable-next-line */}
        <Label grey id={id} label={label} />
      </div>

      <OutlinedInput
        classes={{
          root: classes.input,
        }}
        id={id}
        name={name}
        type="number"
        value={value === undefined ? '' : value}
        inputmode="numeric"
        {...props}
      />

      {suffix && <label htmlFor={id}>{suffix}</label>}
    </FormControl>
  );
};

SmallNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  values: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  suffix: PropTypes.func.isRequired,
};

export default SmallNumberField;
