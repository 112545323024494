import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import TextLink from '../TextLink';

import { ReactComponent as ClarityLogo } from '../../assets/clarity-logo.svg';

import partners from '../../config/partners';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    margin: '50px 0 20px',
  },
  logos: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& > svg': {
      height: '68px',
      display: 'block',
      margin: '0 10px',
    },
  },
  title: {
    fontFamily: 'DM Serif Display',
    fontWeight: 'normal',
    fontSize: '35px',
    lineHeight: '42px',
  },
  text: {
    fontSize: '18px',
    lineHeight: '30px',
  },
}));

const WelcomeText = ({ partner, isLoggedIn, userName, hideHowDoesItWork }) => {
  const classes = useStyles();

  const { PartnerLogo, partnerName, partnerDescription } =
    partners[partner] || {};

  const getText = () => {
    if (partnerName) {
      return `Clarity x ${partnerName}`;
    }

    if (isLoggedIn && userName && userName !== 'Anonymous') {
      return `Welcome back ${userName}`;
    }

    if (isLoggedIn) {
      return `Welcome back`;
    }

    return 'Welcome to Clarity';
  };

  return (
    <div className={classes.container}>
      <div className={classes.logos}>
        <ClarityLogo />
        {!!PartnerLogo && <PartnerLogo />}
      </div>

      <h1 className={classes.title}>{getText()}</h1>
      <p className={classes.text}>
        {partnerDescription ||
          'A financial wellbeing app for on-demand workers.'}
      </p>
      {!hideHowDoesItWork && (
        <TextLink to="/how-does-it-work">How does it work?</TextLink>
      )}
    </div>
  );
};

WelcomeText.propTypes = {
  partner: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  userName: PropTypes.string,
  hideHowDoesItWork: PropTypes.bool,
};

WelcomeText.defaultProps = {
  partner: null,
  isLoggedIn: false,
  userName: null,
  hideHowDoesItWork: false,
};

export default WelcomeText;
