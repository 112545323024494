import JobDaysPerMonth from '../components/JobDaysPerMonth';
import JobHoursPerDay from '../components/JobHoursPerDay';
import JobWaitingTime from '../components/JobWaitingTime';
import JobGigsPerDay from '../components/JobGigsPerDay';
import JobShiftsPerDay from '../components/JobShiftsPerDay';
import JobDaysPerWeek from '../components/JobDaysPerWeek';
import JobPAYEPayment from '../components/JobPAYEPayment';
import JobShiftWaitingTime from '../components/JobShiftWaitingTime';
import JobSatisfaction from '../components/JobSatisfaction';
import QuestionReview from '../components/QuestionReview';
import JobPaymentType from '../components/JobPaymentType';

const getJobSteps = (job, jobFormData) => {
  const { paymentType } = JSON.parse(job.calculatorMeta);

  if (paymentType === 'gig') {
    return [
      {
        id: `JobPaymentType`,
        component: JobPaymentType,
        section: 2,
        isValid: ({ jobFormData: jfd }) => !!jfd.employmentPaymentType,
        pageUrl: `/how-you-work/job-${job.id}/job-gig-payment-type`,
      },

      {
        id: `JobGigDaysPerMonth`,
        component: JobDaysPerMonth,
        section: 2,
        isValid: ({ jobFormData: jfd }) => !!jfd.daysPerMonth,
        pageUrl: `/how-you-work/job-${job.id}/job-gig-days-per-month`,
      },

      {
        id: `JobGigHoursPerDay`,
        component: JobHoursPerDay,
        section: 2,
        isValid: ({ jobFormData: jfd }) => !!jfd.hoursPerDay,
        pageUrl: `/how-you-work/job-${job.id}/job-gig-hours-per-day`,
      },
      {
        id: `JobGigsPerDay`,
        component: JobGigsPerDay,
        section: 2,
        isValid: ({ jobFormData: jfd }) => !!jfd.gigsPerDay,
        pageUrl: `/job-${job.id}/gigs-per-day`,
      },

      {
        id: `JobGigWaitingTime`,
        component: JobWaitingTime,
        section: 2,
        isValid: ({ jobFormData: jfd }) =>
          jfd.waitingTimeOnASlowDay !== undefined &&
          jfd.waitingTimeOnANormalDay !== undefined &&
          jfd.waitingTimeOnABusyDay !== undefined,
        pageUrl: `/how-you-work/job-${job.id}/gig-waiting-time`,
      },
      {
        id: `JobSatisfaction`,
        component: JobSatisfaction,
        section: 2,
        isValid: ({ jobFormData: jfd }) =>
          !!jfd.satisfactionRating && !!jfd.recomondationRating,
        pageUrl: `/how-you-work/job-${job.id}/satisfaction`,
      },
      {
        id: `QuestionReview`,
        component: QuestionReview,
        section: 2,
        pageUrl: `/how-you-work/job-${job.id}/job-review`,
      },
    ];
  }

  if (paymentType === 'shift') {
    return [
      {
        id: `JobPaymentType`,
        component: JobPaymentType,
        section: 2,
        isValid: ({ jobFormData: jfd }) => !!jfd.employmentPaymentType,
        pageUrl: `/how-you-work/job-${job.id}/job-shift-payment-type`,
      },

      {
        id: `JobShiftDaysPerMonth`,
        component: JobDaysPerMonth,
        section: 2,
        isValid: ({ jobFormData: jfd }) => !!jfd.daysPerMonth,
        pageUrl: `/how-you-work/job-${job.id}/job-shift-days-per-month`,
      },

      {
        id: `JobShiftHoursPerDay`,
        component: JobHoursPerDay,
        section: 2,
        isValid: ({ jobFormData: jfd }) => !!jfd.hoursPerDay,
        pageUrl: `/how-you-work/job-${job.id}/job-shift-hours-per-day`,
      },
      {
        id: `JobShiftsPerDay`,
        component: JobShiftsPerDay,
        section: 2,
        isValid: ({ jobFormData: jfd }) => !!jfd.numberOfShiftsPerDay,
        pageUrl: `/job-${job.id}/shifts-per-day`,
      },
      ...(jobFormData?.numberOfShiftsPerDay > 1
        ? [
            {
              id: `JobShiftWaitingTime`,
              component: JobShiftWaitingTime,
              section: 2,
              isValid: ({ jobFormData: jfd }) =>
                !!jfd.timeBetweenShifts || jfd.timeBetweenShifts === 0,
              pageUrl: `/how-you-work/job-${job.id}/shift-waiting-time`,
            },
          ]
        : []),
      {
        id: `JobSatisfaction`,
        component: JobSatisfaction,
        section: 2,
        isValid: ({ jobFormData: jfd }) =>
          !!jfd.satisfactionRating && !!jfd.recomondationRating,
        pageUrl: `/how-you-work/job-${job.id}/satisfaction`,
      },
      {
        id: `QuestionReview`,
        component: QuestionReview,
        section: 2,
        pageUrl: `/how-you-work/job-${job.id}/job-review`,
      },
    ];
  }

  if (paymentType === 'dayRate') {
    return [
      {
        id: `JobPaymentType`,
        component: JobPaymentType,
        section: 2,
        isValid: ({ jobFormData: jfd }) => !!jfd.employmentPaymentType,
        pageUrl: `/how-you-work/job-${job.id}/job-day-rate-payment-type`,
      },
      {
        id: `JobDayRateDaysPerMonth`,
        component: JobDaysPerMonth,
        section: 2,
        isValid: ({ jobFormData: jfd }) => !!jfd.daysPerMonth,
        pageUrl: `/how-you-work/job-${job.id}/job-day-rate-days-per-month`,
      },
      {
        id: `JobDayRateHoursPerDay`,
        component: JobHoursPerDay,
        section: 2,
        isValid: ({ jobFormData: jfd }) => !!jfd.hoursPerDay,
        pageUrl: `/how-you-work/job-${job.id}/job-day-rate-hours-per-day`,
      },
      {
        id: `JobSatisfaction`,
        component: JobSatisfaction,
        section: 2,
        isValid: ({ jobFormData: jfd }) =>
          !!jfd.satisfactionRating && !!jfd.recomondationRating,
        pageUrl: `/how-you-work/job-${job.id}/satisfaction`,
      },
      {
        id: `QuestionReview`,
        component: QuestionReview,
        section: 2,
        pageUrl: `/how-you-work/job-${job.id}/job-review`,
      },
    ];
  }

  if (paymentType === 'PAYE') {
    return [
      {
        id: `JobPaymentType`,
        component: JobPaymentType,
        section: 2,
        isValid: ({ jobFormData: jfd }) => !!jfd.employmentPaymentType,
        pageUrl: `/how-you-work/job-${job.id}/job-paye-payment-type`,
      },
      {
        id: `JobPayeDaysPerWeek`,
        component: JobDaysPerWeek,
        section: 2,
        isValid: ({ jobFormData: jfd }) => !!jfd.daysPerWeek,
        pageUrl: `/how-you-work/job-${job.id}/job-PAYE-days-per-month`,
      },
      {
        id: `JobPayeHoursPerDay`,
        component: JobHoursPerDay,
        section: 2,
        isValid: ({ jobFormData: jfd }) => !!jfd.hoursPerDay,
        pageUrl: `/how-you-work/job-${job.id}/job-PAYE-hours-per-day`,
      },

      {
        id: `JobPAYEPayment`,
        component: JobPAYEPayment,
        section: 2,
        isValid: ({ jobFormData: jfd }) =>
          !!jfd.payePaymentAmount && !!jfd.payePaymentFrequency,
        pageUrl: `/how-you-work/job-${job.id}/job-PAYE-payment`,
      },
      {
        id: `JobSatisfaction`,
        component: JobSatisfaction,
        section: 2,
        isValid: ({ jobFormData: jfd }) =>
          !!jfd.satisfactionRating && !!jfd.recomondationRating,
        pageUrl: `/how-you-work/job-${job.id}/satisfaction`,
      },
      {
        id: `QuestionReview`,
        component: QuestionReview,
        section: 2,
        pageUrl: `/how-you-work/job-${job.id}/job-review`,
      },
    ];
  }

  return [];
};

export default getJobSteps;
