import React, { useState } from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';
import CalculatorNavigation from '../CalculatorNavigation';

import useCalculator from '../../hooks/useCalculator';

import { paymentTypes } from '../../config/industries';

const PaymentTypes = (props) => {
  const [currentIndustryIndex, setCurrentIndustryIndex] = useState(0);

  const { onFormChange, formData, goToPreviousStep, onCreateJobs } =
    useCalculator(props);

  const handleGoToPreviousIndustry = () => {
    if (currentIndustryIndex === 0) {
      goToPreviousStep();
      return;
    }

    setCurrentIndustryIndex(currentIndustryIndex - 1);
  };

  const handleGoToNextIndustry = () => {
    if (currentIndustryIndex + 1 === formData.industries.length) {
      onCreateJobs();
      return;
    }

    setCurrentIndustryIndex(currentIndustryIndex + 1);
  };

  const currentIndustry = formData.industries[currentIndustryIndex];

  const isValid = formData?.industriesWithPaymentTypes?.filter(
    ({ subIndustryId }) => subIndustryId === currentIndustry.id,
  ).length;

  return (
    <>
      <Card
        header={`Job ${currentIndustryIndex + 1}: ${currentIndustry.name}`}
        title={`Select all the ways you work for ${currentIndustry.name}`}
      >
        <CalculatorForm
          fields={[
            {
              id: 'industriesWithPaymentTypes',
              name: 'industriesWithPaymentTypes',
              type: 'multiCheckboxes',
              options: paymentTypes,
              label: 'What do you do?',
              visuallyHidden: true,
              idPrefix: currentIndustry.id,
              extraData: {
                subIndustry: currentIndustry.name,
                subIndustryId: currentIndustry.id,
                industry: currentIndustry.industry,
                industryId: currentIndustry.industryId,
              },
            },
          ]}
          values={formData}
          onChange={onFormChange}
        />
      </Card>

      <CalculatorNavigation
        goToNextStep={handleGoToNextIndustry}
        goToPreviousStep={handleGoToPreviousIndustry}
        disableNextButton={!isValid}
      />
    </>
  );
};

export default PaymentTypes;
