import React, { createContext } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import PropTypes from 'prop-types';

export const defaultFormValues = {
  howManyJobs: 1,
  jobs: {},
};

const CalculatorFormContext = createContext({
  formData: {},
  onFormChange: () => {},
  onReplaceFormData: () => {},
  onJobFormChange: () => {},
  onReplaceJobFormData: () => {},
});

const CalculatorFormProvider = ({ children }) => {
  const [formData, setFormData] = useLocalStorageState(
    'calculatorFormData',
    defaultFormValues,
  );

  const handleFormChange = (e) => {
    const { name, value, type } = e.target;

    const parsedValue = type === 'number' ? parseFloat(value) : value;

    setFormData({ ...formData, [name]: parsedValue });
  };

  const handleReplaceFormData = (data) => {
    setFormData(data);
  };

  const handleUpdateJobFormData = (e, jobId) => {
    const { name, value, type } = e.target;

    const parsedValue = type === 'number' ? parseFloat(value) : value;

    setFormData({
      ...formData,
      jobs: {
        ...formData.jobs,
        [jobId]: {
          ...(formData.jobs?.[jobId] || {}),
          [name]: parsedValue,
        },
      },
    });
  };

  const handleUpdateFormData = (newData) => {
    setFormData({ ...formData, ...newData });
  };

  const handleReplaceJobFormData = (jobId, newData) => {
    setFormData({
      ...formData,
      jobs: {
        ...formData.jobs,
        [jobId]: {
          ...(formData.jobs?.[jobId] || {}),
          ...newData,
        },
      },
    });
  };

  return (
    <CalculatorFormContext.Provider
      value={{
        formData,
        onFormChange: handleFormChange,
        onReplaceFormData: handleReplaceFormData,
        onUpdateJobFormData: handleUpdateJobFormData,
        onUpdateFormData: handleUpdateFormData,
        onReplaceJobFormData: handleReplaceJobFormData,
      }}
    >
      {children}
    </CalculatorFormContext.Provider>
  );
};

CalculatorFormProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { CalculatorFormContext, CalculatorFormProvider };
