/**
 * Utility function to format currency
 *
 * @param {number} value - Value as a floating point number
 * @return {string} Formatted curreny value
 *
 */

const formatCurrency = (value, noDecimals) => {
  const formatter = new Intl.NumberFormat('en-UK', {
    minimumFractionDigits: noDecimals ? 0 : 2,
    maximumFractionDigits: noDecimals ? 0 : 2,
  });

  return formatter.format(value || 0);
};

export default formatCurrency;
