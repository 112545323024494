import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { SUGGESTED_RULES, CREATE_RULE } from '../../graph/rules';

import SuggestedRule from '../SuggestedRule';
import Loader from '../Loader';
import Card from '../Card';
import CalculatorNavigation from '../CalculatorNavigation';

import useCalculator from '../../hooks/useCalculator';
import SimpleTextField from '../SimpleTextField';

const useStyles = makeStyles({
  list: {
    listStyle: 'none',
    margin: '30px 0 0 0',
    padding: 0,
  },
});

const icon = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7498 18.578L14.064 12.8921C15.1653 11.5317 15.8285 9.80305 15.8285 7.92028C15.8285 3.55655 12.2778 0.00590515 7.91409 0.00590515C3.55036 0.00590515 -0.000244141 3.55651 -0.000244141 7.92024C-0.000244141 12.284 3.5504 15.8346 7.91413 15.8346C9.7969 15.8346 11.5256 15.1715 12.886 14.0701L18.5718 19.756C18.7343 19.9184 18.9475 20.0001 19.1608 20.0001C19.3741 20.0001 19.5874 19.9184 19.7499 19.756C20.0756 19.4302 20.0756 18.9037 19.7498 18.578ZM7.91413 14.1684C4.46845 14.1684 1.66595 11.3659 1.66595 7.92024C1.66595 4.47456 4.46845 1.67206 7.91413 1.67206C11.3598 1.67206 14.1623 4.47456 14.1623 7.92024C14.1623 11.3659 11.3598 14.1684 7.91413 14.1684Z"
      fill="#4797D2"
    />
  </svg>
);

const SuggestedRuleList = ({
  ruleType,
  title,
  header,
  text,
  searchText,
  ...props
}) => {
  const { goToPreviousStep, goToNextStep } = useCalculator(props);
  const [rules, setRules] = useState([]);
  const [searchValue, setSearchValue] = useState();

  const {
    data: { suggestedRules },
    loading,
  } = useQuery(SUGGESTED_RULES, {
    variables: {
      ruleType,
      dateFrom: dayjs().subtract(6, 'month').format('YYYY-MM-DD'),
      dateTo: dayjs().format('YYYY-MM-DD'),
    },
  });

  const [handleCreateRule] = useMutation(CREATE_RULE);

  const handleAddNewRule = (newRule) => {
    const filteredRules = rules.filter((rule) => rule.match !== newRule.match);
    setRules([...filteredRules, newRule]);
    window.localStorage.setItem(ruleType, 'true');
  };

  const handleGoToNextStep = () => {
    rules.map((rule) =>
      handleCreateRule({ variables: { ...rule, shouldNotify: false } }),
    );
    goToNextStep();
  };

  const classes = useStyles();

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const filteredSuggestedRules = searchValue
    ? suggestedRules?.filter((rule) => rule.description.includes(searchValue))
    : suggestedRules;

  const renderContent = () => {
    if (loading) {
      return <Loader inline />;
    }

    return (
      <div>
        <SimpleTextField
          name="search"
          type="text"
          values={{ search: searchValue }}
          onChange={handleSearchChange}
          placeholder={searchText}
          search
          suffix={icon}
          noMargin
        />
        <ul className={classes.list}>
          {filteredSuggestedRules?.map((rule) => (
            <SuggestedRule
              key={rule.description}
              handleAddNewRule={handleAddNewRule}
              {...rule}
              rules={rules}
            />
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      <Card header={header} title={title} text={text}>
        {renderContent()}
      </Card>
      <CalculatorNavigation
        goToNextStep={handleGoToNextStep}
        goToPreviousStep={goToPreviousStep}
      />
    </>
  );
};

SuggestedRuleList.propTypes = {
  ruleType: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default SuggestedRuleList;
