import dayjs from 'dayjs';

const getDateRange = () => {
  const dateRange =
    parseInt(localStorage.getItem('dateRange')?.replace('"', ''), 10) || 3;

  return {
    dateTo: dayjs().startOf('hour'),
    dateFrom: dayjs().subtract(dateRange, 'month').startOf('hour'),
  };
};

export default getDateRange;
