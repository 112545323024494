import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';

import Label from '../Label';

import { formatFieldValue } from '../../utils';

const useStyles = makeStyles(() => ({
  formControl: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  noMargin: {
    marginBottom: '0px',
  },
  text: {
    marginTop: '8px',
  },
  outline: {
    borderWidth: '1px',
    borderColor: '#343858',
    '& legend': {
      display: 'none',
    },
  },
  adornment: {
    whiteSpace: 'nowrap',
    '& p': {
      lineHeight: '1.1875em',
    },
    '& svg': {
      color: '#4797D2',
    },
  },
  alt: {
    borderColor: '#C4C4C4',
  },
  altInput: {
    backgroundColor: '#ffffff',
  },
  search: {
    borderColor: '#C4C4C4',
    borderWidth: '2px',
    fontSize: '16px',
    borderRadius: '10px',
  },
  searchInput: {
    marginTop: '-4px',
  },
  searchAdornment: {
    marginTop: '-4px',
  },
}));

const SimpleTextField = ({
  name,
  type,
  prefix,
  suffix,
  disabled,
  readOnly,
  defaultValue,
  values,
  alt,
  search,
  noMargin,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl
      fullWidth
      className={clsx(classes.formControl, { [classes.noMargin]: noMargin })}
    >
      {/* eslint-disable-next-line */}
      <Label {...props} />

      <OutlinedInput
        classes={{
          root: clsx(classes.text, {
            [classes.altInput]: alt,
          }),
          input: clsx({ [classes.searchInput]: search }),
          notchedOutline: clsx(classes.outline, {
            [classes.alt]: alt,
            [classes.search]: search,
          }),
        }}
        startAdornment={
          prefix && (
            <InputAdornment position="start" className={classes.adornment}>
              {prefix}
            </InputAdornment>
          )
        }
        endAdornment={
          suffix && (
            <InputAdornment
              position="end"
              className={clsx(classes.adornment, {
                [classes.searchAdornment]: search,
              })}
            >
              {suffix}
            </InputAdornment>
          )
        }
        id={name}
        name={name}
        type={type}
        disabled={disabled}
        readOnly={readOnly}
        value={formatFieldValue(
          defaultValue || `${values[name] === undefined ? '' : values[name]}`,
          type,
        )}
        {...props}
      />
    </FormControl>
  );
};

SimpleTextField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  suffix: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.string,
  values: PropTypes.shape({}).isRequired,
  alt: PropTypes.bool,
  search: PropTypes.bool,
  noMargin: PropTypes.bool,
};

SimpleTextField.defaultProps = {
  disabled: false,
  prefix: null,
  label: null,
  suffix: null,
  readOnly: false,
  defaultValue: '',
  alt: false,
  search: false,
  noMargin: false,
};

export default SimpleTextField;
