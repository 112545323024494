import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';

import useCalculator from '../../hooks/useCalculator';

const Expenses = (props) => {
  const { onFormChange, formData } = useCalculator(props);

  return (
    <Card
      subTitle="Select all that apply"
      title="Select the statements that apply to you:"
    >
      <CalculatorForm
        fields={[
          {
            id: 'expenses',
            name: 'expenses',
            type: 'multiRadio',
            label: 'Select the statements that apply to you:',
            visuallyHidden: true,
            options: [
              {
                id: 'expenses_workVehicle',
                label:
                  'I use my own vehicle for work (car, scooter, motorcycle or van)',
                name: 'expenses_workVehicle',
              },
              {
                id: 'expenses_accountant',
                label: 'I use an accountant and/or bookkeeping software',
                name: 'expenses_accountant',
              },
              {
                id: 'expenses_phone',
                name: 'expenses_phone',
                label: 'I use a phone for work',
              },
              {
                id: 'expenses_insurance',
                name: 'expenses_insurance',
                label: 'I have to pay for insurance for my job',
              },

              {
                id: 'expenses_other',
                name: 'expenses_other',
                label:
                  "I have other business expenses that aren't on this list",
              },
            ],
          },
        ]}
        values={formData}
        onChange={onFormChange}
      />
    </Card>
  );
};

export default Expenses;
