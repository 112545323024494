import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, LineChart, Line, Tooltip, XAxis } from 'recharts';

import { makeStyles } from '@material-ui/core/styles';

import Card from '../Card';

import { formatCurrency, isArrayWithLenth } from '../../utils';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 132,
    position: 'relative',

    '& .recharts-layer.recharts-cartesian-axis-tick text': {
      fontSize: '10px',
      fontWeight: 500,
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
      color: '#252525',
    },
  },
  noDataMessage: {
    position: 'absolute',
    left: '30px',
    right: '30px',
    top: '50%',
    transform: 'translate(0, -100%)',
    textAlign: 'center',
    color: theme.palette.grey[500],
  },
  tooltip: {
    backgroundColor: '#4797D2',
    borderRadius: '2px',
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '10px',
    color: '#ffffff',
    padding: 6,
  },
}));

const CustomLineChart = ({ data, noDataMessage, labelKey }) => {
  const classes = useStyles();

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div className={classes.tooltip}>
          {`£${formatCurrency(payload[0].value)}`}
        </div>
      );
    }

    return null;
  };

  const CustomCursor = (props) => {
    const { payloadIndex } = props;

    return (
      <>
        <rect
          width={`${100 / data.length}%`}
          height="141"
          rx="10"
          x={`${(payloadIndex / data.length) * 100}%`}
          fill="url(#paint0_linear)"
          fillOpacity="0.15"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="22.5"
            y1="0"
            x2="22.5"
            y2="141"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#4797D2" />
            <stop offset="1" stopColor="#4797D2" stopOpacity="0" />
          </linearGradient>
        </defs>
      </>
    );
  };

  if (!isArrayWithLenth(data)) {
    return (
      <Card smallMargin>
        <div className={classes.container}>
          <p className={classes.noDataMessage}>{noDataMessage}</p>
        </div>
      </Card>
    );
  }

  return (
    <Card smallMargin>
      <div className={classes.container}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 0, right: 30, left: 30, bottom: 0 }}
          >
            <Tooltip cursor={<CustomCursor />} content={<CustomTooltip />} />
            <Line
              dataKey="value"
              stroke="#4797D2"
              activeDot={{
                r: 7,
                strokeWidth: 3,
                stroke: '#4797D2',
                fill: '#ffffff',
              }}
              type="linear"
              strokeWidth={4}
              dot={false}
            />
            <XAxis
              axisLine={false}
              tickLine={false}
              dataKey={labelKey}
              interval="preserveStartEnd"
              minTickGap={0}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
};

CustomLineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  noDataMessage: PropTypes.string,
  labelKey: PropTypes.string,
};

CustomLineChart.defaultProps = {
  noDataMessage: 'No data',
  labelKey: 'id',
};

export default CustomLineChart;
