import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { baseTheme, globalStyles } from '../theme/base';

export const GlobalThemeProvider = ({ children }) => {
  globalStyles();
  return <ThemeProvider theme={baseTheme}>{children}</ThemeProvider>;
};

GlobalThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
