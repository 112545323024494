import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';

import useCalculator from '../../hooks/useCalculator';

const ExpensesAccountant = (props) => {
  const { onFormChange, formData } = useCalculator(props);

  return (
    <Card
      subTitle="Answer all"
      title="Tell us more about accountant and/or bookkeeping software"
    >
      <CalculatorForm
        fields={[
          {
            id: 'accountantType',
            name: 'accountantType',
            type: 'radio',
            visuallyHidden: true,
            label: 'Tell us more about accountant and/or bookkeeping software',
            options: [
              {
                id: 'accountant',
                label: 'I use an accountant',
                value: 'accountant',
              },
              {
                id: 'software',
                label: 'I use bookkeeping software',
                value: 'software',
              },
              {
                id: 'both',
                label: 'I use both',
                value: 'both',
              },
            ],
          },
          {
            id: 'accountantExpense',
            name: 'accountantExpense',
            type: 'number',
            label: 'How much does this cost you?',
            prefix: '£',
            suffix: 'a year',
          },
        ]}
        values={formData}
        onChange={onFormChange}
      />
    </Card>
  );
};

export default ExpensesAccountant;
