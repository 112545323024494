import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import Layout from '../../components/Layout';
import Scrollable from '../../components/Scrollable';
import ResultsHeader from '../../components/ResultsHeader';
import HelpAccordion from '../../components/HelpAccordion';
import ResultFeedback from '../../components/ResultFeedback';

import useTrackPage from '../../hooks/useTrackPage';

import { helpArticles } from '../../config/help';

import { GET_SPENDING_IMPROVMENTS } from '../../graph/clarity';
import { GET_CUSTOM_JOBS } from '../../graph/jobs';

import getUniqueIndustriesFromJobs from '../../utils/getUniqueIndustriesFromJobs';

const WellnessHub = () => {
  useTrackPage('/results/improve-my-score');

  const {
    data: { jobs },
  } = useQuery(GET_CUSTOM_JOBS, {
    variables: {
      isCommonToUsers: false,
    },
    fetchPolicy: 'cache-first',
  });

  const uniqueIndustryList = getUniqueIndustriesFromJobs(jobs);

  const { data, loading } = useQuery(GET_SPENDING_IMPROVMENTS, {
    variables: {
      industry: uniqueIndustryList[0].id,
    },
  });

  const renderContent = () => {
    if (!data?.score?.spendingImprovements?.length) {
      return (
        <p style={{ textAlign: 'center' }}>
          Sorry, there has been a problem fetching your score, please contact
          support.
        </p>
      );
    }

    return data?.score?.spendingImprovements?.map(({ id }) => {
      const article = helpArticles[id];

      if (!article) return false;

      return <HelpAccordion key={id} {...article} />;
    });
  };

  return (
    <Layout animateIn fetching={loading}>
      <Scrollable>
        <ResultsHeader title="How can I improve?" hideDateRangeSelector />
        {renderContent()}

        <ResultFeedback section="improve-my-score" />
      </Scrollable>
    </Layout>
  );
};

export default WellnessHub;
