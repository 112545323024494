import React from 'react';

import Layout from '../../components/Layout';
import Card from '../../components/Card';
import BackHeader from '../../components/BackHeader';

import useTrackPage from '../../hooks/useTrackPage';

const ComingSoon = () => {
  useTrackPage('/coming-soon');

  return (
    <Layout>
      <BackHeader />
      <Card
        title="Coming Soon!"
        text="Hold on, this feature isn't quite ready yet."
        center
      />
    </Layout>
  );
};

export default ComingSoon;
