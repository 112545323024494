import { useEffect } from 'react';
import ReactGA from 'react-ga';

const useTrackPage = (url) => {
  useEffect(() => {
    if (!window[`ga-enable`]) return;
    ReactGA.pageview(
      url || `${window.location.pathname}${window.location.search}`,
    );
  }, [url]);
};

export default useTrackPage;
