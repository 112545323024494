import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Button from '../Button';
import Card from '../Card/Card';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '10px 20px',
  },
  link: {
    color: '#4797D2',
  },
}));

const HelpAccordion = ({
  title,
  description1,
  description2,
  articleTitle,
  articleLink,
  videoTitle,
  videoLink,
}) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const handleToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Button
        alt
        altActive={isOpen}
        arrowDown={!isOpen}
        arrowUp={isOpen}
        onClick={handleToggleOpen}
      >
        {title}
      </Button>

      {isOpen && (
        <div className={classes.content}>
          {!!description1 && <p>{description1}</p>}
          {!!description2 && <p>{description2}</p>}

          {!!(articleTitle && articleLink) && (
            <Card title={articleTitle} smallMargin smallPadding smallTitle>
              <a
                className={classes.link}
                href={articleLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                Find out more{' '}
              </a>
            </Card>
          )}

          {!!(videoTitle && videoLink) && (
            <Card title={videoTitle} smallMargin smallPadding smallTitle>
              <a
                className={classes.link}
                href={videoLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                View video
              </a>
            </Card>
          )}
        </div>
      )}
    </>
  );
};

HelpAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  description1: PropTypes.string.isRequired,
  description2: PropTypes.string.isRequired,
  articleTitle: PropTypes.string.isRequired,
  articleLink: PropTypes.string.isRequired,
  videoTitle: PropTypes.string.isRequired,
  videoLink: PropTypes.string.isRequired,
};

HelpAccordion.defaultProps = {};

export default HelpAccordion;
