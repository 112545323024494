import React from 'react';
import Intercom from 'react-intercom';
import { useQuery } from '@apollo/react-hooks';

import Layout from '../../components/Layout';
import Card from '../../components/Card';
import BackHeader from '../../components/BackHeader';
import Button from '../../components/Button';

import useTrackPage from '../../hooks/useTrackPage';

import { GET_META } from '../../graph/auth';

const ComingSoon = () => {
  useTrackPage('/coming-soon');
  const { data } = useQuery(GET_META, { fetchPolicy: 'cache-only' });

  return (
    <>
      <Layout>
        <BackHeader />
        <Card
          title="Help"
          text="Chat with a member of the Clarity team if you need help or schedule time with a Financial Coach if you have questions understanding your results."
          center
        />
        <Button
          onClick={() => {
            window.Intercom('show');
          }}
        >
          Chat to us
        </Button>

        <Button
          secondary
          to="/coaching"
          marginTop
          inlineBlock
          autoHeight
          style={{ textAlign: 'center' }}
        >
          Discuss your results with a financial coach
        </Button>
      </Layout>
      <Intercom
        user_id={data?.user?.id}
        {...(data?.user || {})}
        appID={process.env.REACT_APP_INTERCOM_APP_ID}
        alignment="none"
      />
    </>
  );
};

export default ComingSoon;
