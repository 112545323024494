import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import dayjs from 'dayjs';

import Layout from '../../components/Layout';
import Scrollable from '../../components/Scrollable';
import ResultsHeader from '../../components/ResultsHeader';
import ValueList from '../../components/ValueList';
import Title from '../../components/Title';
import HighRiskTransactionsList from '../../components/HighRiskTransactionsList';
import LineChart from '../../components/LineChart';
import ResultFeedback from '../../components/ResultFeedback';

import useTrackPage from '../../hooks/useTrackPage';

import { GET_HIGH_RISK_TRANSACTIONS } from '../../graph/clarity';

const HighRiskTransactions = () => {
  useTrackPage('/results/high-risk-transactions');

  const {
    data: { highRiskTransactions },
    loading,
  } = useQuery(GET_HIGH_RISK_TRANSACTIONS, {
    variables: {
      dateTo: dayjs().startOf('hour'),
      dateFrom: dayjs().subtract(1, 'year').startOf('hour'),
    },
  });

  const renderContent = () => {
    return (
      <>
        <Title variant="h3">Top high risk transactions</Title>
        <HighRiskTransactionsList data={highRiskTransactions?.top} />
        <ValueList showIndex data={highRiskTransactions?.top} />
        <LineChart data={highRiskTransactions?.breakdown} labelKey="label" />
        <ResultFeedback section="high-risk-transactions" />
      </>
    );
  };

  return (
    <Layout animateIn fetching={loading}>
      <Scrollable>
        <ResultsHeader title="High risk transactions" hideDateRangeSelector />
        {renderContent()}
      </Scrollable>
    </Layout>
  );
};

export default HighRiskTransactions;
