import camelCaseToSentenceCase from '../utils/camelCaseToSentenceCase';

export const questions = {
  JobPaymentType: {
    label: 'How do you get paid for this kind of work?',
    answerKey: 'employmentPaymentType',
  },
  JobGigDaysPerMonth: {
    label: 'Based on the last month, how many days do you do this work?',
    answerKey: 'daysPerMonth',
    answerSuffix: 'days',
  },
  JobGigHoursPerDay: {
    label:
      'Based on the last month, how many hours do you work a day on average?',
    answerKey: 'hoursPerDay',
    answerSuffix: 'hours',
  },
  JobGigsPerDay: {
    label: 'Based on the last month, how many gigs do you do a day on average?',
    answerKey: 'gigsPerDay',
    answerSuffix: 'gigs',
  },
  JobGigWaitingTime: {
    label:
      'Based on the last month, how much time do you have between each gig on average?',
    answerFormatter: (formData) =>
      Math.round(
        (formData.waitingTimeOnASlowDay || 0) * 0.2 +
          (formData.waitingTimeOnANormalDay || 0) * 0.6 +
          (formData.waitingTimeOnABusyDay || 0) * 0.2,
      ),
    answerSuffix: 'minutes',
  },

  JobShiftDaysPerMonth: {
    label: 'Based on the last month, how many days do you do this work?',
    answerKey: 'daysPerMonth',
    answerSuffix: 'days',
  },
  JobShiftHoursPerDay: {
    label:
      'Based on the last month, how many hours do you work a day on average?',
    answerKey: 'hoursPerDay',
    answerSuffix: 'hours',
  },
  JobShiftsPerDay: {
    label:
      'Based on the last month, how many shifts do you work in a day on average?',
    answerKey: 'numberOfShiftsPerDay',
    answerSuffix: 'shifts',
  },
  JobShiftWaitingTime: {
    label:
      'Based on the last month, when you work more than one shift in a 24 hour period, how long do you spend waiting between shifts on average?',
    answerKey: 'timeBetweenShifts',
    answerSuffix: 'minutes',
  },

  JobDayRateDaysPerMonth: {
    label: 'Based on the last month, how many days do you do this work?',
    answerKey: 'daysPerMonth',
    answerSuffix: 'days',
  },
  JobDayRateHoursPerDay: {
    label:
      'Based on the last month, how many hours do you work a day on average?',
    answerKey: 'hoursPerDay',
    answerSuffix: 'hours',
  },

  JobPayeDaysPerWeek: {
    label:
      'Based on the last month, how many days per week do you do this work?',
    answerKey: 'daysPerWeek',
    answerSuffix: 'days',
  },
  JobPayeHoursPerDay: {
    label:
      'Based on the last month, how many hours do you work a day on average?',
    answerKey: 'hoursPerDay',
    answerSuffix: 'hours',
  },
  JobPAYEPayment: {
    label:
      'Based on the last month, how much are you paid to do this work before taxes?',
    answerFormatter: (formData) =>
      `${formData.payePaymentAmount} ${camelCaseToSentenceCase(
        formData.payePaymentFrequency,
      )}`,
    answerPrefix: '£',
  },

  ConnectBank: {
    label: 'Who do you bank with?',
    editLabel: 'Add more banks',
  },

  TravelTime: {
    label: 'How do you calculate the hours you work?',
    answerFormatter: (formData) =>
      formData.travelTime === 'TRUE'
        ? 'From when I leave the house to when I get home'
        : 'From when I get my first job until the end of my last job',
  },

  LunchBreak: {
    label: 'How long do you take for lunch?',
    answerFormatter: (formData) =>
      formData.noLunchBreak === 'true'
        ? 'I don’t take a lunch break'
        : `${formData.lunchBreakLength} minutes`,
  },
  SuggestedIncomeRules: {
    label: 'You get income from these sources',
    editLabel: 'Tag more transactions',
  },

  Expenses: {
    label: 'Select the statements that apply to you:',
    answerFormatter: (formData) =>
      `${
        formData.expenses_workVehicle
          ? 'I use my own vehicle for work (car, scooter, motorcycle or van), '
          : ''
      }
      ${
        formData.expenses_accountant
          ? 'I use an accountant and/or bookkeeping software, '
          : ''
      }
      ${formData.expenses_phone ? 'I use a phone for work, ' : ''}
      ${
        formData.expenses_insurance
          ? 'I have to pay for insurance for my job, '
          : ''
      }
      ${
        formData.expenses_other
          ? "I have other business expenses that aren't on this list, "
          : ''
      }`.slice(0, -2),
  },
  ExpensesVehicle: {
    label: 'Tell us more about your work vehicle usage',
    answerFormatter: (formData) =>
      formData.expenses_workVehicle &&
      `${formData.vehicleType}, 
      ${formData.vehicleMilesPerMonth} miles per month,
      ${formData.vehiclePercentageUsedForWork} % used for work
     `,
  },
  ExpensesAccountant: {
    label: 'Tell us more about accountant and/or bookkeeping software',
    answerFormatter: (formData) =>
      formData.expenses_accountant &&
      `${formData.accountantType === 'accountant' ? 'I use an accountant,' : ''}
      ${
        formData.accountantType === 'software'
          ? 'I use bookkeeping software,'
          : ''
      }
      ${formData.accountantType === 'both' ? 'I use both,' : ''} 
      £${formData.accountantExpense} a year
     `,
  },
  ExpensesPhone: {
    label: 'What do your monthly phone costs include?',
    answerFormatter: (formData) =>
      formData.expenses_phone &&
      `${
        formData.phoneType === 'plan'
          ? 'Only the cost of the plan (minutes, data etc.)'
          : ''
      }
      ${
        formData.phoneType === 'both'
          ? 'The cost of the plan (minutes, data etc.) and the phone itself'
          : ''
      }
     `,
  },
  ExpensesSavings: {
    label: 'Do you regularly put money into another account as savings?',
    answerFormatter: (formData) =>
      formData.hasSavings === 'no'
        ? 'No'
        : `Yes, £${formData.savingsAmount} per month`,
  },
  SuggestedExpenseRules: {
    label: 'You spend money in these places',
    editLabel: 'Tag more transactions',
  },
};
