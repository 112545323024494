import React from 'react';
import pluralize from 'pluralize';

import Card from '../Card';
import Answer from '../Answer';

import useCalculator from '../../hooks/useCalculator';

import getEditLink from '../../utils/getEditLink';
import getAnswerValue from '../../utils/getAnswerValue';

import { questions } from '../../config/questions';
import { groups } from '../../config/groups';

const JobReview = (props) => {
  const { formData, jobFormData, job, calculatorSteps, group } =
    useCalculator(props);

  return (
    <Card header={`${job?.name || groups[group]?.label} - Your answers`}>
      {calculatorSteps.map(({ id }, index) => {
        const questionMeta = questions[id];

        if (id === 'QuestionReview' || !questionMeta) return false;

        const answerValue = getAnswerValue({
          job,
          answerKey: questionMeta.answerKey,
          answerFormatter: questionMeta.answerFormatter,
          formData,
          jobFormData,
        });

        return (
          <Answer
            key={id}
            label={questionMeta.label}
            editLabel={questionMeta.editLabel}
            suffix={
              questionMeta.answerSuffix
                ? pluralize(questionMeta.answerSuffix, answerValue)
                : undefined
            }
            value={answerValue}
            editLink={getEditLink({ group, jobId: job?.id, index })}
          />
        );
      })}
    </Card>
  );
};

export default JobReview;
