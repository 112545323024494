import { ReactComponent as GophrLogo } from '../assets/gophr-logo.svg';

const partners = {
  gophr: {
    PartnerLogo: GophrLogo,
    partnerName: 'Gophr',
    partnerDescription:
      'Hey! Good news – you have access to Clarity, a financial wellbeing app for on-demand workers.',
  },
};

export default partners;
