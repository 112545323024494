import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

import { makeStyles } from '@material-ui/core/styles';

import Button from '../Button';

const useStyles = makeStyles((theme) => ({
  outer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  wrapper: {
    backgroundColor: theme.palette.common.white,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: 20,

    '& a': {
      color: theme.palette.common.black,
    },
  },
  buttonWrapper: {
    display: 'flex',
    margin: '20px -5px 0',

    '& > button': {
      margin: '0 5px',
      width: '50%',
    },
  },
  inner: {
    padding: '0',
    maxWidth: '480px',
    margin: 'auto',
  },
}));

const CookieBar = ({ onAllowAllCookies, onDisableAllCookies }) => {
  const classes = useStyles();

  return (
    <div className={classes.outer}>
      <section className={classes.wrapper}>
        <div className={classes.inner}>
          By clicking “Allow All”, you agree to the storing of cookies on your
          device to enhance site navigation, analyze site usage, and assist in
          our marketing efforts. <Link to="/cookie-policy">Cookie Notice</Link>
          <div className={classes.buttonWrapper}>
            <Button onClick={onDisableAllCookies} small>
              Disable All
            </Button>
            <Button onClick={onAllowAllCookies} small>
              Allow All
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
};

CookieBar.propTypes = {
  onAllowAllCookies: PropTypes.func.isRequired,
  onDisableAllCookies: PropTypes.func.isRequired,
};

export default CookieBar;
