import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';

import Layout from '../../components/Layout';
import Scrollable from '../../components/Scrollable';
import ResultsHeader from '../../components/ResultsHeader';
import Title from '../../components/Title';
import ValueList from '../../components/ValueList';
import PieChart from '../../components/PieChart';
import SelectField from '../../components/SelectFieldAlt';
import LineChart from '../../components/LineChart';
import ResultFeedback from '../../components/ResultFeedback';

import useTrackPage from '../../hooks/useTrackPage';

import { GET_SPENDING_HABITS } from '../../graph/clarity';

import getDateRange from '../../utils/getDateRange';

const SpendingHabits = () => {
  const [category, setCategory] = useState(null);

  const {
    data: { spendingHabits },
    loading,
    refetch,
  } = useQuery(GET_SPENDING_HABITS, {
    variables: {
      ...getDateRange(),
    },
  });

  const handleRefetch = () => {
    refetch({
      ...getDateRange(),
    });
  };

  useTrackPage('/results/spending-habits');

  const topSixCategories = spendingHabits?.categories?.slice(0, 6);
  const selectedCategory =
    category && spendingHabits?.categories?.find(({ id }) => id === category);

  const renderContent = () => {
    if (loading) {
      return false;
    }

    return (
      <>
        <Title variant="h3">What I'm spending my money on</Title>
        <ValueList data={topSixCategories} showIndex />

        <PieChart
          title="Categories"
          data={topSixCategories}
          total={spendingHabits.total}
          valueKey="percentage"
        />

        <Title variant="h3" largeMarginTop>
          Where I'm spending my money
        </Title>

        <SelectField
          options={spendingHabits.categories}
          name="category"
          values={{ category }}
          onChange={(e) => setCategory(e.target.value)}
          selectText="Choose a category..."
          alt
        />

        <LineChart
          data={selectedCategory?.breakdown}
          noDataMessage="Choose a category to view spending breakdown"
          labelKey="label"
        />
        <ValueList
          data={selectedCategory?.merchants}
          noDataMessage="Choose a category to view spending breakdown"
          showIndex
        />

        <ResultFeedback section="spending-habits" />
      </>
    );
  };

  return (
    <Layout fetching={loading} animateIn>
      <Scrollable>
        <ResultsHeader
          title="My spending habits"
          refetchFunction={handleRefetch}
        />
        {renderContent()}
      </Scrollable>
    </Layout>
  );
};

export default SpendingHabits;
