import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  tabs: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '76px',
    backgroundColor: '#ffffff',
    margin: '30px -30px -30px -30px',
    width: 'calc(100% + 58px)',
  },
  tab: {
    color: '#343858',
    flex: '1 0 50%',
    height: '76px',
    display: 'block',
    border: 'none',
    background: 'transparent',
    borderTop: 'solid 4px transparent',
    opacity: '0.5',
    padding: '10px 0px 14px',

    '& > span': {
      fontSize: '16px',
      fontWeight: 500,
      display: 'block',
    },
  },
  tabActive: { borderColor: '#4797D2', opacity: '1' },
}));

const ResultsTabs = ({ activeTab, setActiveTab }) => {
  const classes = useStyles();

  return (
    <div className={classes.tabs}>
      <button
        className={clsx(classes.tab, {
          [classes.tabActive]: activeTab === 'income',
        })}
        type="button"
        onClick={() => setActiveTab('income')}
      >
        <span>Business</span>
        Jobs, income and expenses
      </button>
      <button
        className={clsx(classes.tab, {
          [classes.tabActive]: activeTab === 'spendingHabits',
        })}
        type="button"
        onClick={() => setActiveTab('spendingHabits')}
      >
        <span>Personal</span>
        Spending habbits
      </button>
    </div>
  );
};

ResultsTabs.propTypes = {
  activeTab: PropTypes.bool.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default ResultsTabs;
