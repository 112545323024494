import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';

import useCalculator from '../../hooks/useCalculator';

const LunchBreak = (props) => {
  const { onFormChange, formData } = useCalculator(props);

  return (
    <Card subTitle="Select one answer" title="How long do you take for lunch?">
      <CalculatorForm
        fields={[
          {
            id: 'lunchBreakLength',
            name: 'lunchBreakLength',
            type: 'number',
            suffix: 'minutes',
            label: 'How long do you take for lunch?',
            visuallyHidden: true,
          },
          {
            id: 'noLunchBreak',
            name: 'noLunchBreak',
            type: 'checkbox',
            label: 'I don’t take a lunch break',
          },
        ]}
        values={formData}
        onChange={onFormChange}
      />
    </Card>
  );
};

export default LunchBreak;
