import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import dayjs from 'dayjs';
import useLocalStorageState from 'use-local-storage-state';

import Layout from '../../components/Layout';
import Scrollable from '../../components/Scrollable';
import ResultCard from '../../components/ResultCard';
import ResultsHeader from '../../components/ResultsHeader';
import SelectFieldAlt from '../../components/SelectFieldAlt';
import Title from '../../components/Title';
import ResultFeedback from '../../components/ResultFeedback';

import useTrackPage from '../../hooks/useTrackPage';

import { GET_EXPENSES } from '../../graph/clarity';
import { GET_CUSTOM_JOBS } from '../../graph/jobs';

import getUniqueIndustriesFromJobs from '../../utils/getUniqueIndustriesFromJobs';

const Expenses = () => {
  useTrackPage('/results/my-expenses');

  const {
    data: { jobs },
  } = useQuery(GET_CUSTOM_JOBS, {
    variables: {
      isCommonToUsers: false,
    },
    fetchPolicy: 'cache-first',
  });

  const uniqueIndustryList = getUniqueIndustriesFromJobs(jobs);

  const [currentIndustry, setCurrentIndustry] = useLocalStorageState(
    'currentIndustry',
    null,
  );

  const { data, loading } = useQuery(GET_EXPENSES, {
    variables: {
      dateTo: dayjs().startOf('hour'),
      dateFrom: dayjs().subtract(6, 'months').startOf('hour'),
      industry: currentIndustry || uniqueIndustryList?.[0]?.id,
    },
  });

  const handleIndustryChange = (e) => {
    setCurrentIndustry(e.target.value);
  };

  const renderContent = () => {
    if (loading) {
      return false;
    }

    return (
      <>
        {data?.expenses.map(
          ({ id, ...result }) =>
            id !== 'accountant_software' && <ResultCard key={id} {...result} />,
        )}
        <ResultFeedback section="my-expenses" />
      </>
    );
  };

  return (
    <Layout fetching={loading} animateIn>
      <Scrollable>
        <ResultsHeader
          title="My expenses"
          subTitle="(per month)"
          hideDateRangeSelector
        />
        <Title variant="h3">Common business expenses</Title>
        <SelectFieldAlt
          name="currentIndustry"
          id="currentIndustry"
          values={{ currentIndustry }}
          onChange={handleIndustryChange}
          options={uniqueIndustryList}
          selectText="Choose industry..."
        />

        {renderContent()}
      </Scrollable>
    </Layout>
  );
};

export default Expenses;
