import React from 'react';

import SuggestedRulesList from '../SuggestedRulesList';

const SuggestedExpenseRules = (props) => {
  return (
    <SuggestedRulesList
      ruleType="EXPENSE"
      header="other business expenses"
      title="You spend money in these places:"
      text="Tag any business expenses to the right job."
      searchText="Search expenses"
      {...props}
    />
  );
};

export default SuggestedExpenseRules;
