import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';

import { GET_JOBS } from '../../graph/jobs';

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: 30,
    fontSize: theme.typography.body2.fontSize,
    width: 90,
    textTransform: 'uppercase',
    textAlign: 'center',
    backgroundColor: '#EBF5FA',
    color: '#343858',
    fontWeight: '600',

    '&:before': {
      display: 'none',
    },
    '&:after': {
      display: 'none',
    },
    '&:hover': {
      opacity: 0.7,
      backgroundColor: '#EBF5FA',
    },
    '& option': {
      all: 'unset',
    },
    '& svg': {
      display: 'none',
    },
  },
  active: {
    backgroundColor: '#ffffff !important',
    color: '#252525 !important',
  },
  inputInner: {
    padding: '5px 8px 4px 8px !important',
    fontSize: '10px',
  },
  dropDownItem: {
    padding: '0 10px 0 12px',
    minHeight: 42,
    minWidth: 220,
  },
  dropDownItemText: {
    fontSize: theme.typography.body2.fontSize,
  },
  actionBar: {
    padding: '5px 10px 0 5px',
    minHeight: 42,
    display: 'flex',
    justifyContent: 'space-between',
  },
  listItemIcon: {
    minWidth: '40px',
  },
}));

const TagSwitcher = ({
  value,
  transactionId,
  jobType,
  disabled,
  active,
  handleAddNewRule,
  match,
}) => {
  const classes = useStyles();

  const { loading: fetching, data } = useQuery(GET_JOBS);

  const filteredJobs = data?.jobs?.filter(
    (jobItem) =>
      jobItem.name &&
      jobItem.name !== 'Tax relief' &&
      (((jobType === 'UNKNOWN' || jobType === 'INCOME') &&
        jobItem.jobType.incomeTag?.id) ||
        (jobType === 'EXPENSE' && jobItem.jobType.expenseTag?.id)),
  );

  const handleChange = (e) => {
    const job = filteredJobs.find((jobItem) => jobItem.id === e.target.value);

    handleAddNewRule({
      transactionId,
      jobId: e.target.value,
      jobName: job.name,
      ruleType: jobType === 'EXPENSE' ? 'EXPENSE' : 'INCOME',
      match,
    });
  };

  return (
    <>
      <FormControl>
        <Select
          className={clsx(classes.input, { [classes.active]: active })}
          variant="filled"
          onChange={handleChange}
          value={value || ''}
          e
          disabled={disabled}
          name={transactionId}
          id={transactionId}
          displayEmpty
          inputProps={{ className: classes.inputInner }}
          renderValue={(selected) =>
            selected
              ? (filteredJobs || []).find((jobItem) => jobItem.id === selected)
                  ?.name
              : 'SELECT JOB'
          }
        >
          {!fetching &&
            filteredJobs &&
            filteredJobs.map(({ id: jobId, name }) => (
              <MenuItem
                className={classes.dropDownItem}
                key={jobId}
                value={jobId}
              >
                <ListItemText
                  primaryTypographyProps={{
                    className: classes.dropDownItemText,
                  }}
                  primary={name}
                />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );
};

TagSwitcher.propTypes = {
  value: PropTypes.string,
  transactionId: PropTypes.string.isRequired,
  jobType: PropTypes.string,
  job: PropTypes.shape({
    jobType: PropTypes.shape({}),
  }),
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  handleAddNewRule: PropTypes.func.isRequired,
  match: PropTypes.string.isRequired,
};

TagSwitcher.defaultProps = {
  value: null,
  jobType: null,
  job: null,
  disabled: false,
  active: false,
};

export default TagSwitcher;
