import React from 'react';
import { navigate } from '@reach/router';

import Layout from '../../components/Layout';

import BackHeader from '../../components/BackHeader';
import SignUpForm from '../../components/SignUpForm';

import useTrackPage from '../../hooks/useTrackPage';

const SetUpLogin = () => {
  useTrackPage('/save-for-later');

  const handleSuccess = () => {
    navigate('/finmo-connect');
  };

  return (
    <Layout>
      <BackHeader title="Set up a login" />
      <SignUpForm
        title="Create an account"
        text="First, let’s set up a login for you. You’ll use these details to access Clarity and Finmo 😊."
        submitButtonText="Continue"
        onSuccess={handleSuccess}
      />
    </Layout>
  );
};

export default SetUpLogin;
