import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import dayjs from 'dayjs';

import Layout from '../../components/Layout';
import Scrollable from '../../components/Scrollable';
import ResultsHeader from '../../components/ResultsHeader';
import ResultCard from '../../components/ResultCard';
import Title from '../../components/Title';
import ValueList from '../../components/ValueList';
import PieChart from '../../components/PieChart';
import ResultFeedback from '../../components/ResultFeedback';

import useTrackPage from '../../hooks/useTrackPage';

import { GET_SUBSCRIPTIONS } from '../../graph/clarity';

const Subscriptions = () => {
  const {
    data: { subscriptions },
    loading,
  } = useQuery(GET_SUBSCRIPTIONS, {
    variables: {
      dateTo: dayjs().startOf('hour'),
      dateFrom: dayjs().subtract(4, 'months').startOf('hour'),
    },
  });

  useTrackPage('/results/subscriptions');

  const lastFullMonth = dayjs()
    .startOf('month')
    .subtract(1, 'day')
    .format('MMMM');

  const renderContent = () => {
    if (loading) {
      return false;
    }

    return (
      <>
        <ResultCard
          text={`Spent on subscriptions in ${lastFullMonth}`}
          value={subscriptions.recurring}
        />
        <Title variant="h3" smallText="Per month" largeMarginTop>
          Breakdown by subscription
        </Title>

        <PieChart
          title="Subscriptions"
          data={subscriptions.top}
          total={subscriptions.recurring}
          valueKey="percentage"
          labelKey="label"
        />

        <Title variant="h3" smallText="Per month" largeMarginTop>
          Your subscriptions
        </Title>

        <ValueList
          data={subscriptions.all}
          valueKey="recurring"
          labelKey="label"
        />
        <ResultFeedback section="subscriptions" />
      </>
    );
  };

  return (
    <Layout fetching={loading} animateIn>
      <Scrollable>
        <ResultsHeader title="Subscriptions" hideDateRangeSelector />
        {renderContent()}
      </Scrollable>
    </Layout>
  );
};

export default Subscriptions;
