import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';

import useCalculator from '../../hooks/useCalculator';

const JobShiftWaitingTime = (props) => {
  const { onJobFormChange, jobFormData, job } = useCalculator(props);

  return (
    <Card
      header={job.name}
      title="Based on the last month, when you work more than one shift in a 24 hour period, how long do you spend waiting between shifts on average?"
    >
      <CalculatorForm
        fields={[
          {
            id: 'timeBetweenShifts',
            name: 'timeBetweenShifts',
            type: 'number',
            label:
              'Based on the last month, when you work more than one shift in a 24 hour period, how long do you spend waiting between shifts on average?',
            visuallyHidden: true,
            suffix: 'Minutes',
          },
        ]}
        values={jobFormData}
        onChange={onJobFormChange}
      />
    </Card>
  );
};

export default JobShiftWaitingTime;
