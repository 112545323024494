import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';

import useCalculator from '../../hooks/useCalculator';

const JobPAYEPayment = (props) => {
  const { onJobFormChange, jobFormData, job } = useCalculator(props);

  return (
    <Card
      header={job.name}
      title="Based on the last month, how much are you paid to do this work before taxes?"
    >
      <CalculatorForm
        fields={[
          {
            id: 'payePaymentAmount',
            name: 'payePaymentAmount',
            type: 'number',
            prefix: '£',
            label:
              'Based on the last month, how much are you paid to do this work before taxes?',
            visuallyHidden: true,
          },
          {
            id: 'payePaymentFrequency',
            name: 'payePaymentFrequency',
            type: 'radio',
            options: [
              {
                id: 'perHour',
                value: 'perHour',
                label: 'Per hour',
              },
              {
                id: 'perMonth',
                value: 'perMonth',
                label: 'Per month',
              },
            ],
          },
        ]}
        values={jobFormData}
        onChange={onJobFormChange}
      />
    </Card>
  );
};

export default JobPAYEPayment;
