import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  step: {
    padding: 0,
    margin: '0 5px',
    width: 10,
    height: 10,
    background: '#4797D2',
    borderRadius: 30,
    opacity: 0.2,
  },
  active: {
    opacity: 1,
  },
});

const Steps = ({ totalSteps, currentStep }) => {
  const classes = useStyles();
  const stepArray = Array(totalSteps).fill(null);

  return (
    <ul className={classes.wrapper}>
      {stepArray.map((step, i) => (
        <li
          className={clsx(classes.step, {
            [classes.active]: i === currentStep - 1,
          })}
        />
      ))}
    </ul>
  );
};

Steps.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};

export default Steps;
