import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import Card from '../Card';

import { isArrayWithLenth, roundNumber } from '../../utils';

import { baseColors } from '../../theme/base';

const useStyles = makeStyles((theme) => ({
  noDataMessage: {
    position: 'absolute',
    left: '30px',
    right: '30px',
    top: '50%',
    transform: 'translate(0, -100%)',
    textAlign: 'center',
    color: theme.palette.grey[500],
  },
  list: {
    padding: 0,
    margin: 0,

    '& li': {
      display: 'flex',
      margin: 0,
      padding: '10px 0',
      fontSize: 12,
      fontWeight: 500,
      alignItems: 'center',
      borderBottom: 'solid 1px #E5E5E5',

      '&:last-child': {
        borderBottom: 'none',
        paddingBottom: 0,
      },

      '&:first-child': {
        paddingTop: 0,
      },
    },

    '& i': {
      display: 'block',
      width: 12,
      height: 12,
      borderRadius: '100%',
      marginRight: 10,
    },
    '& span': {
      marginLeft: 'auto',
      fontSize: 10,
      fontWeight: 400,
      lineHeight: 1,
    },
  },
  title: {
    margin: '0 0 7px 0',
    fontSize: 14,
    fontWeight: 500,
  },
  progressList: {
    padding: 0,
    margin: 0,

    '& li': {
      display: 'flex',
      margin: 0,
      padding: '7px 0',
      alignItems: 'center',

      '&:last-child': {
        paddingBottom: 0,
      },
    },
    '& span': {
      marginLeft: 14,
      fontSize: 10,
      fontWeight: 400,
      lineHeight: 1,
    },
  },
  progress: {
    flex: '1 1 auto',
    height: 10,
    borderRadius: 10,
  },
  progressBlue: {
    backgroundColor: baseColors.blueLight,
  },
  progressBarBlue: {
    backgroundColor: baseColors.blue,
    borderRadius: 10,
  },
  progressGreen: {
    backgroundColor: baseColors.greenLight,
  },
  progressBarGreen: {
    backgroundColor: baseColors.green,
    borderRadius: 10,
  },
  progressPink: {
    backgroundColor: '#4797D2',
  },
  progressBarPink: {
    backgroundColor: '#4797D2',
    borderRadius: 10,
  },
}));

const colourMapping = {
  0: baseColors.blue,
  1: baseColors.green,
  2: '#4797D2',
};

const HighestNet = ({ data, noDataMessage }) => {
  const classes = useStyles();

  const progressBarClasses = {
    0: {
      barColorPrimary: classes.progressBarBlue,
      colorPrimary: classes.progressBlue,
    },
    1: {
      barColorPrimary: classes.progressBarGreen,
      colorPrimary: classes.progressGreen,
    },
    2: {
      barColorPrimary: classes.progressBarPink,
      colorPrimary: classes.progressPink,
    },
  };

  if (!isArrayWithLenth(data)) {
    return (
      <div className={classes.container}>
        <p className={classes.noDataMessage}>{noDataMessage}</p>
      </div>
    );
  }

  return (
    <>
      <Card smallMargin>
        <ol className={classes.list}>
          {data.map((entry, index) => (
            <li>
              <i style={{ backgroundColor: colourMapping[index] }} />
              {entry.id}
              <span>
                <strong>{roundNumber(entry.hours)}</strong> hours
              </span>
            </li>
          ))}
        </ol>
      </Card>
      <Card smallMargin>
        <h4 className={classes.title}>NET % per job</h4>
        <ol className={classes.progressList}>
          {data.map((entry, index) => (
            <li>
              <LinearProgress
                classes={{
                  root: classes.progress,
                  ...progressBarClasses[index],
                }}
                variant="determinate"
                value={entry.netPercentage}
              />
              <span>{Math.round(entry.netPercentage)}%</span>
            </li>
          ))}
        </ol>
      </Card>
    </>
  );
};

HighestNet.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  noDataMessage: PropTypes.string,
};

HighestNet.defaultProps = {
  noDataMessage: 'No data',
};

export default HighestNet;
