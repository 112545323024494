import React from 'react';

import Card from '../Card';

const Icon = () => (
  <svg
    width="60"
    height="58"
    viewBox="0 0 60 58"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29 58C13.009 58 0 44.991 0 29C0 13.009 13.009 0 29 0C44.991 0 58 13.009 58 29C58 36.423 54.64 43.974 48.782 49.714C48.387 50.1 47.754 50.094 47.368 49.699C46.982 49.305 46.988 48.672 47.383 48.285C52.859 42.919 56 35.89 56 29C56 14.112 43.888 2 29 2C14.112 2 2 14.112 2 29C2 43.888 14.112 56 29 56C29.552 56 30 56.448 30 57C30 57.552 29.552 58 29 58Z"
      fill="#4797D2"
    />
    <path
      d="M48.0811 50C47.5861 50 47.1561 49.632 47.0911 49.128L45.8131 39.21C45.7421 38.662 46.1291 38.161 46.6771 38.09C47.2221 38.012 47.7261 38.405 47.7971 38.954L48.9471 47.88L57.8731 46.73C58.4181 46.652 58.9221 47.045 58.9931 47.594C59.0641 48.142 58.6771 48.643 58.1291 48.714L48.2111 49.992C48.1671 49.998 48.1241 50 48.0811 50Z"
      fill="#4797D2"
    />
    <path
      d="M29.019 30C28.467 30 28.019 29.552 28.019 29V13C28.019 12.448 28.467 12 29.019 12C29.571 12 30.019 12.448 30.019 13V29C30.019 29.552 29.571 30 29.019 30Z"
      fill="#4797D2"
    />
    <path
      d="M39.019 30H29.019C28.467 30 28.019 29.552 28.019 29C28.019 28.448 28.467 28 29.019 28H39.019C39.571 28 40.019 28.448 40.019 29C40.019 29.552 39.571 30 39.019 30Z"
      fill="#4797D2"
    />
    <path
      d="M29 8C28.448 8 28 7.552 28 7V5C28 4.448 28.448 4 29 4C29.552 4 30 4.448 30 5V7C30 7.552 29.552 8 29 8Z"
      fill="#4797D2"
    />
    <path
      d="M29 54C28.448 54 28 53.552 28 53V51C28 50.448 28.448 50 29 50C29.552 50 30 50.448 30 51V53C30 53.552 29.552 54 29 54Z"
      fill="#4797D2"
    />
    <path
      d="M7 30H5C4.448 30 4 29.552 4 29C4 28.448 4.448 28 5 28H7C7.552 28 8 28.448 8 29C8 29.552 7.552 30 7 30Z"
      fill="#4797D2"
    />
    <path
      d="M53 30H51C50.448 30 50 29.552 50 29C50 28.448 50.448 28 51 28H53C53.552 28 54 28.448 54 29C54 29.552 53.552 30 53 30Z"
      fill="#4797D2"
    />
    <path
      d="M13.4441 14.444C13.1881 14.444 12.9321 14.346 12.7371 14.151L11.3221 12.736C10.9311 12.345 10.9311 11.713 11.3221 11.322C11.7131 10.931 12.3451 10.931 12.7361 11.322L14.1511 12.737C14.5421 13.128 14.5421 13.76 14.1511 14.151C13.9561 14.346 13.7001 14.444 13.4441 14.444Z"
      fill="#4797D2"
    />
    <path
      d="M12.0291 46.971C11.7731 46.971 11.5171 46.873 11.3221 46.678C10.9311 46.287 10.9311 45.655 11.3221 45.264L12.7371 43.849C13.1281 43.458 13.7601 43.458 14.1511 43.849C14.5421 44.24 14.5421 44.872 14.1511 45.263L12.7361 46.678C12.5411 46.873 12.2851 46.971 12.0291 46.971Z"
      fill="#4797D2"
    />
    <path
      d="M44.5559 14.444C44.2999 14.444 44.0439 14.346 43.8489 14.151C43.4579 13.76 43.4579 13.128 43.8489 12.737L45.2639 11.322C45.6549 10.931 46.2869 10.931 46.6779 11.322C47.0689 11.713 47.0689 12.345 46.6779 12.736L45.2629 14.151C45.0679 14.346 44.8119 14.444 44.5559 14.444Z"
      fill="#4797D2"
    />
  </svg>
);

const ConnectSuccess = () => {
  return (
    <Card
      icon={Icon}
      title="It'll take us about a minute to analyse your bank transactions"
      text="You can carry on to the next section for now."
      center
    />
  );
};

export default ConnectSuccess;
