import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ApolloProvider } from '@apollo/react-hooks';

import CssBaseline from '@material-ui/core/CssBaseline';

import Client, { persistor } from './config/Client';

import App from './App';

import { GlobalThemeProvider } from './providers/theme';
import { CalculatorFormProvider } from './providers/calculator';

import * as serviceWorker from './serviceWorker';

const SCHEMA_VERSION = '2';
const SCHEMA_VERSION_KEY = 'LLW_SCHEMA_VERSION';

Sentry.init({
  dsn: 'https://e9aa0939a4cb423c8d70ba1c02378a74@o223633.ingest.sentry.io/5807998',
  integrations: [new Integrations.BrowserTracing()],
});

const renderApp = async () => {
  const currentVersion = await window.localStorage.getItem(SCHEMA_VERSION_KEY);

  try {
    if (currentVersion === SCHEMA_VERSION) {
      await persistor.restore();
    } else {
      await persistor.purge();
      await window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
    }
  } catch (err) {
    // eslint-disable-next-line
    console.error(err);
  }

  ReactDOM.render(
    <ApolloProvider client={Client}>
      <GlobalThemeProvider>
        <CssBaseline>
          <CalculatorFormProvider>
            <App />
          </CalculatorFormProvider>
        </CssBaseline>
      </GlobalThemeProvider>
    </ApolloProvider>,
    document.getElementById('root'),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();
};

renderApp();
