/**
 * @format
 * @flow
 */

import gql from 'graphql-tag';

const GET_TAGS = gql`
  query GetTags {
    tags {
      id
      name
      displayName
      tagType
    }
  }
`;

const GET_ALL_RULES = gql`
  query GetRules {
    rules {
      id
      match
      ruleType
      job {
        id
        name
        jobType {
          id
          name
        }
      }
    }
  }
`;

const GET_RULE = gql`
  query GetRule($ruleId: ID!) {
    rule(ruleId: $ruleId) {
      id
      match
      shouldNotify
      tags {
        id
        name
        displayName
        tagType
      }
      transactionSet {
        id
        amount
      }
    }
  }
`;

const UPDATE_RULE = gql`
  mutation UpdateRule($ruleId: ID!, $shouldNotify: Boolean, $tagIds: [ID]!) {
    updateRule(ruleId: $ruleId, shouldNotify: $shouldNotify, tagIds: $tagIds) {
      id
      match
      shouldNotify
      tags {
        id
        name
        displayName
        tagType
      }
      transactionSet {
        id
        amount
      }
    }
  }
`;

const CREATE_RULE = gql`
  mutation CreateRule(
    $transactionId: ID!
    $shouldNotify: Boolean!
    $jobId: ID!
    $ruleType: String!
  ) {
    createRule(
      transactionId: $transactionId
      shouldNotify: $shouldNotify
      jobId: $jobId
      ruleType: $ruleType
    ) {
      id
      match
      shouldNotify
      job {
        id
        name
      }
      ruleType
      transactionSet {
        id
        amount
      }
    }
  }
`;

const DELETE_RULE = gql`
  mutation DeleteRule($ruleId: ID!) {
    deleteRule(ruleId: $ruleId) {
      success
    }
  }
`;

const SUGGESTED_RULES = gql`
  query SuggestedRules(
    $ruleType: RuleTypeEnum
    $dateFrom: Date
    $dateTo: Date
  ) {
    suggestedRules(ruleType: $ruleType, dateFrom: $dateFrom, dateTo: $dateTo) {
      description
      transaction {
        id
      }
      matchingTransactionCount
      ruleType
    }
  }
`;

export {
  GET_TAGS,
  GET_ALL_RULES,
  GET_RULE,
  CREATE_RULE,
  UPDATE_RULE,
  DELETE_RULE,
  SUGGESTED_RULES,
};
