import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { navigate } from '@reach/router';

import { makeStyles } from '@material-ui/core/styles';

import Button from '../Button';

const useStyles = makeStyles(() => ({
  card: {
    background: '#ffffff',
    width: '100%',
    margin: '5px 0',
    textAlign: 'left',
    padding: '24px',
    position: 'relative',
    color: '#343858',
    border: '1px solid #E5E5E5',
    boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.05)',
    borderRadius: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  complete: {
    background: '#E3F3EB',
    borderColor: '#53A467',
  },
  title: {
    fontWeight: '500',
    fontSize: '16px',
    margin: '0 0 8px',
  },
  text: {
    fontSize: '12px',
    margin: '0 0 8px',
  },
  button: {
    position: 'absolute',
    top: '50%',
    right: '20px',
    backgroundColor: '#4797D2',
    width: '22px',
    height: '22px',
    borderRadius: '100%',
    border: 'none',
    cursor: 'pointer',
    transform: 'translateY(-50%)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='4' height='6' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.85506 2.63598L1.65542 0.35352C1.46217 0.152994 1.14387 0.152994 0.950622 0.35352C0.757371 0.554047 0.757372 0.884326 0.950622 1.08485L2.79219 3.00165L0.944939 4.91845C0.751688 5.11897 0.751688 5.44925 0.944939 5.64978C1.04156 5.75004 1.17229 5.80312 1.29734 5.80312C1.42806 5.80312 1.55311 5.75004 1.64973 5.64978L3.85506 3.36732C4.04831 3.16679 4.04831 2.83651 3.85506 2.63598Z' fill='white'/%3E%3C/svg%3E%0A")`,

    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: -33,
      bottom: -33,
      right: -22,
      width: 89,
      height: 89,
    },
  },
  buttonComplete: {
    backgroundColor: '#53A467',
    backgroundImage: `url("data:image/svg+xml,%3Csvg width='9' height='8' viewBox='0 0 9 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.73602 0.131241C8.46268 -0.0729121 8.08684 -0.0388861 7.88183 0.233318L2.96169 6.56207L1.01413 4.8608C0.774961 4.62262 0.364949 4.65664 0.159943 4.92885C-0.0792306 5.16703 -0.0450627 5.57533 0.228278 5.77949L2.62001 7.85505C2.75668 7.95712 2.92752 8.02517 3.06419 7.99115C3.2692 7.99115 3.4742 7.9231 3.61088 7.75297L8.90686 0.947855C9.0777 0.743702 9.00936 0.36942 8.73602 0.131241Z' fill='white'/%3E%3C/svg%3E%0A")`,
  },
  textLink: {
    padding: 0,
    border: 'none',
    background: 'none',
    fontSize: '12px',
    display: 'inline-block',
    color: '#4797D2',
    margin: '0 5px',
    position: 'relative',
    textDecoration: 'underline',
    cursor: 'pointer',

    '&:first-of-type': {
      marginLeft: 0,
    },

    '&:hover': {
      textDecoration: 'none',
    },

    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      top: -10,
      bottom: -10,
      right: -10,
      left: -10,
    },
  },
  textLinkComplete: {
    color: '#343858',
  },
}));

const JobCard = ({
  subIndustryName,
  paymentTypeName,
  isComplete,
  id,
  onRemoveJob,
  removingJob,
  numberOfShiftsPerDay,
  hideAction,
}) => {
  const classes = useStyles();

  if (!subIndustryName || !paymentTypeName) return false;

  const handleJobClick = () => {
    if (isComplete && paymentTypeName === 'PAYE') {
      navigate(`/calculator/jobs/${id}/6`);
      return;
    }

    if (isComplete && paymentTypeName === 'Day Rate') {
      navigate(`/calculator/jobs/${id}/5`);
      return;
    }

    if (
      isComplete &&
      paymentTypeName === 'Shift work' &&
      numberOfShiftsPerDay === 1
    ) {
      navigate(`/calculator/jobs/${id}/6`);
      return;
    }

    if (isComplete) {
      navigate(`/calculator/jobs/${id}/7`);
      return;
    }

    navigate(`/calculator/jobs/${id}/1`);
  };

  const handleRemoveJob = () => {
    onRemoveJob(id);
  };

  return (
    <div className={clsx(classes.card, { [classes.complete]: isComplete })}>
      <div>
        <h3 className={classes.title}>{subIndustryName}</h3>
        <p className={classes.text}>{paymentTypeName}</p>
        <button
          className={clsx(classes.textLink, {
            [classes.textLinkComplete]: isComplete,
          })}
          type="button"
          onClick={handleRemoveJob}
          disabled={removingJob}
        >
          {removingJob ? 'Removing...' : 'Remove'}
        </button>
      </div>
      {!hideAction && (
        <Button onClick={handleJobClick} small green={isComplete}>
          {isComplete ? 'Edit' : 'Start'}
        </Button>
      )}
    </div>
  );
};

JobCard.propTypes = {
  subIndustryName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  paymentTypeName: PropTypes.string.isRequired,
  isComplete: PropTypes.bool.isRequired,
  onRemoveJob: PropTypes.func.isRequired,
  removingJob: PropTypes.bool,
  numberOfShiftsPerDay: PropTypes.number,
  hideAction: PropTypes.bool,
};

JobCard.defaultProps = {
  removingJob: false,
  numberOfShiftsPerDay: 0,
  hideAction: false,
};

export default JobCard;
