import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Card from '../Card';
import Button from '../Button';
import Steps from '../Steps';

const useStyles = makeStyles({
  wrapper: {
    margin: 'auto 0',
  },
  footer: {
    color: '#343858',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 20,
    alignItems: 'center',
  },
});

const Intro = ({
  title,
  text,
  onPrevClick,
  onNextClick,
  totalSteps,
  currentStep,
  icon,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Card title={title} text={text} minHeight icon={icon} center overflow />

      <footer className={classes.footer}>
        <Button textOnly onClick={onPrevClick}>
          Prev
        </Button>

        <Steps totalSteps={totalSteps} currentStep={currentStep} />

        <Button textOnly onClick={onNextClick}>
          Next
        </Button>
      </footer>
    </div>
  );
};

Intro.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  onPrevClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  icon: PropTypes.node,
};

Intro.defaultProps = {
  title: null,
  text: null,
  icon: null,
};

export default Intro;
