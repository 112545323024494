import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';

import useCalculator from '../../hooks/useCalculator';

const ExpensesPhone = (props) => {
  const { onFormChange, formData } = useCalculator(props);

  return (
    <Card
      subTitle="Answer all"
      title="What do your monthly phone costs include?"
    >
      <CalculatorForm
        fields={[
          {
            id: 'phoneType',
            name: 'phoneType',
            type: 'radio',
            visuallyHidden: true,
            label: 'What do your monthly phone costs include?',
            options: [
              {
                id: 'plan',
                label: 'Only the cost of the plan (minutes, data etc.)',
                value: 'plan',
              },
              {
                id: 'both',
                label:
                  'The cost of the plan (minutes, data etc.) and the phone itself',
                value: 'both',
              },
            ],
          },
        ]}
        values={formData}
        onChange={onFormChange}
      />
    </Card>
  );
};

export default ExpensesPhone;
