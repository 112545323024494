import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';

import Button from '../Button';

const useStyles = makeStyles(() => ({
  header: {
    color: '#343858',
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    height: '30px',
    justifyContent: 'space-between',
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',

    '& > button, & > a': {
      marginLeft: '10px',
    },
  },
  text: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '16px',
  },
  backButton: {
    color: '#343858',
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',

    '& > svg': {
      marginRight: '10px',
    },
  },
}));

const BackHeader = ({ title, showSave, backUrl }) => {
  const classes = useStyles();

  const handleGoBack = () => {
    if (backUrl) {
      navigate(backUrl);
      return;
    }

    window.history.back();
  };

  return (
    <header className={classes.header}>
      <div className={classes.inner}>
        <button
          type="button"
          onClick={handleGoBack}
          className={classes.backButton}
        >
          <svg
            width="7"
            height="13"
            viewBox="0 0 7 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.317052 7.35229L5.12877 12.6509C5.55151 13.1164 6.24778 13.1164 6.67052 12.6509C7.09325 12.1854 7.09325 11.4186 6.67052 10.9531L2.6421 6.50342L6.68295 2.05371C7.10568 1.5882 7.10568 0.821486 6.68295 0.355977C6.47158 0.123223 6.18561 1.02744e-06 5.91208 1.02418e-06C5.62611 1.02077e-06 5.35258 0.123223 5.14121 0.355977L0.317052 5.65455C-0.105684 6.12006 -0.105684 6.88678 0.317052 7.35229Z"
              fill="#4797D2"
            />
          </svg>

          <span className={classes.text}>{title}</span>
        </button>

        {showSave && (
          <div className={classes.buttons}>
            <Button secondary small to="/help">
              Help
            </Button>

            <Button to="/save-and-exit" small>
              Save & Exit
            </Button>
          </div>
        )}
      </div>
    </header>
  );
};

BackHeader.propTypes = {
  title: PropTypes.string,
  showSave: PropTypes.bool,
  backUrl: PropTypes.string,
};

BackHeader.defaultProps = {
  title: 'Back',
  showSave: false,
  backUrl: null,
};

export default BackHeader;
