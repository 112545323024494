import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles(() => ({
  wrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center' },
  active: {
    fontWeight: 700,
  },
  thumb: {
    background: '#4797D2',
    boxShadow: 'none',
  },
  track: {
    background: '#E5E5E5!important',
  },
}));

const SwitchField = ({ name, value, offLabel, onLabel, onChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <label
        htmlFor={name}
        className={clsx(classes.label, { [classes.active]: !value })}
      >
        {offLabel}
      </label>
      <Switch
        checked={value}
        onChange={onChange}
        name={name}
        id={name}
        inputProps={{ 'aria-label': 'secondary checkbox' }}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
      />
      <label
        htmlFor={name}
        className={clsx(classes.label, { [classes.active]: value })}
      >
        {onLabel}
      </label>
    </div>
  );
};

SwitchField.propTypes = {
  name: PropTypes.string.isRequired,
};

SwitchField.defaultProps = {};

export default SwitchField;
