export const industries = [
  {
    id: 'transportation',
    name: 'Delivery services',
    subIndustries: [
      {
        id: 'foodDelivery',
        name: 'Food Delivery',
        industry: 'Delivery services',
        industryId: 'transportation',
      },
      {
        id: 'peopleDelivery',
        name: 'People Delivery',
        industry: 'Delivery services',
        industryId: 'transportation',
      },
      {
        id: 'parcelDelivery',
        name: 'Parcel Delivery',
        industry: 'Delivery services',
        industryId: 'transportation',
      },
      {
        id: 'deliveryGeneral',
        name: 'General Delivery',
        industry: 'Delivery services',
        industryId: 'transportation',
      },
    ],
  },
  {
    id: 'beauty',
    name: 'Health and beauty',
    subIndustries: [
      {
        id: 'hairdressing',
        name: 'Hairdressing',
        industry: 'Health and beauty',
        industryId: 'beauty',
      },
      {
        id: 'nails',
        name: 'Nails',
        industry: 'Health and beauty',
        industryId: 'beauty',
      },
      {
        id: 'beautyGeneral',
        name: 'General Health and beauty',
        industry: 'Health and beauty',
        industryId: 'beauty',
      },
    ],
  },
  {
    id: 'hospitality',
    name: 'Hospitality',
    subIndustries: [
      {
        id: 'hospitalityGeneral',
        name: 'General Hospitality',
        industry: 'Hospitality',
        industryId: 'hospitality',
      },
    ],
  },
  {
    id: 'retail',
    name: 'Retail and sales',
    subIndustries: [
      {
        id: 'retailGeneral',
        name: 'General Retail and sales',
        industry: 'Retail and sales',
        industryId: 'retail',
      },
    ],
  },
  {
    id: 'construction',
    name: 'Construction and building trades',
    subIndustries: [
      {
        id: 'constructionGeneral',
        name: 'General Construction',
        industry: 'Construction and building trades',
        industryId: 'construction',
      },
    ],
  },
  {
    id: 'technology',
    name: 'Technology',
    subIndustries: [
      {
        id: 'technologyGeneral',
        name: 'General Technology',
        industry: 'Technology',
        industryId: 'technology',
      },
    ],
  },
  {
    id: 'media',
    name: 'Media, artistic, literary',
    subIndustries: [
      {
        id: 'mediaGeneral',
        name: 'General Media',
        industry: 'Media, artistic, literary',
        industryId: 'media',
      },
    ],
  },
  {
    id: 'education',
    name: 'Education',
    subIndustries: [
      {
        id: 'educationGeneral',
        name: 'General Education',
        industry: 'Education',
        industryId: 'education',
      },
    ],
  },
  {
    id: 'agriculture',
    name: 'Agriculture',
    subIndustries: [
      {
        id: 'agricultureGeneral',
        name: 'General Agriculture',
        industry: 'Agriculture',
        industryId: 'agriculture',
      },
    ],
  },
  {
    id: 'accounting',
    name: 'Accounting and finance',
    subIndustries: [
      {
        id: 'accountingGeneral',
        name: 'General Accounting and finance',
        industry: 'Accounting and finance',
        industryId: 'accounting',
      },
    ],
  },
  {
    id: 'hr',
    name: 'HR and recruitment',
    subIndustries: [
      {
        id: 'hrGeneral',
        name: 'General HR',
        industry: 'HR and recruitment',
        industryId: 'hr',
      },
    ],
  },
  {
    id: 'admin',
    name: 'Admin & other professional services',
    subIndustries: [
      {
        id: 'adminGeneral',
        name: 'General Admin',
        industry: 'Admin & other professional services',
        industryId: 'admin',
      },
    ],
  },
];

export const paymentTypes = [
  {
    id: 'gig',
    name: 'Gig work',
  },
  {
    id: 'shift',
    name: 'Shift work',
  },
  {
    id: 'dayRate',
    name: 'Day rate',
  },
  {
    id: 'PAYE',
    name: 'Full time employment',
  },
];
