import React, { useContext } from 'react';
import { useApolloClient, useMutation, useQuery } from '@apollo/react-hooks';
import { navigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';

import Layout from '../../components/Layout';
import Button from '../../components/Button';
import Footer from '../../components/Footer';
import Card from '../../components/Card';
import Loader from '../../components/Loader';

import useTrackPage from '../../hooks/useTrackPage';

import { CREATE_USER, GET_TOKEN, IS_AUTHENTICATED } from '../../graph/auth';

import {
  CalculatorFormContext,
  defaultFormValues,
} from '../../providers/calculator';

import fca from '../../assets/fca.png';

const useStyles = makeStyles({
  fca: {
    margin: '0 auto -10px',
    display: 'block',
    maxWidth: '300px',
  },
  disclaimer: {
    textAlign: 'center',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#343858',
    marginBottom: '30px',

    '& > a': {
      color: '#343858',
    },
  },
});

const Icon = () => (
  <svg
    width="59"
    height="59"
    viewBox="0 0 59 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.5 59C13.239 59 0 45.761 0 29.5C0 13.239 13.239 0 29.5 0C45.761 0 59 13.239 59 29.5C59 45.761 45.761 59 29.5 59ZM29.5 1.43902C14.0065 1.43902 1.43902 14.0065 1.43902 29.5C1.43902 44.9935 14.0065 57.561 29.5 57.561C44.9935 57.561 57.561 44.9935 57.561 29.5C57.561 14.0065 44.9935 1.43902 29.5 1.43902Z"
      fill="#4797D2"
    />
    <path
      d="M15.5413 37.6059H10.8885C10.5047 37.6059 10.1689 37.2701 10.1689 36.8864V31.2742C10.1689 30.8905 10.5047 30.5547 10.8885 30.5547H15.5413C15.925 30.5547 16.2608 30.8905 16.2608 31.2742V36.8864C16.2608 37.2701 15.925 37.6059 15.5413 37.6059ZM11.608 36.1669H14.8218V31.9937H11.608V36.1669Z"
      fill="#4797D2"
    />
    <path
      d="M48.1114 37.6059H41.9236C41.5399 37.6059 41.2041 37.2701 41.2041 36.8864V31.2742C41.2041 30.8905 41.5399 30.5547 41.9236 30.5547H48.1114C48.4952 30.5547 48.8309 30.8905 48.8309 31.2742V36.8864C48.8309 37.2701 48.5431 37.6059 48.1114 37.6059ZM42.6431 36.1669H47.3919V31.9937H42.6431V36.1669Z"
      fill="#4797D2"
    />
    <path
      d="M15.6374 48.8314H10.8406C10.4569 48.8314 10.1211 48.4956 10.1211 48.1119V42.5476C10.1211 42.1639 10.4569 41.8281 10.8406 41.8281H15.6374C16.0211 41.8281 16.3569 42.1639 16.3569 42.5476V48.1598C16.3569 48.5436 16.0211 48.8314 15.6374 48.8314ZM11.6081 47.3924H14.9658V43.2192H11.6081C11.6081 43.2672 11.6081 47.3924 11.6081 47.3924Z"
      fill="#4797D2"
    />
    <path
      d="M33.145 48.8314H24.367C23.9832 48.8314 23.6475 48.4956 23.6475 48.1119V42.5476C23.6475 42.1639 23.9832 41.8281 24.367 41.8281H33.145C33.5288 41.8281 33.8645 42.1639 33.8645 42.5476V48.1598C33.8645 48.5436 33.5288 48.8314 33.145 48.8314ZM25.0865 47.3924H32.4255V43.2192H25.0865C25.0865 43.2672 25.0865 47.3924 25.0865 47.3924Z"
      fill="#4797D2"
    />
    <path
      d="M24.3676 48.8314H15.6375C15.2537 48.8314 14.918 48.4956 14.918 48.1119V42.5476C14.918 42.1639 15.2537 41.8281 15.6375 41.8281H24.3196C24.7033 41.8281 25.0391 42.1639 25.0391 42.5476V48.1598C25.0871 48.5436 24.7513 48.8314 24.3676 48.8314ZM16.357 47.3924H23.6001V43.2192H16.357V47.3924Z"
      fill="#4797D2"
    />
    <path
      d="M42.0193 48.8314H33.1453C32.7616 48.8314 32.4258 48.4956 32.4258 48.1119V42.5476C32.4258 42.1639 32.7616 41.8281 33.1453 41.8281H42.0193C42.403 41.8281 42.7388 42.1639 42.7388 42.5476V48.1598C42.7388 48.5436 42.403 48.8314 42.0193 48.8314ZM33.8648 47.3924H41.2998V43.2192H33.8648V47.3924Z"
      fill="#4797D2"
    />
    <path
      d="M48.1112 48.8305H42.0193C41.6356 48.8305 41.2998 48.4947 41.2998 48.111V42.4988C41.2998 42.1151 41.6356 41.7793 42.0193 41.7793H48.1592C48.5429 41.7793 48.8787 42.1151 48.8787 42.4988V48.111C48.8307 48.5427 48.5429 48.8305 48.1112 48.8305ZM42.7388 47.3915H47.4396V43.2183H42.7388V47.3915Z"
      fill="#4797D2"
    />
    <path
      d="M37.702 43.2192H28.7801C28.3963 43.2192 28.0605 42.8834 28.0605 42.4997V36.8875C28.0605 36.5037 28.3963 36.168 28.7801 36.168C29.1638 36.168 29.4996 36.5037 29.4996 36.8875V41.7802H36.9345V36.8875C36.9345 36.5037 37.2703 36.168 37.654 36.168C38.0378 36.168 38.3736 36.5037 38.3736 36.8875V42.4997C38.4215 42.8834 38.0857 43.2192 37.702 43.2192Z"
      fill="#4797D2"
    />
    <path
      d="M28.7801 43.2192H19.8582C19.4744 43.2192 19.1387 42.8834 19.1387 42.4997V36.8875C19.1387 36.5037 19.4744 36.168 19.8582 36.168C20.2419 36.168 20.5777 36.5037 20.5777 36.8875V41.7802H28.1086V36.8875C28.1086 36.5037 28.4444 36.168 28.8281 36.168C29.2118 36.168 29.5476 36.5037 29.5476 36.8875V42.4997C29.4996 42.8834 29.2118 43.2192 28.7801 43.2192Z"
      fill="#4797D2"
    />
    <path
      d="M19.8584 43.2192H10.8885C10.5047 43.2192 10.1689 42.8834 10.1689 42.4997V36.8875C10.1689 36.5037 10.5047 36.168 10.8885 36.168H19.8584C20.2421 36.168 20.5779 36.5037 20.5779 36.8875V42.4997C20.5779 42.8834 20.2421 43.2192 19.8584 43.2192ZM11.608 41.7802H19.1389V37.607H11.608V41.7802Z"
      fill="#4797D2"
    />
    <path
      d="M48.1109 43.2192H37.7019C37.3182 43.2192 36.9824 42.8834 36.9824 42.4997V36.8875C36.9824 36.5037 37.3182 36.168 37.7019 36.168H48.1588C48.5426 36.168 48.8784 36.5037 48.8784 36.8875V42.4997C48.8304 42.8834 48.5426 43.2192 48.1109 43.2192ZM38.4214 41.7802H47.4393V37.607H38.4214V41.7802Z"
      fill="#4797D2"
    />
    <path
      d="M21.7286 37.6059H15.5408C15.1571 37.6059 14.8213 37.2701 14.8213 36.8864V31.2742C14.8213 30.8905 15.1571 30.5547 15.5408 30.5547H21.7286C22.1123 30.5547 22.4481 30.8905 22.4481 31.2742C22.4481 31.6579 22.1123 31.9937 21.7286 31.9937H16.2603V36.1669H21.7286C22.1123 36.1669 22.4481 36.5027 22.4481 36.8864C22.4481 37.2701 22.1123 37.6059 21.7286 37.6059Z"
      fill="#4797D2"
    />
    <path
      d="M48.1109 31.9946H37.2703C36.8866 31.9946 36.5508 31.6588 36.5508 31.2751V25.6629C36.5508 25.2791 36.8866 24.9434 37.2703 24.9434H48.1589C48.5427 24.9434 48.8784 25.2791 48.8784 25.6629V31.2751C48.8305 31.7068 48.5427 31.9946 48.1109 31.9946ZM37.9898 30.5556H47.4394V26.3824H37.9898V30.5556Z"
      fill="#4797D2"
    />
    <path
      d="M41.9233 37.6059H37.2224C36.8387 37.6059 36.5029 37.2701 36.5029 36.8864C36.5029 36.5027 36.8387 36.1669 37.2224 36.1669H41.2037V31.9937H37.2704C36.8867 31.9937 36.5509 31.6579 36.5509 31.2742C36.5509 30.8905 36.8867 30.5547 37.2704 30.5547H41.9233C42.307 30.5547 42.6428 30.8905 42.6428 31.2742V36.8864C42.6428 37.2701 42.355 37.6059 41.9233 37.6059Z"
      fill="#4797D2"
    />
    <path
      d="M21.7291 31.9954H10.8885C10.5047 31.9954 10.1689 31.6597 10.1689 31.2759V25.7117C10.1689 25.328 10.5047 24.9922 10.8885 24.9922H21.7291C22.1128 24.9922 22.4486 25.328 22.4486 25.7117C22.4486 26.0954 22.1128 26.4312 21.7291 26.4312H11.608V30.6044H21.7291C22.1128 30.6044 22.4486 30.9402 22.4486 31.3239C22.4486 31.7076 22.1128 31.9954 21.7291 31.9954Z"
      fill="#4797D2"
    />
    <path
      d="M37.2698 37.6069H21.7283C21.3446 37.6069 21.0088 37.2711 21.0088 36.8874V21.9695C21.0088 21.5858 21.3446 21.25 21.7283 21.25H37.2698C37.6535 21.25 37.9893 21.5858 37.9893 21.9695V36.8874C37.9893 37.2711 37.6535 37.6069 37.2698 37.6069ZM22.4478 36.1679H36.5503V22.689H22.4478V36.1679Z"
      fill="#4797D2"
    />
    <path
      d="M29.4988 34.0092C29.1151 34.0092 28.7793 33.6735 28.7793 33.2897V29.6922C28.7793 29.3084 29.1151 28.9727 29.4988 28.9727C29.8825 28.9727 30.2183 29.3084 30.2183 29.6922V33.2897C30.2183 33.7214 29.9305 34.0092 29.4988 34.0092Z"
      fill="#4797D2"
    />
    <path
      d="M29.499 30.4588C27.9641 30.4588 26.6689 29.2116 26.6689 27.6766C26.6689 26.1417 27.9161 24.8945 29.499 24.8945C31.034 24.8945 32.3291 26.1417 32.3291 27.6766C32.3291 29.2116 31.034 30.4588 29.499 30.4588ZM29.499 26.2856C28.7315 26.2856 28.108 26.9092 28.108 27.6287C28.108 28.3482 28.7315 28.9718 29.499 28.9718C30.2665 28.9718 30.8901 28.3482 30.8901 27.6287C30.8901 26.9092 30.2665 26.2856 29.499 26.2856Z"
      fill="#4797D2"
    />
    <path
      d="M35.5908 22.6886C35.207 22.6886 34.8712 22.3528 34.8712 21.9691V16.9325C34.8712 13.9585 32.4729 11.5601 29.4989 11.5601C26.5249 11.5601 24.1265 13.9585 24.1265 16.9325V21.9691C24.1265 22.3528 23.7908 22.6886 23.407 22.6886C23.0233 22.6886 22.6875 22.3528 22.6875 21.9691V16.9325C22.6875 13.191 25.7574 10.1211 29.4989 10.1211C33.2403 10.1211 36.3103 13.191 36.3103 16.9325V21.9691C36.3103 22.3528 36.0225 22.6886 35.5908 22.6886Z"
      fill="#4797D2"
    />
  </svg>
);

const Register = () => {
  const client = useApolloClient();
  const classes = useStyles();

  const { onReplaceFormData, formData } =
    useContext(CalculatorFormContext) || {};
  useTrackPage('/register');

  const {
    data: { isAuthenticated },
  } = useQuery(IS_AUTHENTICATED);

  const [handleRegsiterAnonymousAccount, { loading }] = useMutation(
    CREATE_USER,
    {
      async onCompleted({ anonymousRegistration }) {
        if (!anonymousRegistration) return;

        onReplaceFormData({
          g_productivity: formData.g_productivity,
          g_incomeAndExpense: formData.g_incomeAndExpense,
          g_taxes: formData.g_taxes,
          g_personalSpending: formData.g_personalSpending,
          ...defaultFormValues,
        });

        navigate('/create-account');

        await client.writeQuery({
          query: GET_TOKEN,
          data: anonymousRegistration,
        });

        client.writeData({
          data: {
            isAuthenticated: true,
          },
        });
      },
    },
  );

  return (
    <Layout showIllustration>
      <img className={classes.fca} alt="Regulated by the FCA" src={fca} />

      <Card title="A note on your information and data" center icon={Icon}>
        <p>
          As a company, one of our values is transparency and we want to be as
          transparent as possible about what we do and don’t do with your data.{' '}
        </p>

        <p>
          We <strong>don’t</strong> sell or pass along any of your personal
          data. Nor do we give anyone any information about you that can be tied
          back to you.{' '}
        </p>

        <p>
          We <strong>do</strong> use other software services that might have
          access to your personal information - for instance, we use a software
          called Intercom to have online conversations with you when you have
          questions.
        </p>

        <p>
          We exist to try and bring positive change to gig working and the gig
          industry. In this regard, we use your data to create statistics and
          aggregated insights. We often share these insights with third parties
          like news publications, policy-makers, gig companies and other
          stakeholders in order to improve working conditions within the
          industry. We also use statistics within the tool to help you improve
          your financial health.{' '}
        </p>

        <p>
          It’s important to know that your data belongs to you. So, if you want
          us to forget about you, just let us know and we’ll do just that.{' '}
        </p>

        <p>
          Read more in our{' '}
          <a
            href="https://www.goclarity.co.uk/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </a>{' '}
          and{' '}
          <a
            href="https://www.goclarity.co.uk/terms-and-conditions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms and conditions
          </a>
          .
        </p>
      </Card>

      <p className={classes.disclaimer}>
        By continuing you agree to our{' '}
        <a
          href="https://www.goclarity.co.uk/terms-and-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>
        . <br />
        Clarity is subject to our{' '}
        <a
          href="https://www.goclarity.co.uk/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </p>
      <div>
        <Button
          loading={loading}
          marginBottom
          onClick={
            isAuthenticated
              ? () => navigate('/calculator')
              : handleRegsiterAnonymousAccount
          }
        >
          {loading ? <Loader /> : 'Continue'}
        </Button>
      </div>

      <Footer />
    </Layout>
  );
};

export default Register;
