import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import FieldWrapper from '../FieldWrapper';
import Button from '../Button';
import Loader from '../Loader';

import { isArrayWithLenth } from '../../utils';

const useStyles = makeStyles({
  form: {
    marginTop: 30,
  },
});

const Form = ({
  onSubmit,
  dataTransformer,
  initialValues,
  validationSchema,
  fields,
  buttonLabel,
  loading,
  error,
  ...props
}) => {
  const classes = useStyles();

  const formatAndSubmit = (values) => {
    if (!onSubmit) return;

    if (dataTransformer) {
      onSubmit(dataTransformer(values));
      return;
    }

    onSubmit({ variables: values });
  };

  return (
    <Formik
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={formatAndSubmit}
      validationSchema={validationSchema && validationSchema}
      {...props}
      render={({ handleSubmit, values, errors }) => {
        return (
          <form className={classes.form} onSubmit={handleSubmit}>
            {isArrayWithLenth(fields) &&
              fields.map((field) => (
                <FieldWrapper
                  key={field.id}
                  values={values}
                  errors={errors}
                  {...props}
                  {...field}
                />
              ))}

            <Button marginTop loading={loading} type="submit">
              {loading ? <Loader /> : buttonLabel}
            </Button>

            {error && <p>{error.message?.replace('GraphQL error: ', '')}</p>}
          </form>
        );
      }}
    />
  );
};

Form.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
  initialValues: PropTypes.shape({}),
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.shape({}),
  dataTransformer: PropTypes.func,
  buttonLabel: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

Form.defaultProps = {
  onSubmit: undefined,
  initialValues: {},
  validationSchema: null,
  dataTransformer: undefined,
  fields: null,
  buttonLabel: 'Submit',
  loading: false,
  error: false,
};

export default memo(Form);
