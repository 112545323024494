import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import TextLink from '../TextLink';

import { ReactComponent as ScoreGuage } from '../../assets/score-gauge.svg';
import { ReactComponent as Chevron } from '../../assets/chevron-large.svg';

import { calculateMarkerRotation } from '../../utils';
import Loader from '../Loader';

const useStyles = makeStyles((theme) => ({
  container: {
    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),
    textAlign: 'center',
  },
  wrapper: {
    position: 'relative',
    color: '#343858',
    margin: '0 auto',
    maxWidth: 340,
  },
  outer: {
    margin: '30px 0',
  },
  loading: {
    opacity: 0.3,
  },
  marker: {
    position: 'absolute',
    top: 0,
    bottom: 30,
    width: '11.6%',
    left: '50%',
    marginLeft: '-5.8%',
    transformOrigin: 'bottom center',

    '& > svg': {
      width: '40%',
      margin: '30%',
    },
  },
  markerCircle: {
    width: '100%',
    height: 'auto',
    padding: '20%',
    borderRadius: '100%',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)',
    display: 'flex',

    '&:after': {
      content: "''",
      display: 'block',
      paddingBottom: '100%',
      width: '100%',
      borderRadius: '100%',
    },
  },
  markerCircleSmallWrapper: {
    width: 20,
    marginRight: 10,
  },
  red: {
    '&:after': {
      backgroundColor: '#FF8FA7',
    },
  },
  yellow: {
    '&:after': {
      backgroundColor: '#FFD76D',
    },
  },
  green: {
    '&:after': {
      backgroundColor: '#65CE7F',
    },
  },
  blue: {
    '&:after': {
      backgroundColor: '#61D0FA',
    },
  },
  potential: {
    opacity: 0.4,
  },
  score: {
    fontSize: 26,
    position: 'absolute',
    left: 50,
    right: 50,
    bottom: 20,
    textAlign: 'center',
    fontWeight: 600,
  },

  legend: {
    fontSize: 10,
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'center',
    color: '#343858',
    padding: 0,
    margin: '0 0 15px',

    '& > li': {
      padding: 0,
      margin: '15px 10px 0',
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

const ScoreVisualisation = ({
  score,
  noMargin,
  potentialScore,
  activeTab,
  showWhatDoesThisMean,
  locked,
}) => {
  const classes = useStyles();

  const normalisedScore = Math.min(score, 10);
  const normalisedPotentialScore = Math.min(potentialScore, 10);

  const markerRotation = calculateMarkerRotation(normalisedScore);
  const markerRotation2 = calculateMarkerRotation(normalisedPotentialScore);

  const getScoreDescription = () => {
    if (!score) return false;

    if (normalisedScore < 4) {
      return 'Time to reform 💪';
    }

    if (normalisedScore < 6) {
      return 'Room for improvement ⬆️';
    }

    if (normalisedScore < 8) {
      return 'Feeling good 🙌';
    }

    return 'Feeling heroic ⚡';
  };

  const getMarkerColorClass = (value) => {
    if (!score) return false;

    if (value < 4) {
      return classes.red;
    }

    if (value < 6) {
      return classes.yellow;
    }

    if (value < 8) {
      return classes.green;
    }

    return classes.blue;
  };

  if (locked) {
    return (
      <div
        className={clsx(classes.container, {
          [classes.outer]: !noMargin,
          [classes.loading]: !score,
        })}
      >
        <div className={classes.wrapper}>
          <ScoreGuage />
          <svg
            width="25"
            height="28"
            viewBox="0 0 16 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM8 16C6.9 16 6 15.1 6 14C6 12.9 6.9 12 8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16ZM11.1 7H4.9V5C4.9 3.29 6.29 1.9 8 1.9C9.71 1.9 11.1 3.29 11.1 5V7Z"
              fill="#343858"
              fillOpacity="0.2"
            />
          </svg>
        </div>
      </div>
    );
  }

  if (!score) {
    return (
      <div
        className={clsx(classes.container, {
          [classes.outer]: !noMargin,
          [classes.loading]: !score,
        })}
      >
        <div className={classes.wrapper}>
          <ScoreGuage />
          <div style={{ position: 'relative', paddingBottom: '30px' }}>
            <Loader />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(classes.container, {
        [classes.outer]: !noMargin,
        [classes.loading]: !score,
      })}
    >
      <div className={classes.wrapper}>
        <ScoreGuage />

        {!!score && (
          <div
            className={clsx(classes.marker)}
            style={{ transform: `rotate(${markerRotation}deg)` }}
          >
            <div
              className={clsx(
                classes.markerCircle,
                getMarkerColorClass(normalisedScore),
              )}
            />
            <Chevron />
          </div>
        )}

        {!!score && (
          <div
            className={clsx(classes.marker, classes.potential)}
            style={{ transform: `rotate(${markerRotation2}deg)` }}
          >
            <div
              className={clsx(
                classes.markerCircle,
                getMarkerColorClass(normalisedPotentialScore),
              )}
            />
          </div>
        )}

        <div className={classes.score}>{getScoreDescription()}</div>
      </div>

      <ul className={classes.legend}>
        <li>
          <div className={classes.markerCircleSmallWrapper}>
            <div
              className={clsx(
                classes.markerCircle,
                getMarkerColorClass(normalisedScore),
              )}
            />
          </div>
          Your score
        </li>
        <li>
          <div className={classes.markerCircleSmallWrapper}>
            <div
              className={clsx(
                classes.markerCircle,
                classes.potential,
                getMarkerColorClass(normalisedPotentialScore),
              )}
            />
          </div>
          Potential score
        </li>
      </ul>

      {showWhatDoesThisMean && (
        <TextLink to={`/results/what-does-this-mean/${activeTab}`}>
          What does this mean?
        </TextLink>
      )}
    </div>
  );
};

ScoreVisualisation.propTypes = {
  score: PropTypes.number.isRequired,
  potentialScore: PropTypes.number.isRequired,
  noMargin: PropTypes.bool,
  activeTab: PropTypes.string,
  showWhatDoesThisMean: PropTypes.bool,
  locked: PropTypes.bool,
  onUnlockClick: PropTypes.func.isRequired,
};

ScoreVisualisation.defaultProps = {
  noMargin: false,
  activeTab: null,
  showWhatDoesThisMean: false,
  locked: false,
};

export default ScoreVisualisation;
