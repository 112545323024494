import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '##343858',
    margin: '15px 0 15px 0',
  },
  smallText: {
    fontSize: 10,
    color: '##343858',
    marginTop: -10,
    marginBottom: 15,
  },
  largeMarginTop: {
    marginTop: 30,
  },
  h2: {
    fontSize: 24,
    textAlign: 'center',
  },
}));

const Title = ({ variant, children, smallText, largeMarginTop, ...props }) => {
  const classes = useStyles();

  return (
    <>
      <h3
        className={clsx(classes.title, {
          [classes.largeMarginTop]: largeMarginTop,
          [classes.h2]: variant === 'h2',
        })}
        {...props}
      >
        {children}
      </h3>
      {!!smallText && <p className={classes.smallText}>{smallText}</p>}
    </>
  );
};

Title.propTypes = {
  variant: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  smallText: PropTypes.string,
  largeMarginTop: PropTypes.bool,
};

Title.defaultProps = {
  smallText: null,
  largeMarginTop: false,
};

export default Title;
