import React from 'react';
import PropTypes from 'prop-types';

import Field from '../Field';

const FieldWrapper = ({ id, type, ...field }) => {
  return <Field id={id} type={type} {...field} />;
};

FieldWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default FieldWrapper;
