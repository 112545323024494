import React, { useState, useCallback, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';

import Layout from '../../components/Layout';
import Scrollable from '../../components/Scrollable';
import PieChart from '../../components/PieChart';
import LineChart from '../../components/LineChart';
import Title from '../../components/Title';
import SelectField from '../../components/SelectFieldAlt';
import ResultsHeader from '../../components/ResultsHeader';
import SwitchField from '../../components/SwitchField';
import ResultCard from '../../components/ResultCard';
import Button from '../../components/Button';
import ResultFeedback from '../../components/ResultFeedback';

import { GET_INCOME_BREAKDOWN } from '../../graph/clarity';

import useTrackPage from '../../hooks/useTrackPage';

import getDateRange from '../../utils/getDateRange';
import getDateRangeText from '../../utils/getDateRangeText';

const IncomeBreakdown = () => {
  useTrackPage('/results/my-income');
  const [jobType, setJobType] = useState(null);
  const [client, setClient] = useState(null);
  const [netIncome, setNetIncome] = useState(false);

  const { data, loading, refetch } = useQuery(GET_INCOME_BREAKDOWN, {
    variables: {
      ...getDateRange(),
      incomeType: netIncome ? 'netIncome' : 'turnover',
    },
  });

  const handleRefetch = useCallback(() => {
    refetch({
      ...getDateRange(),
      incomeType: netIncome ? 'netIncome' : 'turnover',
    });
  }, [netIncome, refetch]);

  useEffect(() => {
    handleRefetch();
  }, [netIncome]); // eslint-disable-line

  const selectedClient =
    client && data?.incomeBreakdown?.clients.find(({ id }) => id === client);

  const selectedJobType =
    jobType && data?.incomeBreakdown?.jobTypes.find(({ id }) => id === jobType);

  const renderContent = () => {
    if (loading) {
      return false;
    }

    return (
      <>
        <SwitchField
          offLabel="Turnover"
          onLabel="Net income"
          value={netIncome}
          onChange={(e) => setNetIncome(e.target.checked)}
          name="netIncome"
        />
        <ResultCard
          value={data?.incomeBreakdown?.totalIncome}
          text={`Estimated turnover for the last ${getDateRangeText()}.`}
        >
          <Button textLink to="/coming-soon">
            How is this calculated?
          </Button>
          <Button small center to="/calculator/17">
            Tag more transactions
          </Button>
        </ResultCard>
        <PieChart
          title="Clients"
          data={data?.incomeBreakdown?.clients}
          total={data?.incomeBreakdown?.totalIncome}
        />
        <Title variant="h3">Your income</Title>
        <LineChart data={data?.incomeBreakdown?.income} labelKey="label" />

        {data?.incomeBreakdown?.jobTypes?.length > 1 && (
          <>
            <Title variant="h3">Income by job type</Title>
            <PieChart
              title="Job Type"
              data={data?.incomeBreakdown?.jobTypes}
              total={data?.incomeBreakdown?.totalIncome}
            />

            <SelectField
              options={data?.incomeBreakdown?.jobTypes}
              name="jobType"
              values={{ jobType }}
              onChange={(e) => setJobType(e.target.value)}
              alt
              selectText="Choose a job type..."
            />

            <LineChart
              data={selectedJobType?.income}
              labelKey="label"
              noDataMessage="Choose a job type to view income breakdown"
            />
          </>
        )}

        {data?.incomeBreakdown?.clients?.length > 1 && (
          <>
            <Title variant="h3">Income by client</Title>
            <PieChart
              title="Clients"
              data={data?.incomeBreakdown?.clients}
              total={data?.incomeBreakdown?.totalIncome}
            />

            <SelectField
              options={data?.incomeBreakdown?.clients}
              name="client"
              values={{ client }}
              onChange={(e) => setClient(e.target.value)}
              alt
              selectText="Choose a client..."
            />

            <LineChart
              labelKey="label"
              data={selectedClient?.income}
              noDataMessage="Choose a client to view income breakdown"
            />
          </>
        )}
        <ResultFeedback section="my-income" />
      </>
    );
  };

  return (
    <Layout fetching={loading} animateIn>
      <Scrollable>
        <ResultsHeader title="My Income" refetchFunction={handleRefetch} />
        {renderContent()}
      </Scrollable>
    </Layout>
  );
};

export default IncomeBreakdown;
