import React from 'react';
import { navigate } from '@reach/router';

import Layout from '../../components/Layout';
import FooterBar from '../../components/FooterBar';
import Footer from '../../components/Footer';
import Button from '../../components/Button';
import SignUpForm from '../../components/SignUpForm';

import useTrackPage from '../../hooks/useTrackPage';

const CreateAccount = () => {
  useTrackPage('/create-account');

  const handleSuccess = () => {
    navigate('/calculator');
  };

  return (
    <Layout showIllustration showFCALogo>
      <SignUpForm
        title="Create an account to save your data"
        text="Set up a login so you can exit the app and come back later without losing your progress."
        submitButtonText="Continue"
        onSuccess={handleSuccess}
      />

      <div>
        <Button secondary marginTop to="/calculator">
          Skip
        </Button>
      </div>

      <FooterBar noPadding>
        <Footer />
      </FooterBar>
    </Layout>
  );
};

export default CreateAccount;
