import React from 'react';

import JobCard from '../JobCard';
import Button from '../Button';
import IntroText from '../IntroText';
import FooterBar from '../FooterBar';

import useCalculator from '../../hooks/useCalculator';
import useCompleteCheck from '../../hooks/useCompleteCheck';

const JobsList = (props) => {
  const { jobs, formData, onRemoveJob, removingJob, goToNextStep } =
    useCalculator(props);

  const { isJobsComplete, jobsWithParsedMeta } = useCompleteCheck({
    jobs,
    formData,
  });

  return (
    <>
      <IntroText title={<>Tell us a bit more about each job</>}>
        Click on each job to tell us about them in a bit more detail. Each one
        will take about <strong>2 minutes</strong>.
      </IntroText>

      {jobsWithParsedMeta?.map((job) => (
        <JobCard
          key={job.id}
          onRemoveJob={onRemoveJob}
          removingJob={removingJob}
          hideAction={!formData.g_productivity}
          {...job}
        />
      ))}

      <FooterBar>
        <Button secondary marginTop to="/calculator/jobs/2">
          + Add industry
        </Button>

        <Button
          marginBottom
          marginTop
          disabled={!isJobsComplete}
          to={!formData.g_productivity ? '/calculator' : undefined}
          onClick={formData.g_productivity ? goToNextStep : undefined}
        >
          Continue
        </Button>
      </FooterBar>
    </>
  );
};

export default JobsList;
