import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import ButtonStyle from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    background: '#4797D2',
    borderRadius: 21,
    color: `${theme.palette.common.white} !important`,
    height: 60,
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '20px',
    textTransform: 'none',

    '&:hover, &:active, &:focus': {
      background: '#326A93',
    },
  },

  green: {
    background: '#53A467',

    '&:hover': {
      background: '#53A467',
    },
  },

  secondary: {
    background: 'transparent',
    border: 'solid 3px',
    borderColor: '#4797D2',
    color: '#343858  !important',

    '&:hover': {
      background: 'transparent',
      borderColor: '#326A93',
    },
  },

  loading: {
    background: '#326A93',
    pointerEvents: 'none',
  },

  small: {
    width: 'auto',
    height: '30px',
    fontSize: 14,
    lineHeight: '18px',
    margin: 0,
    borderRadius: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderWidth: 2,
  },

  midSmall: {
    height: '40px',
    fontSize: 16,
    margin: 0,
    borderRadius: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderWidth: 2,
  },
  medium: {
    height: '56px',
    fontSize: 20,
    margin: 0,
    borderWidth: '3px',
    maxWidth: '115px',
    borderRadius: '20px',
  },

  alt: {
    color: '#343858  !important',
    background: '#FFFFFF',
    boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.05)',
    borderRadius: '15px',
    fontSize: '16px',
    textAlign: 'left',
    margin: '8px 0',
    justifyContent: 'flex-start',
    padding: '20px 50px 20px 20px',
    minHeight: '64px',
    height: 'auto',
    border: '1px solid #E5E5E5',

    '&:hover': {
      borderColor: '#4797D2',
      background: '#FFFFFF',
    },

    '&:after': {
      content: "''",
      display: 'block',
      position: 'absolute',
      right: '20px',
      top: '50%',
      backgroundColor: '#EBF5FA',
      width: '22px',
      height: '22px',
      borderRadius: '100%',
      transform: 'translateY(-50%)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 7 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.55071 4.49362L2.5262 1.35523C2.26048 1.07951 1.82282 1.07951 1.55711 1.35523C1.29139 1.63096 1.29139 2.08509 1.55711 2.36081L4.08925 4.99641L1.54929 7.63201C1.28357 7.90773 1.28357 8.36186 1.54929 8.63759C1.68215 8.77545 1.8619 8.84843 2.03384 8.84843C2.21359 8.84843 2.38553 8.77545 2.51838 8.63759L5.55071 5.4992C5.81643 5.22348 5.81643 4.76934 5.55071 4.49362Z' fill='%234797D2' stroke='%234797D2'/%3E%3C/svg%3E%0A")`,
    },
  },
  altBlue: {
    backgroundColor: '#4797D2',
    borderColor: '#4797D2',
    color: '#FFFFFF!important',

    '&:hover, &:active, &:focus': {
      background: '#326A93',
    },

    '& span span span': {
      fontSize: '12px',
      fontWeight: '400',
      display: 'block',
      lineHeight: '1',
      marginTop: '4px',
    },

    '&:after': {
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='22' width='22' height='22' rx='11' transform='rotate(90 22 0)' fill='white'/%3E%3Cpath d='M12.8188 10.5411L10.0693 7.68799C9.82771 7.43734 9.42984 7.43734 9.18828 7.68799C8.94671 7.93865 8.94671 8.3515 9.18828 8.60216L11.4902 10.9982L9.18117 13.3942C8.93961 13.6448 8.93961 14.0577 9.18117 14.3083C9.30195 14.4336 9.46536 14.5 9.62167 14.5C9.78508 14.5 9.94138 14.4336 10.0622 14.3083L12.8188 11.4552C13.0604 11.2046 13.0604 10.7917 12.8188 10.5411Z' fill='%23429CDB' stroke='%234797D2'/%3E%3C/svg%3E%0A")`,
    },
  },
  disabled: {
    opacity: 0.2,

    '&.Mui-disabled': {
      background: '#4797D2!important',
    },
  },
  textOnly: {
    background: 'none',
    padding: 0,
    width: 'auto',
    margin: 0,
    fontSize: '16px',
    height: '24px',
    color: '#343858  !important',

    '&:hover': {
      background: '#F9F9FA',
    },
  },
  noBorder: {
    borderColor: 'transparent',
  },
  textLink: {
    border: 'none',
    background: 'none',
    padding: 0,
    color: '#4797D2!important',
    fontSize: 12,
    fontWeight: 400,
    height: 'auto',
    margin: '15px 0',
    '&:hover': {
      background: 'transparent',
    },
  },
  center: {
    maxWidth: '190px',
    margin: '0 auto',
  },

  arrowDown: {
    '&:after': {
      transform: 'translateY(-50%) rotate(90deg)',
    },
  },
  arrowUp: {
    '&:after': {
      transform: 'translateY(-50%) rotate(-90deg)',
    },
  },
  altActive: {
    color: '#ffffff!important',
    background: '#4797D2',

    '&:hover': {
      background: '#4797D2',
    },
  },
  icon: {
    '& svg': {
      width: 16,
      height: 16,
      marginLeft: 4,
    },
  },
  marginBottom: {
    marginBottom: 15,
  },
  marginTop: {
    marginTop: 15,
  },
  white: {
    backgroundColor: '#ffffff',
    color: '#4797D2 !important',

    '&:hover': {
      backgroundColor: '#ffffff',
      color: '#4797D2',
    },
  },
  whiteSecondary: {
    borderColor: '#ffffff',
    color: '#ffffff !important',

    '&:hover': {
      borderColor: '#ffffff',
      color: '#ffffff !important',
    },
  },
  inlineBlock: {
    display: 'inline-block',
  },
  autoHeight: {
    height: 'auto',
  },
}));

const Button = ({
  children,
  to,
  label,
  marginBottom,
  marginTop,
  secondary,
  small,
  medium,
  center,
  alt,
  altBlue,
  disabled,
  textOnly,
  noBorder,
  textLink,
  arrowDown,
  arrowUp,
  altActive,
  icon,
  green,
  white,
  whiteSecondary,
  midSmall,
  inlineBlock,
  autoHeight,
  ...props
}) => {
  const classes = useStyles();

  return (
    <ButtonStyle
      className={clsx(classes.button, {
        [classes.marginBottom]: marginBottom,
        [classes.marginTop]: marginTop,
        [classes.secondary]: secondary,
        [classes.small]: small,
        [classes.medium]: medium,
        [classes.center]: center,
        [classes.alt]: alt,
        [classes.altBlue]: altBlue,
        [classes.disabled]: disabled,
        [classes.textOnly]: textOnly,
        [classes.noBorder]: noBorder,
        [classes.textLink]: textLink,
        [classes.arrowDown]: arrowDown,
        [classes.arrowUp]: arrowUp,
        [classes.altActive]: altActive,
        [classes.icon]: icon,
        [classes.green]: green,
        [classes.white]: white,
        [classes.whiteSecondary]: whiteSecondary,
        [classes.midSmall]: midSmall,
        [classes.inlineBlock]: inlineBlock,
        [classes.autoHeight]: autoHeight,
      })}
      component={to ? Link : 'button'}
      to={to}
      disabled={disabled}
      {...props}
    >
      {children || label}
    </ButtonStyle>
  );
};

Button.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  label: PropTypes.string,
  marginBottom: PropTypes.bool,
  marginTop: PropTypes.bool,
  secondary: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  center: PropTypes.bool,
  alt: PropTypes.bool,
  disabled: PropTypes.bool,
  textOnly: PropTypes.bool,
  noBorder: PropTypes.bool,
  textLink: PropTypes.bool,
  arrowDown: PropTypes.bool,
  arrowUp: PropTypes.bool,
  altActive: PropTypes.bool,
  icon: PropTypes.bool,
  green: PropTypes.bool,
  altBlue: PropTypes.bool,
  white: PropTypes.bool,
  whiteSecondary: PropTypes.bool,
  midSmall: PropTypes.bool,
  inlineBlock: PropTypes.bool,
  autoHeight: PropTypes.bool,
};

Button.defaultProps = {
  children: undefined,
  to: undefined,
  label: undefined,
  marginBottom: false,
  marginTop: false,
  secondary: false,
  small: false,
  medium: false,
  center: false,
  alt: false,
  disabled: false,
  textOnly: false,
  noBorder: false,
  textLink: false,
  arrowDown: false,
  arrowUp: false,
  altActive: false,
  icon: false,
  green: false,
  altBlue: false,
  white: false,
  whiteSecondary: false,
  midSmall: false,
  inlineBlock: false,
  autoHeight: false,
};

export default Button;
