import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

import TagSwitcher from '../TagSwitcher';

const useStyles = makeStyles({
  rule: {
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    boxSizing: 'border-box',
    borderRadius: '10px',
    padding: '10px 20px',
    marginBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& h3': {
      margin: 0,
      fontWeight: '600',
      fontSize: '16px',
    },

    '& p': {
      margin: 0,
      fontSize: '10px',
    },
  },
  tagged: {
    color: '#ffffff',
    background: '#4797D2',
    border: 'none',
    padding: '12px 20px',
  },
  personal: {
    background:
      'linear-gradient(87.02deg, #3A236F 0.41%, #4E2F97 97.76%), #FFFFFF',
  },
  text: {
    flex: '0 1 50%',
  },
});

const SuggestedRules = ({
  matchingTransactionCount,
  ruleType,
  transaction,
  description,
  rules,
  handleAddNewRule,
}) => {
  const classes = useStyles();

  const matchedRule = rules?.find(
    (rule) => rule.match === description && rule.ruleType === ruleType,
  );

  const isTagged = !!matchedRule;
  const isPersonal = matchedRule?.jobName === 'Personal';

  return (
    <li
      className={clsx(classes.rule, {
        [classes.tagged]: isTagged,
        [classes.personal]: isPersonal,
      })}
    >
      <div className={classes.text}>
        <h3>{description}</h3>
        <p>{matchingTransactionCount} transactions</p>
      </div>

      <TagSwitcher
        value={matchedRule?.jobId}
        jobType={ruleType}
        transactionId={transaction.id}
        active={isTagged}
        handleAddNewRule={handleAddNewRule}
        match={description}
      />
    </li>
  );
};

SuggestedRules.propTypes = {
  matchingTransactionCount: PropTypes.number.isRequired,
  ruleType: PropTypes.string.isRequired,
  transaction: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  description: PropTypes.string.isRequired,
  rules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleAddNewRule: PropTypes.func.isRequired,
};

SuggestedRules.defaultProps = {};

export default SuggestedRules;
