import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  card: {
    background: '#E3F3EB',
    borderRadius: '5px',
    padding: '15px',
    border: '1px solid #53A467',
    marginTop: '30px',
  },
  title: {
    fontSize: '14px',
    fontWeight: '700',
    margin: '0 0 5px',
    lineHeight: '20px',
  },
  text: {
    fontSize: '14px',
    fontWeight: '400',
    margin: '0',
    lineHeight: '20px',
  },
  marginBottom: {
    margin: '0 0 20px 0',
  },
  yellow: {
    borderColor: '#EFC45F',
    backgroundColor: '#FCF3DF',
  },
  red: {
    borderColor: '#D6645B',
    backgroundColor: '#F7E0DE',
  },
}));

const HelpCard = ({ title, text, red, yellow, marginBottom }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.card, {
        [classes.red]: red,
        [classes.yellow]: yellow,
        [classes.marginBottom]: marginBottom,
      })}
    >
      {!!title && <h4 className={classes.title}>{title}</h4>}
      {!!text && <p className={classes.text}>{text}</p>}
    </div>
  );
};

HelpCard.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string.isRequired,
  red: PropTypes.bool,
  yellow: PropTypes.bool,
  marginBottom: PropTypes.bool,
};

HelpCard.defaultProps = {
  title: null,
  red: false,
  yellow: false,
  marginBottom: false,
};

export default HelpCard;
