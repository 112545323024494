import React from 'react';
import PropTypes from 'prop-types';

import RadioField from '../RadioField';
import LargeNumberField from '../LargeNumberField';
import SmallNumberField from '../SmallNumberField';
import SelectField from '../SelectField';
import SimpleTextField from '../SimpleTextField';
import MultiRadioField from '../MultiRadioField';
import GroupedCheckboxField from '../GroupedCheckboxField';
import MultiCheckboxField from '../MultiCheckboxField';
import SingleCheckboxField from '../SingleCheckboxField';
import RatingField from '../RatingField';
import IncrementNumberField from '../IncrementNumberField';

const CalculatorField = ({ type, ...props }) => {
  if (type === 'radio') {
    return <RadioField type={type} {...props} />;
  }

  if (type === 'multiRadio') {
    return <MultiRadioField type={type} {...props} />;
  }

  if (type === 'largeNumber') {
    return <LargeNumberField {...props} />;
  }

  if (type === 'smallNumber') {
    return <SmallNumberField {...props} />;
  }

  if (type === 'incrementNumber') {
    return <IncrementNumberField {...props} />;
  }

  if (type === 'select') {
    return <SelectField {...props} />;
  }

  if (type === 'text') {
    return <SimpleTextField {...props} />;
  }

  if (type === 'number') {
    return <SimpleTextField type="number" {...props} />;
  }

  if (type === 'checkbox') {
    return <SingleCheckboxField {...props} />;
  }

  if (type === 'groupedCheckboxes') {
    return <GroupedCheckboxField {...props} />;
  }

  if (type === 'multiCheckboxes') {
    return <MultiCheckboxField {...props} />;
  }

  if (type === 'rating') {
    return <RatingField {...props} />;
  }

  return null;
};

CalculatorField.propTypes = {
  type: PropTypes.string.isRequired,
};

export default CalculatorField;
