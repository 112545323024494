import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';
import Card from '../Card';

import { isArrayWithLenth } from '../../utils';

const useStyles = makeStyles((theme) => ({
  noDataMessage: {
    position: 'absolute',
    left: '30px',
    right: '30px',
    top: '50%',
    transform: 'translate(0, -100%)',
    textAlign: 'center',
    color: theme.palette.grey[500],
  },
  list: {
    padding: 0,
    margin: 0,
    borderRadius: 10,
    overflow: 'hidden',

    '& li': {
      display: 'flex',
      margin: 0,
      padding: '10px',
      fontSize: 12,
      fontWeight: 500,
      alignItems: 'center',
      borderBottom: 'solid 1px #E5E5E5',

      '&:last-child': {
        borderBottom: 'none',
      },
    },

    '& span': {
      marginLeft: 'auto',
      width: 10,
      height: 10,
      borderRadius: '100%',
      display: 'block',
      flex: '0 0 10px',
    },
  },
  green: {
    backgroundColor: 'rgba(109, 215, 131, 0.15)',
    '& span': {
      backgroundColor: 'rgba(109, 215, 131, 1)',
    },
  },
  yellow: {
    backgroundColor: 'rgba(248, 202, 18, 0.15)',
    '& span': {
      backgroundColor: 'rgba(248, 202, 18, 1)',
    },
  },
  red: {
    backgroundColor: 'rgba(231, 91, 86, 0.1)',
    '& span': {
      backgroundColor: 'rgba(231, 91, 86, 1)',
    },
  },

  key: {
    padding: 0,
    margin: '20px 0 0 0',
    display: 'flex',
    justifyContent: 'center',

    '& li': {
      display: 'flex',
      margin: 0,
      padding: '0px 15px',
      fontSize: 12,
      fontWeight: 500,
      alignItems: 'center',
    },

    '& span': {
      width: 10,
      height: 10,
      borderRadius: '100%',
      marginRight: 10,
      display: 'block',
      flex: '0 0 10px',
    },
  },

  greenDot: {
    backgroundColor: 'rgba(109, 215, 131, 1)',
  },
  yellowDot: {
    backgroundColor: 'rgba(248, 202, 18, 1)',
  },
  redDot: {
    backgroundColor: 'rgba(231, 91, 86, 1)',
  },
}));

const HighRiskTransactionsList = ({ data, noDataMessage }) => {
  const classes = useStyles();

  if (!isArrayWithLenth(data)) {
    return (
      <div className={classes.container}>
        <p className={classes.noDataMessage}>{noDataMessage}</p>
      </div>
    );
  }

  return (
    <Card smallMargin>
      <ol className={classes.list}>
        {data.map((entry) => {
          return (
            <li
              className={clsx({
                [classes.green]: entry.risk === 1,
                [classes.yellow]: entry.risk === 2,
                [classes.red]: entry.risk === 3,
              })}
            >
              {entry.id}
              <span />
            </li>
          );
        })}
      </ol>

      <ol className={classes.key}>
        <li>
          <span className={classes.greenDot} /> Low risk
        </li>
        <li>
          <span className={classes.yellowDot} /> Medium risk
        </li>
        <li>
          <span className={classes.redDot} /> High risk
        </li>
      </ol>
    </Card>
  );
};

HighRiskTransactionsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  noDataMessage: PropTypes.string,
};

HighRiskTransactionsList.defaultProps = {
  noDataMessage: 'No data',
};

export default HighRiskTransactionsList;
