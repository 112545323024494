import React, { useState } from 'react';
import { navigate } from '@reach/router';
import PropTypes from 'prop-types';

import Intro from '../Intro';

const Stepper = ({ stepContent, exitUrl }) => {
  const [currentStep, setCurrentStep] = useState(1);

  const handlePrevClick = () => {
    if (currentStep === 1) {
      navigate(exitUrl);
      return;
    }

    setCurrentStep(currentStep - 1);
  };

  const handleNextClick = () => {
    if (currentStep === stepContent.length) {
      navigate(exitUrl);
      return;
    }

    setCurrentStep(currentStep + 1);
  };

  return (
    <Intro
      onPrevClick={handlePrevClick}
      onNextClick={handleNextClick}
      totalSteps={stepContent.length}
      currentStep={currentStep}
      {...stepContent[currentStep - 1]}
    />
  );
};

Stepper.propTypes = {
  stepContent: PropTypes.arrayOf({}).isRequired,
  exitUrl: PropTypes.string,
};

Stepper.defaultProps = {
  exitUrl: '/',
};

export default Stepper;
