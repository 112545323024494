import React from 'react';

import Calculator from '../Calculator';

import getCalculatorSteps from '../../config/calculatorSteps';

const ConnectReturn = () => {
  const steps = getCalculatorSteps('', 'income');
  const stepIndex = steps.findIndex((step) => step.id === 'ConnectSuccess');

  return <Calculator step={stepIndex + 1} group="income" />;
};

export default ConnectReturn;
