import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { globalHistory, Router } from '@reach/router';

import Register from './views/Register';
import Login from './views/Login';
import Save from './views/Save';
import Calculator from './views/Calculator';
import Results from './views/Results';
import ResultsAbout from './views/ResultsAbout';
import IncomeBreakdown from './views/IncomeBreakdown';
import Expenses from './views/Expenses';
import JobOptimisation from './views/JobOptimisation';
import Taxes from './views/Taxes';
import ConnectReturn from './views/ConnectReturn';
import ComingSoon from './views/ComingSoon';
import CookiePolicy from './views/CookiePolicy';
import SpendingHabits from './views/SpendingHabits';
import Subscriptions from './views/Subscriptions';
import WellnessHub from './views/WellnessHub';
import HighRiskTransactions from './views/HighRiskTransactions';
import ImproveMyScore from './views/ImproveMyScore';
import Welcome from './views/Welcome';
import HowDoesItWork from './views/HowDoesItWork';
import CreateAccount from './views/CreateAccount';
import SetUpLogin from './views/SetUpLogin';
import FinmoConnect from './views/FinmoConnect';
import OpenBanking from './views/OpenBanking';
import Help from './views/Help';
import Coaching from './views/Coaching';

import Layout from './components/Layout';

import { IS_AUTHENTICATED, GET_META } from './graph/auth';
import WhatDoYouWantToDoToday from './views/WhatDoYouWantToDoToday';

const App = () => {
  const {
    data: { isAuthenticated },
  } = useQuery(IS_AUTHENTICATED);

  // Fetched commonly used data
  const { loading } = useQuery(GET_META, {
    skip: !isAuthenticated,
  });

  // Hook into router change event
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        window.scrollTo(0, 0);
      }
    });
  }, []); // eslint-disable-line

  if (loading) {
    return <Layout fetching />;
  }

  if (!isAuthenticated) {
    return (
      <>
        <Router>
          <Login path="/login" />
          <CookiePolicy path="/cookie-policy" />
          <WhatDoYouWantToDoToday path="/what-do-you-want-to-do-today" />
          <Register path="/register" />
          <HowDoesItWork path="/how-does-it-work" />
          <Welcome path="/partner/:partner" />
          <Welcome default path="/" />
        </Router>
      </>
    );
  }

  return (
    <>
      <Router>
        <WhatDoYouWantToDoToday path="/what-do-you-want-to-do-today" />
        <Calculator path="/calculator/:group/:jobId/:step" />
        <Calculator path="/calculator/:group/:step" />
        <Calculator path="/calculator/:group" />
        <Calculator path="/calculator" />
        <ConnectReturn path="/connect/return" />
        <Results path="/results" />
        <ResultsAbout path="/results/what-does-this-mean/:type" />
        <IncomeBreakdown path="/results/income" />
        <Expenses path="/results/expenses" />
        <JobOptimisation path="/results/my-time" />
        <Taxes path="/results/taxes" />
        <SpendingHabits path="/results/spending-habits" />
        <Subscriptions path="/results/subscriptions" />
        <HighRiskTransactions path="/results/high-risk-transactions" />
        <ImproveMyScore path="/results/improve-my-score" />
        <WellnessHub path="/results/improve-my-wellness" />
        <Save path="/save-and-exit" />
        <CreateAccount path="/create-account" />
        <SetUpLogin path="/set-up-login" />
        <ComingSoon path="/coming-soon" />
        <Coaching path="/coaching" />
        <Help path="/help" />
        <Login path="/login" />
        <CookiePolicy path="/cookie-policy" />
        <Register path="/register" />
        <HowDoesItWork path="/how-does-it-work" />
        <OpenBanking path="/open-banking" />
        <FinmoConnect path="/finmo-connect" />
        <Welcome path="/partner/:partner" />
        <Welcome default path="/" />
      </Router>
    </>
  );
};

export default App;
