import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';

import Label from '../Label';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30,
    alignItems: 'center',
  },
  labelWrapper: {
    flex: '1 0 auto',
    marginRight: 'auto',
  },
  input: {
    flex: '0 0 40px',
    width: '50px',
    height: '35px',
    margin: '0 10px',

    '& input': {
      appearance: 'textfield',
      textAlign: 'center',
      fontSize: 18,
      fontWeight: 300,
      width: '50px',
      height: '35px',
      paddingLeft: 5,
      paddingRight: 5,

      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0,
      },
    },
  },
  button: {
    width: '18px',
    height: '18px',
    borderRadius: '100%',
    display: 'flex',
    border: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    background: '#4797D2',
    padding: 0,

    '& span': {
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: '1px',
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: '1px',
    },

    '& svg': {
      width: '8px',
      height: '8px',
      display: 'block',
      fill: '#4797D2',
    },

    '&:focus': {
      outline: 'none',

      '& svg': {
        fill: theme.palette.secondary.dark,
      },
    },

    '&:disabled': {
      '& svg': {
        fill: theme.palette.grey[200],
      },
    },
  },

  decrement: {
    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
}));

const IncrementNumberField = ({
  name,
  id,
  label,
  onChange,
  values,
  maxValue,
  minValue,
}) => {
  const classes = useStyles();

  const value = values[name];

  const handleBlur = () => {
    if (!value) {
      onChange({ target: { name, value: minValue } });
    }
  };

  const handleSetValue = (newValue) => {
    if (newValue && newValue <= minValue) {
      onChange({ target: { name, value: minValue } });
      return;
    }

    if (newValue && newValue >= maxValue) {
      onChange({ target: { name, value: maxValue } });
      return;
    }

    if (Number.isNaN(newValue)) {
      return;
    }

    onChange({ target: { name, value: newValue } });
  };

  const handleOnChange = (e) => {
    handleSetValue(e.target.value ? parseInt(e.target.value, 10) : '');
  };

  const handleIncrement = () => {
    handleSetValue(value ? value + 1 : 1);
  };

  const handleDecrement = () => {
    handleSetValue(value ? value - 1 : 0);
  };

  return (
    <FormControl fullWidth className={classes.container}>
      <div className={classes.labelWrapper}>
        {/* eslint-disable-next-line */}
        <Label grey id={id} label={label} />
      </div>

      <button
        type="button"
        className={clsx(classes.button, classes.decrement)}
        onClick={handleDecrement}
        disabled={value <= minValue}
      >
        <svg
          width="12"
          height="2"
          viewBox="0 0 12 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="12" height="2" fill="white" />
        </svg>

        <span>Decrease by one</span>
      </button>

      <OutlinedInput
        classes={{
          root: classes.input,
        }}
        id={id}
        name={name}
        type="text"
        value={value === undefined ? 0 : value}
        onChange={handleOnChange}
        onBlur={handleBlur}
        inputmode="numeric"
      />
      <button
        type="button"
        className={clsx(classes.button, classes.increment)}
        onClick={handleIncrement}
        disabled={value >= maxValue}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect y="5" width="12" height="2" fill="white" />
          <rect
            x="7"
            width="12"
            height="2"
            transform="rotate(90 7 0)"
            fill="white"
          />
        </svg>

        <span>Increase by one</span>
      </button>
    </FormControl>
  );
};

IncrementNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  values: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  maxValue: PropTypes.func.isRequired,
  minValue: PropTypes.func.isRequired,
};

export default IncrementNumberField;
