import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';

import { makeStyles } from '@material-ui/core/styles';

import Card from '../Card';

import { formatCurrency, isArrayWithLenth } from '../../utils';

import { baseColors } from '../../theme/base';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    minHeight: 80,
    position: 'relative',

    '& path': {
      stroke: 'none',
    },

    '& h6': {
      fontSize: '10px',
      fontWeight: 500,
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
      color: '#343858',
      margin: '15px 0 0 0',
    },
  },
  col: {
    flex: '0 0 50%',
    width: '50%',
    minHeight: 86,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
  },
  legend: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    fontSize: '10px',
    lineHeight: '10px',

    '& li': {
      display: 'flex',
      alignItems: 'center',
      margin: '15px 0',

      '&:first-child': {
        marginTop: '10px',
      },
    },

    '& span': {
      display: 'block',
      width: 10,
      height: 10,
      borderRadius: 10,
      marginRight: 10,
      flex: '0 0 10px',
    },
  },
  noDataMessage: {
    position: 'absolute',
    left: '30px',
    right: '30px',
    top: '50%',
    transform: 'translate(0, -100%)',
    textAlign: 'center',
    color: theme.palette.grey[500],
  },
  chart: {
    position: 'relative',
    zIndex: 2,
  },
  total: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '13px',
    zIndex: 1,
  },
}));

const colourMapping = {
  bright: {
    0: baseColors.yellow,
    1: baseColors.pink,
    2: baseColors.purple,
    3: baseColors.green,
    4: baseColors.grey,
  },
};

const CustomPieChart = ({
  data,
  noDataMessage,
  theme,
  title,
  total,
  valueKey,
  labelKey,
}) => {
  const classes = useStyles();

  if (!isArrayWithLenth(data)) {
    return (
      <Card smallMargin smallVerticalPadding>
        <div className={classes.container}>
          <p className={classes.noDataMessage}>{noDataMessage}</p>
        </div>
      </Card>
    );
  }

  const getColour = (i) => {
    return colourMapping[theme][i] || baseColors.grey;
  };

  return (
    <Card smallMargin smallVerticalPadding>
      <div className={classes.container}>
        <div className={classes.col}>
          {title && <h6>{title}</h6>}
          <ul className={classes.legend}>
            {data.map((entry, i) => {
              return (
                <li key={entry.id}>
                  <span style={{ backgroundColor: getColour(i) }} />
                  <div>
                    <strong>{entry[labelKey] || entry.id}</strong> (
                    {Math.round(entry[valueKey])}
                    %)
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
        <div className={classes.col}>
          <ResponsiveContainer
            width="100%"
            height="100%"
            className={classes.chart}
          >
            <PieChart height={86} width={86}>
              <Pie
                data={data}
                labelLine={false}
                dataKey={valueKey}
                innerRadius={32}
                outerRadius={42}
              >
                {data.map((entry, i) => {
                  return <Cell key={entry.id} fill={getColour(i)} />;
                })}
              </Pie>
              <Tooltip
                formatter={(value, name) => [
                  `${Math.round(value)}%`,
                  `${(data[name][labelKey] || data[name].id).replace(
                    '{{a}}',
                    '',
                  )}`,
                ]}
              />
            </PieChart>
          </ResponsiveContainer>

          {!!total && (
            <div className={classes.total}>£{formatCurrency(total, true)}</div>
          )}
        </div>
      </div>
    </Card>
  );
};

CustomPieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  noDataMessage: PropTypes.string,
  theme: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
};

CustomPieChart.defaultProps = {
  noDataMessage: 'No data',
  theme: 'bright',
  title: null,
  total: null,
  valueKey: 'value',
  labelKey: 'id',
};

export default CustomPieChart;
