/* eslint-disable */
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { CachePersistor } from 'apollo-cache-persist';

import { setContext } from 'apollo-link-context';
import { onError } from 'apollo-link-error';
import { RetryLink } from 'apollo-link-retry';
import { ApolloLink, Observable } from 'apollo-link';
import { navigate } from '@reach/router';
import { createUploadLink } from 'apollo-upload-client';

import { GET_TOKEN, REFRESH_TOKEN } from '../graph/auth';

const DEFAULTS = {
  __typename: 'Query',
  accessToken: false,
  refreshToken: false,
  isAuthenticated: false,
};

const cache = new InMemoryCache();

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_API_URL,
});

const retryLink = new RetryLink();

const resetAuth = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    // we need to handle the refresh token?
    if (graphQLErrors && graphQLErrors.length) {
      console.error(graphQLErrors);

      for (const err in graphQLErrors) {
        if (err.message === 'Token is blacklisted') {
          cache.reset();
        }

        if (err.message === 'Token is invalid or expired') {
          cache.reset();
        }

        if (err.message === 'User not authenticated') {
          cache.reset();
        }
      }
    }

    if (networkError) {
      console.error(networkError);

      if (networkError.response && networkError.response.status === 401) {
        console.error('401 ERROR!');

        // do we have a refresh token?
        const data = cache.readQuery({ query: GET_TOKEN });

        console.log('data', data);

        // attempt refresh
        if (data?.refreshToken) {
          // we have a token, refresh it
          return new Observable((observer) => {
            return client
              .mutate({
                mutation: REFRESH_TOKEN,
                variables: {
                  refreshToken: data.refreshToken,
                },
              })
              .then(({ data }) => {
                // If there's no token returned from refresh

                if (!data?.refresh) {
                  client.clearStore();
                  client.writeData({ data: { isAuthenticated: false } });
                  navigate('/');
                  return;
                }

                client.writeQuery({
                  query: GET_TOKEN,
                  data: {
                    accessToken: data?.refresh?.accessToken,
                    refreshToken: data?.refresh?.refreshToken,
                  },
                });

                // resend the last operation
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    ...headers,
                    authorization: `Bearer ${data?.refresh?.accessToken}`,
                  },
                }));
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                forward(operation).subscribe(subscriber);
              })
              .catch((err) => {
                console.error(err);

                observer.error(err);

                cache.reset();
              });
          });
        }

        client.clearStore();
        client.writeData({ data: { isAuthenticated: false } });
        navigate('/');
      }
    }

    return false;
  },
);

const authLink = setContext((_, { headers }) => {
  try {
    const data = cache.readQuery({ query: GET_TOKEN });

    return {
      headers: {
        ...headers,
        authorization: data?.accessToken ? `Bearer ${data?.accessToken}` : null,
      },
    };
  } catch (err) {
    return headers;
  }
}).concat(resetAuth);

// $FlowFixMe
export const client = new ApolloClient({
  cache,
  link: ApolloLink.from([authLink, retryLink, httpLink]),
  resolvers: {
    Query: {
      accessToken: () => false,
      refreshToken: () => false,
    },
  },
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

export const persistor = new CachePersistor({
  cache: client.cache,
  storage: window.localStorage,
  debug: true,
});

client.persistor = persistor;

client.onClearStore(() => {
  cache.writeData({
    data: DEFAULTS,
  });
});

// setup defaults
cache.writeData({
  data: DEFAULTS,
});

export default client;
