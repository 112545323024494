import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import LockedButtonStyle from '@material-ui/core/Button';

import Button from '../Button';

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '100%',
    fontWeight: 500,
    lineHeight: '20px',
    textTransform: 'none',
    color: 'rgba(52,56, 88, 0.2)  !important',
    background: '#FFFFFF',
    boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.05)',
    borderRadius: '15px',
    fontSize: '16px',
    textAlign: 'left',
    margin: '8px 0',
    justifyContent: 'flex-start',
    padding: '20px',
    minHeight: '64px',
    height: 'auto',
    border: '1px solid #E5E5E5',
    alignItems: 'center',
    cursor: 'default',

    '&:hover, &:active, &:focus': {
      background: '#FFFFFF',
    },

    '& svg': {
      marginRight: '12px',
    },

    '& button': {
      marginLeft: 'auto',
      marginRught: '0',
    },
  },
}));

const LockedButton = ({ children, label, ...props }) => {
  const classes = useStyles();

  return (
    <LockedButtonStyle className={clsx(classes.button, {})}>
      <svg
        width="16"
        height="21"
        viewBox="0 0 16 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 7H13V5C13 2.24 10.76 0 8 0C5.24 0 3 2.24 3 5V7H2C0.9 7 0 7.9 0 9V19C0 20.1 0.9 21 2 21H14C15.1 21 16 20.1 16 19V9C16 7.9 15.1 7 14 7ZM8 16C6.9 16 6 15.1 6 14C6 12.9 6.9 12 8 12C9.1 12 10 12.9 10 14C10 15.1 9.1 16 8 16ZM11.1 7H4.9V5C4.9 3.29 6.29 1.9 8 1.9C9.71 1.9 11.1 3.29 11.1 5V7Z"
          fill="#343858"
          fillOpacity="0.2"
        />
      </svg>

      {children || label}

      <Button small {...props}>
        Start
      </Button>
    </LockedButtonStyle>
  );
};

LockedButton.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  label: PropTypes.string,
};

LockedButton.defaultProps = {
  children: undefined,
  to: undefined,
  label: undefined,
};

export default LockedButton;
