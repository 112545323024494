import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import Layout from '../../components/Layout';
import Scrollable from '../../components/Scrollable';
import ScatterChart from '../../components/ScatterChart';
import Title from '../../components/Title';
import HighestNet from '../../components/HighestNet';
import ResultsHeader from '../../components/ResultsHeader';
import ResultFeedback from '../../components/ResultFeedback';

import { GET_JOB_OPTIMISATION } from '../../graph/clarity';

import useTrackPage from '../../hooks/useTrackPage';

import getDateRange from '../../utils/getDateRange';

const JobOptimisation = () => {
  useTrackPage('/results/my-time');

  const {
    data: { highestNoneEarningTime, profitableJobs },
    loading,
    refetch,
  } = useQuery(GET_JOB_OPTIMISATION, {
    variables: {
      ...getDateRange(),
    },
  });

  const handleRefetch = () => {
    refetch({
      ...getDateRange(),
    });
  };

  const renderContent = () => {
    if (loading) {
      return false;
    }

    return (
      <>
        <Title variant="h3">Money earned vs hours worked</Title>
        <ScatterChart data={profitableJobs} />

        <Title variant="h3" largeMarginTop>
          {' '}
          Highest NET (Non Earning Time)
        </Title>
        <HighestNet data={highestNoneEarningTime} />
        <ResultFeedback section="my-time" />
      </>
    );
  };

  return (
    <Layout fetching={loading} animateIn>
      <Scrollable>
        <ResultsHeader title="My time" refetchFunction={handleRefetch} />
        {renderContent()}
      </Scrollable>
    </Layout>
  );
};

export default JobOptimisation;
