import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  spinner: {
    animation: `$spin 800ms infinite`,
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '12.4%': {
      transform: 'rotate(0deg)',
    },
    '12.5%': {
      transform: 'rotate(45deg)',
    },
    '24.9%': {
      transform: 'rotate(45deg)',
    },
    '25%': {
      transform: 'rotate(90deg)',
    },
    '37.4%': {
      transform: 'rotate(90deg)',
    },
    '37.5%': {
      transform: 'rotate(135deg)',
    },
    '49.9%': {
      transform: 'rotate(135deg)',
    },
    '50%': {
      transform: 'rotate(180deg)',
    },
    '62.4%': {
      transform: 'rotate(180deg)',
    },
    '62.5%': {
      transform: 'rotate(225deg)',
    },
    '74.9%': {
      transform: 'rotate(225deg)',
    },
    '75%': {
      transform: 'rotate(270deg)',
    },
    '87.4%': {
      transform: 'rotate(270deg)',
    },
    '87.5%': {
      transform: 'rotate(315deg)',
    },
    '99.9%': {
      transform: 'rotate(315deg)',
    },
    '100%': {
      transform: 'rotate(0deg)',
    },
  },
});

const Spinner = () => {
  const classes = useStyles();

  return (
    <svg
      width="66"
      height="65"
      viewBox="0 0 66 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes.spinner}
    >
      <rect
        opacity="0.4"
        x="31.29"
        width="3.42105"
        height="23.9474"
        rx="1.71053"
        fill="#4797D2"
      />
      <rect
        x="31.29"
        y="41.052"
        width="3.42105"
        height="23.9474"
        rx="1.71053"
        fill="#EBF5FA"
      />
      <rect
        x="65.5"
        y="30.7896"
        width="3.42105"
        height="23.9474"
        rx="1.71053"
        transform="rotate(90 65.5 30.7896)"
        fill="#EBF5FA"
      />
      <rect
        x="24.4482"
        y="30.7896"
        width="3.42105"
        height="23.9474"
        rx="1.71053"
        transform="rotate(90 24.4482 30.7896)"
        fill="#EBF5FA"
      />
      <rect
        x="57.1904"
        y="54.2713"
        width="3.42105"
        height="23.9474"
        rx="1.71053"
        transform="rotate(135 57.1904 54.2713)"
        fill="#EBF5FA"
      />
      <rect
        x="28.1621"
        y="25.2429"
        width="3.42105"
        height="23.9474"
        rx="1.71053"
        transform="rotate(135 28.1621 25.2429)"
        fill="#EBF5FA"
      />
      <rect
        x="11.2295"
        y="56.6903"
        width="3.42105"
        height="23.9474"
        rx="1.71053"
        transform="rotate(-135 11.2295 56.6903)"
        fill="#EBF5FA"
      />
      <rect
        x="40.2578"
        y="27.6619"
        width="3.42105"
        height="23.9474"
        rx="1.71053"
        transform="rotate(-135 40.2578 27.6619)"
        fill="#4797D2"
      />
    </svg>
  );
};

export default Spinner;
