import React from 'react';
import { useQuery } from '@apollo/react-hooks';

import SectionItem from '../SectionItem';
import Button from '../Button';
import FooterBar from '../FooterBar';
import IntroText from '../IntroText';

import useCalculator from '../../hooks/useCalculator';
import useCompleteCheck from '../../hooks/useCompleteCheck';

import { groups } from '../../config/groups';

import { GET_META } from '../../graph/auth';
import { GET_ACCOUNTS } from '../../graph/accounts';

const CalculatorMenu = (props) => {
  const { jobs, formData } = useCalculator(props);

  const { data } = useQuery(GET_META, { fetchPolicy: 'cache-only' });

  const {
    data: { accounts },
  } = useQuery(GET_ACCOUNTS, { fetchPolicy: 'cache-only' });

  const name = data?.user?.firstName;

  const { isJobsComplete, isIncomeComplete, isExpensesComplete, showResults } =
    useCompleteCheck({
      jobs,
      formData,
    });

  const showExpensesAndJobs =
    formData.g_productivity || formData.g_incomeAndExpense || formData.g_taxes;

  const renderText = () => {
    if (
      formData.g_productivity &&
      !formData.g_personalSpending &&
      !formData.g_incomeAndExpense &&
      !formData.g_taxes
    ) {
      return (
        <>
          Answer a few questions and receive personalised actions that help you
          make more money during the time you work. The whole thing shouldn’t
          take longer than <strong>5 minutes</strong>.
        </>
      );
    }

    if (
      !formData.g_productivity &&
      formData.g_personalSpending &&
      !formData.g_incomeAndExpense &&
      !formData.g_taxes
    ) {
      return (
        <>
          We will analyse your spending behaviour and provide insights into how
          you spend your money and give recommendations on ways to save. This
          should only take <strong>a couple of minutes</strong>.
        </>
      );
    }

    if (
      !formData.g_productivity &&
      !formData.g_personalSpending &&
      formData.g_incomeAndExpense &&
      !formData.g_taxes
    ) {
      return (
        <>
          Know the ins and outs of your business - understand how much you make
          across your jobs and where you could save money on your expenses. This
          shouldn’t take longer than <strong>5 minutes</strong>.
        </>
      );
    }

    if (
      !formData.g_productivity &&
      !formData.g_personalSpending &&
      !formData.g_incomeAndExpense &&
      formData.g_taxes
    ) {
      return (
        <>
          Learn new ways to save money on your taxes and reduce spending on your
          expenses. Receive a personalised tax estimate which includes your
          estimated income and expenses. This should take around{' '}
          <strong>5 minutes</strong>.
        </>
      );
    }

    const estimatedTime =
      (formData.g_productivity ? 5 : 0) +
      (formData.g_personalSpending ? 2 : 0) +
      (formData.g_incomeAndExpense ? 5 : 0) +
      (formData.g_taxes ? 5 : 0);

    return (
      <>
        We’ll ask you a series of questions about your work, income and
        expenses. Then we’ll guide you through your results. The whole process
        shouldn’t take longer than <strong>{estimatedTime} minutes</strong>.
      </>
    );
  };

  return (
    <>
      <IntroText
        title={
          <>
            {name && name !== 'Anonymous' ? (
              <>
                Hello {name}.<br />
              </>
            ) : (
              ''
            )}
            Welcome to Clarity.
          </>
        }
      >
        {renderText()}
      </IntroText>

      <FooterBar>
        {showExpensesAndJobs && (
          <SectionItem
            name="Jobs"
            to={
              isJobsComplete
                ? `/calculator/jobs/${groups.jobs.totalSteps}`
                : '/calculator/jobs/1'
            }
            complete={isJobsComplete}
          />
        )}

        <SectionItem
          name="Income"
          to={
            isIncomeComplete
              ? `/calculator/income/${
                  groups.income.totalSteps +
                  (formData?.g_productivity ? 3 : 0) +
                  (formData.g_productivity ||
                  formData.g_incomeAndExpense ||
                  formData.g_taxes
                    ? 3
                    : 0)
                }`
              : accounts?.length
              ? '/calculator/income/4'
              : '/calculator/income/1'
          }
          complete={isIncomeComplete}
          disabled={!isJobsComplete}
        />
        {showExpensesAndJobs && (
          <SectionItem
            name="Expenses"
            to={
              isExpensesComplete
                ? `/calculator/expenses/${
                    formData.g_taxes &&
                    !formData.g_incomeAndExpense &&
                    !formData.g_productivity
                      ? 3
                      : groups.expenses.totalSteps +
                        (formData?.expenses_workVehicle ? 1 : 0) +
                        (formData?.expenses_accountant ? 1 : 0) +
                        (formData?.expenses_phone ? 1 : 0) +
                        (formData?.expenses_other ? 1 : 0)
                  }`
                : '/calculator/expenses/1'
            }
            complete={isExpensesComplete}
            disabled={!isJobsComplete || !isIncomeComplete}
            margi
            nBottom
          />
        )}

        <Button to="/results" marginBottom disabled={!showResults}>
          View results
        </Button>
      </FooterBar>
    </>
  );
};

export default CalculatorMenu;
