import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  iconWrapper: {
    background: '#ffffff',
    border: '1px solid #e5e5e5',
    boxShadow: '5px 5px 33.2051px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
    padding: '2px',
    width: '74px',
    height: '74px',
    margin: '-66px auto 20px',
  },
}));

const IconWrapper = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.iconWrapper}>{children}</div>;
};

IconWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default IconWrapper;
