import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../TextField';
import PasswordField from '../PasswordField';
import CheckboxField from '../CheckboxField';

const Field = ({ type, ...props }) => {
  if (type === 'text' || type === 'password') {
    return <TextField type={type} {...props} />;
  }

  if (type === 'passwordNew') {
    return <PasswordField {...props} />;
  }

  if (type === 'checkbox') {
    return <CheckboxField {...props} />;
  }

  return <TextField type="text" {...props} />;
};

Field.propTypes = {
  type: PropTypes.string.isRequired,
};

export default Field;
