const getDateRangeText = () => {
  const dateRange =
    parseInt(localStorage.getItem('dateRange')?.replace('"', ''), 10) || 3;

  if (dateRange === 12) {
    return 'year';
  }

  return `${dateRange} months`;
};

export default getDateRangeText;
