import React from 'react';

import Card from '../Card';

const Icon = () => (
  <svg
    width="51"
    height="37"
    viewBox="0 0 51 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44.4478 5.4336H36.1292V4.99773C36.1292 2.24203 33.8872 0 31.1312 0H19.9139C17.1582 0 14.9162 2.24203 14.9162 4.99773V5.4336H6.59755C3.31618 5.4336 0.646484 8.10311 0.646484 11.3846V31.0489C0.646484 34.3305 3.31618 37 6.59755 37H44.4478C47.7293 37 50.3988 34.3305 50.3988 31.0489V11.3846C50.3988 8.10311 47.7293 5.4336 44.4478 5.4336ZM16.4687 4.99773C16.4687 3.0981 18.0143 1.55247 19.9139 1.55247H31.1312C33.0311 1.55247 34.5767 3.0981 34.5767 4.99773V5.4336H16.4687V4.99773ZM44.4478 35.4475H6.59755C4.17213 35.4475 2.19896 33.4744 2.19896 31.0489V18.9586H48.8464V31.0489C48.8464 33.4744 46.8732 35.4475 44.4478 35.4475ZM48.8464 17.4061H2.19896V11.3846C2.19896 8.95918 4.17213 6.98601 6.59755 6.98601H44.4478C46.8732 6.98601 48.8464 8.95918 48.8464 11.3846V17.4061Z"
      fill="#4797D2"
    />
    <path
      d="M25.5219 10.6094C23.9525 10.6094 22.6758 11.8861 22.6758 13.4555C22.6758 15.0249 23.9525 16.3017 25.5219 16.3017C27.0913 16.3017 28.3681 15.0249 28.3681 13.4555C28.3681 11.8861 27.0913 10.6094 25.5219 10.6094ZM25.5219 14.7492C24.8086 14.7492 24.2282 14.1688 24.2282 13.4555C24.2282 12.7422 24.8086 12.1618 25.5219 12.1618C26.2353 12.1618 26.8156 12.7422 26.8156 13.4555C26.8156 14.1688 26.2353 14.7492 25.5219 14.7492Z"
      fill="#4797D2"
    />
  </svg>
);

const WorkingHoursIntro = () => {
  return (
    <Card
      icon={Icon}
      title="Your working hours"
      text="Next we’ll ask you about the hours you work. This means we can show you insights to help you make the most out of your working week, like what your best paying jobs are."
      center
    />
  );
};

export default WorkingHoursIntro;
