import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';

import useCalculator from '../../hooks/useCalculator';

const JobGigsPerDay = (props) => {
  const { onJobFormChange, jobFormData, job } = useCalculator(props);

  return (
    <Card
      header={job.name}
      title="Based on the last month, how many gigs do you do a day on average?"
      text="By 'gigs', we mean things like doing a food delivery or a taxi ride."
    >
      <CalculatorForm
        fields={[
          {
            id: 'gigsPerDay',
            name: 'gigsPerDay',
            type: 'number',
            label:
              'Based on the last month, how many gigs do you do a day on average?',
            visuallyHidden: true,
            suffix: 'gigs per day',
          },
        ]}
        values={jobFormData}
        onChange={onJobFormChange}
      />
    </Card>
  );
};

export default JobGigsPerDay;
