import React, { useState, useEffect, useCallback } from 'react';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import BankList from '../BankList';
import LoadingOverlay from '../LoadingOverlay';
import Card from '../Card';
import CalculatorNavigation from '../CalculatorNavigation';
import IntroText from '../IntroText';

import { GET_TRUELAYER_URL, GET_BANKS } from '../../graph/banks';
import { GET_ACCOUNTS } from '../../graph/accounts';
import TextLink from '../TextLink';

const ConnectBank = ({ goToNextStep, goToPreviousStep }) => {
  const client = useApolloClient();
  const [connectingBank, setConnectingBank] = useState(false);
  const [currentAccountCount, setCurrentAccountCount] = useState(null);

  const { data: bankData, loading } = useQuery(GET_BANKS);

  const {
    data: { accounts },
    startPolling,
    stopPolling,
  } = useQuery(GET_ACCOUNTS, { fetchPolicy: 'network-only' });

  // eslint-disable-next-line
  const handleComplete = async () => {
    goToNextStep();
  };

  const authedAccountLength = accounts?.filter(
    (account) => !account.needsAuth,
  )?.length;

  useEffect(() => {
    if (
      authedAccountLength &&
      typeof currentAccountCount === 'number' &&
      authedAccountLength > currentAccountCount
    ) {
      stopPolling();

      setConnectingBank(false);
      setCurrentAccountCount(authedAccountLength);
      handleComplete();
    }
  }, [authedAccountLength, currentAccountCount, stopPolling, handleComplete]);

  const handleBankClick = useCallback(
    async (provider) => {
      setConnectingBank(true);
      setCurrentAccountCount(authedAccountLength);

      const {
        data: {
          truelayerUrl: { url },
        },
      } = await client.query({
        query: GET_TRUELAYER_URL,
        variables: {
          provider,
        },
        fetchPolicy: 'network-only',
      });

      startPolling(1000);

      window.location.href = url;
    },
    [authedAccountLength, client, startPolling],
  );

  const handleCancel = () => {
    stopPolling();
    setConnectingBank(false);
  };

  if (loading) {
    return false;
  }

  return (
    <>
      <IntroText title="Who do you bank with?" noBottomPadding>
        <p>
          Select your bank to import your transactions. (We can only view your
          transactions, we can’t change anything in your bank account.)
        </p>
        <TextLink to="/open-banking">Read more about Open Banking.</TextLink>
      </IntroText>

      <Card>
        <BankList banks={bankData?.banks} onClick={handleBankClick} />

        <LoadingOverlay
          open={connectingBank}
          title="Connecting your bank account..."
          onCancelClick={handleCancel}
        />
      </Card>

      <CalculatorNavigation
        goToNextStep={goToNextStep}
        goToPreviousStep={goToPreviousStep}
        onlyShowBackButton={!accounts?.length}
      />
    </>
  );
};

ConnectBank.propTypes = {
  goToNextStep: PropTypes.func.isRequired,
  goToPreviousStep: PropTypes.func.isRequired,
};

export default ConnectBank;
