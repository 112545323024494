import React from 'react';
import PropTypes from 'prop-types';
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
} from 'recharts';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import Card from '../Card';

import { formatCurrency, isArrayWithLenth } from '../../utils';

import { baseColors } from '../../theme/base';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 140,
    position: 'relative',
    overflow: 'hidden',
  },
  containerBg: {
    '&:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 20,
      left: 20,
      right: 0,
      background:
        'linear-gradient(222.8deg, rgba(255, 2, 0, 0.2) 3.19%, rgba(255, 2, 0, 0) 49%);',
      transform: ' matrix(1, 0, 0, -1, 0, 0)',
      pointerEvents: 'none',
    },

    '&:after': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 20,
      left: 20,
      right: 0,
      background:
        'linear-gradient(238.14deg, rgba(93, 179, 127, 0.25) 0%, rgba(93, 179, 127, 0) 44.6%)',
      transform: 'matrix(-1, 0, 0, 1, 0, 0)',
      pointerEvents: 'none',
    },

    '& .recharts-text.recharts-label': {
      fontSize: 10,
      fontWeight: 500,
      letterSpacing: '0.05em',
      color: '#252525',
      textTransform: 'uppercase',
    },
  },
  noDataMessage: {
    position: 'absolute',
    left: '30px',
    right: '30px',
    top: '50%',
    transform: 'translate(0, -100%)',
    textAlign: 'center',
    color: theme.palette.grey[500],
  },
  list: {
    padding: 0,
    margin: 0,

    '& li': {
      display: 'flex',
      margin: 0,
      padding: '10px 0',
      fontSize: 12,
      fontWeight: 500,
      alignItems: 'center',
      borderBottom: 'solid 1px #E5E5E5',

      '&:last-child': {
        borderBottom: 'none',
        paddingBottom: 0,
      },

      '&:first-child': {
        paddingTop: 0,
      },
    },

    '& i': {
      display: 'block',
      width: 12,
      height: 12,
      borderRadius: '100%',
      borderWidth: 3,
      borderStyle: 'solid',
      marginRight: 10,
    },
    '& span': {
      marginLeft: 'auto',
    },
  },
}));

const colourMapping = {
  0: baseColors.yellow,
  1: '#4797D2',
  2: baseColors.purple,
  3: baseColors.grey,
};

const CustomScatterChart = ({ data, noDataMessage }) => {
  const classes = useStyles();

  const singleJob = data.length === 1;

  if (!isArrayWithLenth(data)) {
    return (
      <Card smallMargin>
        <div className={classes.container}>
          <p className={classes.noDataMessage}>{noDataMessage}</p>
        </div>
      </Card>
    );
  }

  return (
    <>
      <Card smallMargin>
        <div className={clsx(classes.container, classes.containerBg)}>
          <ResponsiveContainer width="100%" height="100%">
            <ScatterChart
              data={data}
              margin={{
                top: 10,
                right: 10,
                bottom: 0,
                left: 0,
              }}
            >
              <Scatter>
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    stroke={colourMapping[index]}
                    fill="white"
                    strokeWidth="3"
                  />
                ))}
              </Scatter>
              <XAxis
                type="number"
                dataKey="hoursWorked"
                name="Hours worked"
                domain={[singleJob ? 'dataMin' : 0, 'dataMax']}
                tick={false}
                label={{
                  value: 'Hours worked',
                  position: 'insideCenter',
                  offset: 0,
                  dy: 10,
                  dx: -20,
                }}
                axisLine={false}
              />
              <YAxis
                type="number"
                dataKey="moneyEarned"
                name="Money earned"
                domain={[singleJob ? 'dataMin' : 0, 'dataMax']}
                tick={false}
                label={{
                  value: 'Money earned',
                  position: 'Money earned',
                  angle: -90,
                  dx: -25,
                  dy: 2,
                }}
                axisLine={false}
              />
              <Tooltip
                cursor={false}
                formatter={(value, name) => {
                  if (name === 'Money earned') {
                    return [`£${formatCurrency(value, true)}`, `${name}`];
                  }

                  return [`${Math.round(value)}`, `${name}`];
                }}
              />
            </ScatterChart>
          </ResponsiveContainer>
        </div>
      </Card>
      <Card smallMargin>
        <ol className={classes.list}>
          {data.map((entry, index) => (
            <li>
              <i style={{ borderColor: colourMapping[index] }} />
              {entry.id}
              <span>#{index + 1}</span>
            </li>
          ))}
        </ol>
      </Card>
    </>
  );
};

CustomScatterChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  noDataMessage: PropTypes.string,
};

CustomScatterChart.defaultProps = {
  noDataMessage: 'No data',
};

export default CustomScatterChart;
