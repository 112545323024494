import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    borderBottom: 'solid 1px #C4C4C4',
    marginBottom: '20px',
    paddingBottom: '20px',

    '&:last-child': {
      borderBottom: 'none',
      marginBottom: '0px',
      paddingBottom: '0px',
    },
  },
  question: {
    color: '#4797D2',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '22px',
  },
  value: { fontSize: '12px' },
  link: {
    color: '#4797D2',
    textDecoration: 'underline',
    fontSize: '12px',
    fontWeight: '500',
  },
}));

const Answer = ({ label, value, editLink, suffix, editLabel }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <p className={classes.question}>{label}</p>
      <p className={classes.value}>
        {value} {suffix}
      </p>
      <Link className={classes.link} to={editLink}>
        {editLabel}
      </Link>
    </div>
  );
};

Answer.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  editLink: PropTypes.string.isRequired,
  suffix: PropTypes.string,
  editLabel: PropTypes.string,
};

Answer.defaultProps = {
  suffix: '',
  editLabel: 'Edit answer',
};

export default Answer;
