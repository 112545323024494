import gql from 'graphql-tag';

const GET_INCOME_BREAKDOWN = gql`
  query IncomeBreakdown(
    $dateFrom: DateTime
    $dateTo: DateTime
    $incomeType: String
  ) {
    incomeBreakdown(
      dateFrom: $dateFrom
      dateTo: $dateTo
      incomeType: $incomeType
    ) {
      totalIncome
      income {
        id
        value
        label
      }
      clients {
        id
        value
        income {
          id
          value
          label
        }
      }
      jobTypes {
        id
        value
        income {
          id
          value
          label
        }
      }
    }
  }
`;

const GET_JOB_OPTIMISATION = gql`
  query JobOptimisation($dateFrom: DateTime, $dateTo: DateTime) {
    profitableJobs(dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      moneyEarned
      hoursWorked
    }
    highestNoneEarningTime {
      id
      hours
      netPercentage
    }
  }
`;

const GET_SPENDING_HABITS = gql`
  query SpendingHabits($dateFrom: DateTime, $dateTo: DateTime) {
    spendingHabits(dateFrom: $dateFrom, dateTo: $dateTo) {
      total
      categories {
        id
        total
        percentage
        merchants {
          id
          value
        }
        breakdown {
          id
          value
          label
        }
      }
    }
  }
`;

const GET_SUBSCRIPTIONS = gql`
  query Subscriptions($dateFrom: DateTime, $dateTo: DateTime) {
    subscriptions(dateFrom: $dateFrom, dateTo: $dateTo) {
      recurring
      top {
        id
        label
        percentage
      }
      all {
        id
        label
        recurring
      }
    }
  }
`;

const GET_EXPENSES = gql`
  query ExpenseBreakdown(
    $industry: String!
    $dateFrom: DateTime
    $dateTo: DateTime
  ) {
    expenses(industry: $industry, dateFrom: $dateFrom, dateTo: $dateTo) {
      id
      label
      value
      average
    }
  }
`;

const GET_TAXES = gql`
  query ExpenseBreakdown($industry: String!) {
    expenses(industry: $industry) {
      id
      label
      value
      average
    }
    taxCalculation(scope: "CURRENT_YEAR") {
      fullYearEstimate
      grossIncome
      expenses
      taxableIncome
      incomeTax
      nationalInsuranceTax
    }
  }
`;

const GET_HIGH_RISK_TRANSACTIONS = gql`
  query HighRiskTransactions($dateFrom: DateTime, $dateTo: DateTime) {
    highRiskTransactions(dateFrom: $dateFrom, dateTo: $dateTo) {
      top {
        id
        risk
        value
      }
      breakdown {
        id
        label
        value
      }
    }
  }
`;

const GET_INCOME_SCORE = gql`
  query IncomeScore($industry: String!) {
    score(industry: $industry) {
      incomeScore
      potentialIncomeScore
    }
  }
`;

const GET_SPENDING_SCORE = gql`
  query SpendingScore($industry: String!) {
    score(industry: $industry) {
      spendingScore
      potentialSpendingScore
    }
  }
`;

const GET_INCOME_IMPROVMENTS = gql`
  query IncomeScore($industry: String!) {
    score(industry: $industry) {
      incomeImprovements {
        id
      }
    }
  }
`;

const GET_SPENDING_IMPROVMENTS = gql`
  query IncomeScore($industry: String!) {
    score(industry: $industry) {
      spendingImprovements {
        id
      }
    }
  }
`;

export {
  GET_INCOME_BREAKDOWN,
  GET_SPENDING_HABITS,
  GET_SUBSCRIPTIONS,
  GET_JOB_OPTIMISATION,
  GET_EXPENSES,
  GET_TAXES,
  GET_HIGH_RISK_TRANSACTIONS,
  GET_INCOME_SCORE,
  GET_SPENDING_SCORE,
  GET_INCOME_IMPROVMENTS,
  GET_SPENDING_IMPROVMENTS,
};
