import React, { useContext } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import { navigate } from '@reach/router';

import Layout from '../../components/Layout';
import Form from '../../components/Form';
import Card from '../../components/Card';
import Footer from '../../components/Footer';
import FooterBar from '../../components/FooterBar';
import Button from '../../components/Button';

import { validate, requiredString } from '../../utils/validate';

import { CREATE_TOKEN, GET_TOKEN } from '../../graph/auth';

import { CalculatorFormContext } from '../../providers/calculator';

import useTrackPage from '../../hooks/useTrackPage';

const loginForm = [
  {
    id: 'email',
    name: 'email',
    type: 'email',
    label: 'Email',
  },
  {
    id: 'password',
    name: 'password',
    type: 'password',
    label: 'Password',
  },
];

const loginFormValidationSchema = validate({
  email: requiredString,
  password: requiredString,
});

const loginFormInitialValues = {
  email: '',
  password: '',
};

const Login = () => {
  const client = useApolloClient();
  const { onReplaceFormData, formData } =
    useContext(CalculatorFormContext) || {};
  useTrackPage('/log-in');

  const [handleLogin, { loading, error }] = useMutation(CREATE_TOKEN, {
    onCompleted({ logIn }) {
      if (!logIn) return;

      navigate(`/calculator`);

      client.writeData({
        data: {
          isAuthenticated: true,
        },
      });

      client.writeQuery({
        query: GET_TOKEN,
        data: logIn,
      });

      if (!logIn.user.calculatorMeta) return;

      onReplaceFormData({
        g_productivity: formData.g_productivity,
        g_incomeAndExpense: formData.g_incomeAndExpense,
        g_taxes: formData.g_taxes,
        g_personalSpending: formData.g_personalSpending,
        ...JSON.parse(logIn.user.calculatorMeta),
      });
    },
  });

  return (
    <Layout showFCALogo showIllustration>
      <Card title="Log-in">
        <Form
          fields={loginForm}
          loading={loading}
          error={error}
          onSubmit={handleLogin}
          validationSchema={loginFormValidationSchema}
          initialValues={loginFormInitialValues}
          buttonLabel="Log in"
        />
        <Button marginTop secondary to="/what-do-you-want-to-do-today">
          I don't have an account
        </Button>
      </Card>
      <FooterBar>
        <Footer />
      </FooterBar>
    </Layout>
  );
};

export default Login;
