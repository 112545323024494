import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';

import useCalculator from '../../hooks/useCalculator';

const JobSatisfaction = (props) => {
  const { onJobFormChange, jobFormData, job } = useCalculator(props);

  return (
    <Card header={job.name} center title="Job satisfaction">
      <CalculatorForm
        fields={[
          {
            id: 'satisfactionRating',
            name: 'satisfactionRating',
            type: 'rating',
            label: 'How satisfied are you with this type of work?',
          },
          {
            id: 'recomondationRating',
            name: 'recomondationRating',
            type: 'rating',
            ratingValues: {
              1: 'Not at all',
              2: 'Not likely',
              3: 'Likely',
              4: 'Very likely',
            },
            label:
              'How likely are you to recommend this type of work to a friend?',
          },
        ]}
        values={jobFormData}
        onChange={onJobFormChange}
      />
    </Card>
  );
};

export default JobSatisfaction;
