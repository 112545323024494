import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import Label from '../Label';

const useStyles = makeStyles({
  field: {
    background: '#ffffff',
    fontWeight: 500,

    '& .MuiSvgIcon-root': {
      fill: '#4797D2',
      right: 10,
    },
  },
  input: {
    borderRadius: '10px',
  },
  outline: {
    borderWidth: '1px',
    borderColor: '#C4C4C4',
    top: 0,

    '& legend': {
      display: 'none',
    },
  },

  altInput: {
    padding: '15.5px 14px',
  },
});

const SelectFieldAlt = ({
  options,
  name,
  disabled,
  readOnly,
  values,
  selectText,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth>
      {/* eslint-disable-next-line */}
      <Label {...props} />

      <NativeSelect
        id={name}
        name={name}
        input={
          <OutlinedInput
            shrink={false}
            classes={{
              root: classes.input,
              notchedOutline: classes.outline,
              input: classes.altInput,
            }}
            {...props}
          />
        }
        className={classes.field}
        value={`${values[name] || ''}`}
        disabled={disabled}
        readOnly={readOnly}
        {...props}
      >
        <option value="">{selectText}</option>
        {options &&
          options.map(
            ({ id: optionId, label: optionLabel }) =>
              optionId !== 'Other' && (
                <option key={optionId} value={optionId}>
                  {optionLabel || optionId}
                </option>
              ),
          )}
      </NativeSelect>
    </FormControl>
  );
};

SelectFieldAlt.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  values: PropTypes.shape({}).isRequired,
  selectText: PropTypes.string,
};

SelectFieldAlt.defaultProps = {
  label: null,
  readOnly: false,
  disabled: false,
  selectText: 'Select',
};

export default SelectFieldAlt;
