import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import Card from '../Card';

import { formatCurrency } from '../../utils';

const useStyles = makeStyles(() => ({
  title: {
    margin: 0,
    fontSize: '16px',
    fontWeight: 600,
    color: '#252525',
  },
  value: {
    margin: 0,
    fontSize: '30px',
    fontWeight: 600,
    color: '#4797D2',

    '& span': {
      fontSize: '50px',
    },
  },
  text: {
    margin: '0 0 10px 0',
    fontSize: '10px',
    fontWeight: 500,
    color: '#343858',

    '&:last-child': {
      margin: 0,
    },
  },
  average: {
    margin: 0,
    fontSize: '20px',
    fontWeight: 600,
    color: '#3A236F',

    '& span': {
      fontSize: '28px',
    },
  },
  white: {
    color: '#ffffff',
  },
}));

const ResultCard = ({
  label,
  value,
  text,
  average,
  largeMargin,
  children,
  blue,
}) => {
  const classes = useStyles();

  return (
    <Card smallMargin={!largeMargin} center blue={blue}>
      {!!label && (
        <h4 className={clsx(classes.title, { [classes.white]: blue })}>
          {label}
        </h4>
      )}

      <p className={clsx(classes.value, { [classes.white]: blue })}>
        £<span>{formatCurrency(value)}</span>
      </p>
      {!!text && (
        <p className={clsx(classes.text, { [classes.white]: blue })}>{text}</p>
      )}
      {!!average && (
        <>
          <p className={clsx(classes.average, { [classes.white]: blue })}>
            £<span>{formatCurrency(average)}</span>
          </p>
          <p className={clsx(classes.text, { [classes.white]: blue })}>
            Industry average
          </p>
        </>
      )}

      {children}
    </Card>
  );
};

ResultCard.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
  value: PropTypes.number.isRequired,
  average: PropTypes.number,
  largeMargin: PropTypes.bool,
  children: PropTypes.node,
  blue: PropTypes.bool,
};

ResultCard.defaultProps = {
  label: null,
  text: 'Total amount you spend per month',
  average: null,
  largeMargin: false,
  children: <></>,
  blue: false,
};

export default ResultCard;
