import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import InputAdornment from '@material-ui/core/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';

import Label from '../Label';

import { formatFieldValue } from '../../utils';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: '20px',
  },
  text: {
    height: 50,
  },
  input: {
    padding: '15.5px 14px',
    marginTop: '-3px',
  },
  outline: {
    border: '2px solid #E5E5E5',
    borderRadius: 10,

    '& legend': {
      display: 'none',
    },
  },
  adornment: {
    marginTop: '-3px',

    whiteSpace: 'nowrap',
    '& p': {
      lineHeight: '1.1875em',
    },
    '& svg': {
      color: '#4797D2',
    },
  },
}));

const SearchField = ({ name, defaultValue, value, ...props }) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth className={classes.formControl}>
      {/* eslint-disable-next-line */}
      <Label {...props} />

      <OutlinedInput
        classes={{
          root: clsx(classes.text),
          input: classes.input,
          notchedOutline: classes.outline,
        }}
        endAdornment={
          <InputAdornment position="end" className={classes.adornment}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.7501 18.5779L14.0642 12.8921C15.1656 11.5317 15.8287 9.803 15.8287 7.92023C15.8287 3.5565 12.2781 0.00585938 7.91433 0.00585938C3.5506 0.00585938 0 3.55646 0 7.92019C0 12.2839 3.55064 15.8346 7.91437 15.8346C9.79714 15.8346 11.5258 15.1714 12.8862 14.0701L18.5721 19.7559C18.7345 19.9184 18.9478 20 19.1611 20C19.3744 20 19.5876 19.9184 19.7501 19.7559C20.0758 19.4302 20.0758 18.9037 19.7501 18.5779ZM7.91437 14.1684C4.4687 14.1684 1.66619 11.3659 1.66619 7.92019C1.66619 4.47452 4.4687 1.67201 7.91437 1.67201C11.36 1.67201 14.1625 4.47452 14.1625 7.92019C14.1625 11.3659 11.36 14.1684 7.91437 14.1684Z"
                fill="#4797D2"
              />
            </svg>
          </InputAdornment>
        }
        id={name}
        name={name}
        type="text"
        value={formatFieldValue(defaultValue || `${value || ''}`, 'text')}
        {...props}
      />
    </FormControl>
  );
};

SearchField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
};

SearchField.defaultProps = {
  defaultValue: '',
  value: '',
};

export default SearchField;
