import { useContext, useEffect } from 'react';
import { navigate } from '@reach/router';
import { useMutation, useQuery } from '@apollo/react-hooks';

import getCalculatorSteps from '../config/calculatorSteps';

import { CalculatorFormContext } from '../providers/calculator';

import { UPDATE_USER } from '../graph/auth';
import {
  GET_CUSTOM_JOBS,
  CREATE_JOB,
  UPDATE_JOB,
  REMOVE_JOB,
} from '../graph/jobs';

import getJobSteps from '../config/jobSteps';
import { groups } from '../config/groups';

const useCalculator = ({ step, jobId, group }) => {
  const {
    onFormChange,
    formData,
    onReplaceFormData,
    onUpdateJobFormData,
    onUpdateFormData,
    onReplaceJobFormData,
  } = useContext(CalculatorFormContext) || {};

  const [addJob] = useMutation(CREATE_JOB, {
    refetchQueries: ['GetCustomJobs'],
    onCompleted: ({ createJob }) => {
      if (!createJob) return;
      onReplaceJobFormData(createJob.id, JSON.parse(createJob.calculatorMeta));
    },
  });

  const [handleUpdateJob, { loading: savingJob }] = useMutation(UPDATE_JOB, {
    refetchQueries: ['GetCustomJobs'],
  });

  const [handleRemoveJob, { loading: removingJob }] = useMutation(REMOVE_JOB, {
    refetchQueries: ['GetCustomJobs'],
  });

  const {
    data: { jobs },
    loading,
  } = useQuery(GET_CUSTOM_JOBS, {
    variables: {
      isCommonToUsers: false,
    },
    fetchPolicy: 'network-first',
  });

  const [handleUpdateUser, { loading: saving }] = useMutation(UPDATE_USER);

  const job = jobId ? jobs?.find((jobItem) => jobItem.id === jobId) : null;
  const jobFormData = formData.jobs?.[jobId] || {};

  const calculatorSteps = job
    ? getJobSteps(job, jobFormData)
    : getCalculatorSteps(formData, group);

  const currentStep = parseInt(step, 10);
  const totalSteps = calculatorSteps.length;

  useEffect(() => {
    const groupIndex = groups[group]?.order;
    const activeGroupIndex = groups[formData.activeGroup]?.order;

    if (jobId || !groupIndex || !currentStep) return;

    if (!formData.activeStep || currentStep > formData.activeStep) {
      onUpdateFormData({ activeStep: currentStep });
    }

    if (!activeGroupIndex || groupIndex > activeGroupIndex) {
      onUpdateFormData({ activeGroup: group, activeStep: 0 });
    }
  }, [currentStep, group]); // eslint-disable-line

  const {
    component: Step,
    isValid,
    hideNavigtion,
    onlyShowBackButton,
    showFCA,
    hideHeader,
    showQuote,
    pageUrl,
    scrollable,
  } = step ? calculatorSteps[currentStep - 1] : {};

  const disableNextButton = isValid
    ? !isValid({ formData, jobs, jobFormData })
    : false;

  const goToPreviousStep = () => {
    if (currentStep === 1 && job) {
      navigate(`/calculator/${group}/4`);
      return;
    }

    if (currentStep === 1) {
      navigate(`/calculator`);
      return;
    }

    navigate(
      jobId
        ? `/calculator/${group}/${jobId}/${currentStep - 1}`
        : `/calculator/${group}/${currentStep - 1}`,
    );
  };

  const goToNextStep = () => {
    if (currentStep === totalSteps && job) {
      handleUpdateJob({
        variables: {
          jobId,
          calculatorMeta: JSON.stringify({
            ...JSON.parse(job.calculatorMeta),
            ...jobFormData,
          }),
        },
      });

      navigate(`/calculator/${group}/4`);
      return;
    }

    if (!job) {
      handleUpdateUser({
        variables: { calculatorMeta: JSON.stringify(formData) },
      });
    }

    if (currentStep === totalSteps) {
      navigate(`/calculator`);
      return;
    }

    navigate(
      jobId
        ? `/calculator/${group}/${jobId}/${currentStep + 1}`
        : `/calculator/${group}/${currentStep + 1}`,
    );

    navigate(
      jobId
        ? `/calculator/${group}/${jobId}/${currentStep + 1}`
        : `/calculator/${group}/${currentStep + 1}`,
    );
  };

  const goToLastStep = () => {
    navigate(`/calculator/${group}/${calculatorSteps.length}`);
  };

  const saveFormData = async () => {
    await handleUpdateUser({
      variables: { calculatorMeta: JSON.stringify(formData) },
    });

    navigate('/save-and-exit');
  };

  const onRemoveJob = (id) => {
    handleRemoveJob({ variables: { jobId: id } });
  };

  const onCreateJobs = async () => {
    if (!formData?.industriesWithPaymentTypes.length) return;

    await Promise.all(
      formData.industriesWithPaymentTypes.map(
        ({
          subIndustry,
          paymentTypeName,
          paymentTypeId,
          industryId,
          industry,
          subIndustryId,
        }) => {
          const hasMatchedJob = jobs?.some(({ calculatorMeta }) => {
            const parsedMeta = JSON.parse(calculatorMeta);
            return (
              parsedMeta.subIndustry === subIndustryId &&
              parsedMeta.paymentType === paymentTypeId
            );
          });

          if (hasMatchedJob) return false;

          return addJob({
            variables: {
              name: `${subIndustry} ${paymentTypeName}`,
              jobType: paymentTypeId === 'PAYE' ? 'earner' : 'freelance',
              calculatorMeta: JSON.stringify({
                industry: industryId,
                industryName: industry,
                subIndustry: subIndustryId,
                subIndustryName: subIndustry,
                paymentTypeName,
                paymentType: paymentTypeId,
                ...(paymentTypeId === 'gig'
                  ? {
                      waitingTimeOnASlowDay: 0,
                      waitingTimeOnANormalDay: 0,
                      waitingTimeOnABusyDay: 0,
                    }
                  : {}),
              }),
            },
          });
        },
      ),
    );

    goToNextStep();
  };

  const onJobFormChange = (e) => {
    onUpdateJobFormData(e, jobId);
  };

  return {
    Step,
    goToNextStep,
    goToPreviousStep,
    goToLastStep,
    currentStep,
    onFormChange,
    onReplaceFormData,
    formData,
    saveFormData,
    saving: saving || savingJob,
    jobFormData,
    onJobFormChange,
    onCreateJobs,
    onRemoveJob,
    removingJob,
    job,
    jobs,
    disableNextButton,
    hideNavigtion,
    onlyShowBackButton,
    showFCA,
    loading,
    hideHeader,
    showQuote,
    pageUrl,
    scrollable,
    isValid,
    calculatorSteps,
    group,
  };
};

export default useCalculator;
