const getUniqueIndustriesFromJobs = (jobs) => {
  if (!jobs?.length)
    return [
      {
        id: 'deliveryGeneral',
      },
    ];

  const uniqueJob = jobs.reduce((a, { calculatorMeta }) => {
    const parsedMeta = JSON.parse(calculatorMeta);

    if (!parsedMeta?.industryName) return a;

    return {
      ...a,
      [parsedMeta.industryName]: {
        id: parsedMeta.industryName,
        label: parsedMeta.industryName,
      },
    };
  }, {});

  return Object.values(uniqueJob);
};

export default getUniqueIndustriesFromJobs;
