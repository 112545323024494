import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  bank: {
    display: 'flex',
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    cursor: 'pointer',
    border: 'none',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.15)',

    transition: theme.transitions.create(['opacity'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.shortest,
    }),

    '&:hover': {
      opacity: 0.7,
    },
  },
  logo: {
    flex: '0 0 100%',
    width: '100%',
  },
}));

const BankListItem = ({ truelayerProvider, name, logo, tint, onClick }) => {
  const classes = useStyles();

  const handleBankClick = () => {
    onClick(truelayerProvider);
  };

  return (
    <button
      target="_blank"
      type="button"
      onClick={handleBankClick}
      className={classes.bank}
      style={{ backgroundColor: tint }}
    >
      <img src={logo} alt={name} className={classes.logo} />
    </button>
  );
};

BankListItem.propTypes = {
  truelayerProvider: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  tint: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default BankListItem;
