import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useApolloClient } from '@apollo/react-hooks';

import Layout from '../../components/Layout';
import Card from '../../components/Card';
import Quote from '../../components/Quote';
import Spinner from '../../components/Spinner';
import HelpCard from '../../components/HelpCard';

import { GET_TOKEN } from '../../graph/auth';

const STEP_CONTENT = [
  {
    title: 'Creating your free Finmo Account',
    helpTitle: 'Stress-free tax returns',
    helpText:
      'Finmo’s tax return portal simplifies the Self Assessment process. Unlike with HMRC’s site, our handy prompts, questions and explanations help you pull the information you need together. And we’ll handle all the complicated bits for you.',
  },
  {
    title: 'Importing your Clarity profile into Finmo',
    helpTitle: 'Expert accountants',
    helpText:
      'Our advisors and accountants work with you to make sure you catch every expense and aren’t paying more than you need to.',
  },
  {
    title: 'Redirecting you to the Finmo Portal',
    helpTitle: 'Pricing that suits you',
    helpText:
      'Our Self Assessment plans start at £60 and are tailored to how many income sources you need to include in your tax return (e.g. self-employment and PAYE).',
  },
];

const icon = () => <Spinner />;

const STEP_INTERVAL = 6000;

const FinmoConnect = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const client = useApolloClient();
  const interval = useRef();

  const handleGoToNextStep = useCallback(async () => {
    if (currentStep + 1 === STEP_CONTENT.length) {
      clearInterval(interval.current);

      const data = await client.readQuery({ query: GET_TOKEN });

      window.location.href = `${process.env.REACT_APP_FINMO_URL}auth/${data.accessToken}/${data.refreshToken}/onboarding%2Fincome`;
      return;
    }

    setCurrentStep(currentStep + 1);
  }, [client, currentStep]);

  useEffect(() => {
    interval.current = setInterval(() => {
      handleGoToNextStep();
    }, STEP_INTERVAL);
    return () => clearInterval(interval.current);
  }, [handleGoToNextStep]);

  const currentStepContent = STEP_CONTENT[currentStep];

  return (
    <Layout>
      <Card icon={icon} center title={currentStepContent.title}>
        <HelpCard
          title={currentStepContent.helpTitle}
          text={currentStepContent.helpText}
        />
      </Card>
      <Quote />
    </Layout>
  );
};

export default FinmoConnect;
