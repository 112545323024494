import { createMuiTheme, makeStyles } from '@material-ui/core/styles';

export const baseColors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  pinkLight: 'rgba(255, 53, 144, 0.1)',
  green: '#65CE7F',
  greenLight: 'rgba(11, 236, 100, 0.2)',
  blue: '#2CD5FF',
  blueLight: 'rgba(44, 213, 255, 0.2)',
  yellow: '#FFD76D',
  pink: '#FF8FA7',
  purple: '#8B80EE',
  grey: '#E5E5E5',
};

export const baseTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#4797D2',
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
});

export const globalStyles = makeStyles({
  '@global': {
    html: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      flex: '1 0 100%',
      background: '#EBF5FA',
    },
    body: {
      display: 'flex',
      width: '100%',
      height: '100%',
      flexDirection: 'column',
      flex: '1 0 100%',
      color: '#343858',
      background: '#EBF5FA',

      '& #root, & #root > div': {
        display: 'flex',
        width: '100%',
        height: '100%',
        flexDirection: 'column',
        flex: '1 0 100%',
      },
    },
    button: {
      fontFamily: "'Poppins', sans-serif",
    },
  },
});
