import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';

import HelpIcon from '@material-ui/icons/Help';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  helpIconButton: {
    width: '30px',
    height: '30px',
    position: 'relative',
    top: '-2px',
    right: '-8px',

    '& svg': {
      color: theme.palette.grey[400],
      width: '20px',
      heignt: '20px',
      transition: theme.transitions.create(['color'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shortest,
      }),
    },
    '&:hover svg': {
      color: '#4797D2',
    },
  },
  label: {
    marginBottom: 0,
    lineHeight: 1.4,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  hasHelpText: {
    paddingRight: '50px',
  },
  tooltip: {
    backgroundColor: theme.palette.grey[800],
    padding: '10px',
    fontSize: '14px',
  },
  marginBottom: {
    marginBottom: ' 8px',
  },
  optionalText: {
    color: theme.palette.grey[400],
    fontSize: 12,
    textTransform: 'uppercase',
    marginLeft: 'auto',
    display: 'block',
  },
  visuallyHidden: {
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    width: '1px',
  },
  labelText: {
    fontWeight: 500,
    fontSize: '16px',
    color: '#252525',
  },
  grey: {
    color: '#343858',
  },
}));

const Label = ({
  id,
  label,
  helpText,
  marginBottom,
  optional,
  visuallyHidden,
  grey,
}) => {
  const classes = useStyles();

  return (
    <FormLabel
      htmlFor={id}
      className={clsx(classes.label, {
        [classes.marginBottom]: marginBottom,
        [classes.hasHelpText]: !!helpText,
        [classes.visuallyHidden]: visuallyHidden,
      })}
    >
      <span
        className={clsx(classes.labelText, {
          [classes.grey]: grey,
        })}
      >
        {label}
      </span>

      {optional && <span className={classes.optionalText}>Optional</span>}

      {helpText && (
        <IconButton size="small" className={classes.helpIconButton}>
          <Tooltip title={helpText} classes={{ tooltip: classes.tooltip }}>
            <HelpIcon />
          </Tooltip>
        </IconButton>
      )}
    </FormLabel>
  );
};

Label.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  helpText: PropTypes.string,
  marginBottom: PropTypes.bool,
  optional: PropTypes.bool,
  visuallyHidden: PropTypes.bool,
  grey: PropTypes.bool,
};

Label.defaultProps = {
  helpText: null,
  marginBottom: false,
  optional: false,
  visuallyHidden: false,
  grey: false,
};

export default Label;
