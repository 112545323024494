const defaultValues = {
  totalTime: 0,
  totalDays: 0,
  jobArray: [],
};

const calculateTimeAcrossJobs = (jobs) => {
  if (!jobs?.length) return defaultValues;

  return jobs.reduce((a, { calculatorMeta, ...job }) => {
    const { hoursPerDay, daysPerMonth, daysPerWeek } =
      JSON.parse(calculatorMeta);

    if (!hoursPerDay || (!daysPerMonth && !daysPerWeek)) {
      return a;
    }

    const caclulatedDaysPerMonth = daysPerWeek
      ? daysPerWeek * 4.33
      : daysPerMonth;

    const jobHoursPerMonth = hoursPerDay * caclulatedDaysPerMonth;

    return {
      totalTime: a.totalTime + jobHoursPerMonth,
      totalDays: a.totalDays + caclulatedDaysPerMonth,
      jobArray: [
        ...a.jobArray,
        {
          id: job.id,
          name: job.name,
          hours: jobHoursPerMonth,
        },
      ],
    };
  }, defaultValues);
};

export default calculateTimeAcrossJobs;
