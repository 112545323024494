import React, { memo } from 'react';
import { useSpring, animated } from 'react-spring';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import MaterialModal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
  modal: {
    overflow: 'visible',
    padding: '20px',
    display: 'flex',
    minHeight: '100vh',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: '50px',
    },
  },
  backdrop: {
    backgroundColor: ' rgba(0, 0, 0, 0.7)',
  },
  inner: {
    color: theme.palette.common.white,
    margin: 'auto',
    width: '100%',
    overflow: 'visible',
    maxWidth: '500px',
    textAlign: 'center',

    '&:focus': {
      outline: 'none',
    },
  },
  loader: {
    marginBottom: 20,
  },
  cancelButton: {
    marginTop: 20,
    color: theme.palette.common.white,
  },
}));

const LoadingOverlay = ({ open, title, text, onCancelClick }) => {
  const classes = useStyles();

  const style = useSpring({
    opacity: open ? 1 : 0,
    transform: open ? 'translate(0, 0)' : 'translate(0, 50px)',
  });

  const backdropStyle = useSpring({
    opacity: open ? 1 : 0,
  });

  const renderBackdrop = () => (
    <animated.div style={backdropStyle}>
      <Backdrop open transitionDuration={0} className={classes.backdrop} />
    </animated.div>
  );

  return (
    <MaterialModal
      open={open}
      className={classes.modal}
      BackdropComponent={renderBackdrop}
    >
      <animated.div className={clsx(classes.inner)} style={style}>
        <CircularProgress color="white" size={80} className={classes.loader} />
        <Typography variant="h5">{title}</Typography>

        {text && <Typography variant="p">{text}</Typography>}

        {onCancelClick && (
          <Button
            className={classes.cancelButton}
            color="white"
            onClick={onCancelClick}
          >
            Cancel
          </Button>
        )}
      </animated.div>
    </MaterialModal>
  );
};

LoadingOverlay.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  onCancelClick: PropTypes.func,
};

LoadingOverlay.defaultProps = {
  onCancelClick: null,
  text: null,
};

export default memo(LoadingOverlay);
