import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';

import Label from '../Label';

import { ReactComponent as Chevron } from '../../assets/chevron.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '192px',
    height: '128px',
    position: 'relative',
    margin: '0 auto 30px',
    display: 'block',
  },
  input: {
    width: '108px',
    height: '128px',
    borderRadius: 0,

    '& input': {
      appearance: 'textfield',
      textAlign: 'center',
      fontWeight: 200,
      fontSize: '100px',

      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        appearance: 'none',
        margin: 0,
      },
    },
  },
  outline: {
    borderLeft: 'none',
    borderTop: 'none',
    borderRight: 'none',
    borderWidth: '1px',
  },
  button: {
    position: 'absolute',
    height: '60px',
    width: '60px',
    display: 'flex',
    right: 0,
    background: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    border: '0.707317px solid #429CDB',
    borderRadius: '15px',
    backgroundColor: '#429CDB',

    '& span': {
      clip: 'rect(0 0 0 0)',
      clipPath: 'inset(50%)',
      height: '1px',
      overflow: 'hidden',
      position: 'absolute',
      whiteSpace: 'nowrap',
      width: '1px',
    },

    '& svg': {
      width: '18px',
      height: '18px',
      display: 'block',
      fill: theme.palette.common.white,
    },

    '&:focus': {
      outline: 'none',

      '& svg': {
        fill: theme.palette.secondary.dark,
      },
    },

    '&:disabled': {
      border: '0.707317px solid #C4C4C4',
      backgroundColor: '#ffffff',

      '& svg': {
        fill: '#C4C4C4',
      },
    },
  },
  increment: {
    top: 0,
  },
  decrement: {
    bottom: 0,

    '& svg': {
      transform: 'rotate(180deg)',
    },
  },
}));

const LargeNumberField = ({
  name,
  id,
  label,
  onChange,
  values,
  maxValue,
  minValue,
}) => {
  const classes = useStyles();

  const value = values[name];

  const handleBlur = () => {
    if (!value) {
      onChange({ target: { name, value: minValue } });
    }
  };

  const handleSetValue = (newValue) => {
    if (newValue <= minValue) {
      onChange({ target: { name, value: minValue } });
      return;
    }

    if (newValue >= maxValue) {
      onChange({ target: { name, value: maxValue } });
      return;
    }

    onChange({ target: { name, value: newValue } });
  };

  const handleOnChange = (e) => {
    if (!e?.target?.value) {
      onChange({ target: { name, value: null } });
      return;
    }

    handleSetValue(parseInt(e.target.value, 10));
  };

  const handleIncrement = () => {
    handleSetValue(value + 1);
  };

  const handleDecrement = () => {
    handleSetValue(value - 1);
  };

  return (
    <FormControl fullWidth className={classes.container}>
      {/* eslint-disable-next-line */}
      <Label id={id} label={label} visuallyHidden />

      <OutlinedInput
        classes={{
          root: classes.input,
          notchedOutline: classes.outline,
        }}
        id={id}
        name={name}
        type="number"
        value={value}
        onChange={handleOnChange}
        onBlur={handleBlur}
        inputmode="numeric"
      />

      <button
        type="button"
        className={clsx(classes.button, classes.increment)}
        onClick={handleIncrement}
        disabled={value >= maxValue}
      >
        <Chevron />
        <span>Increase by one</span>
      </button>

      <button
        type="button"
        className={clsx(classes.button, classes.decrement)}
        onClick={handleDecrement}
        disabled={value <= minValue}
      >
        <Chevron />
        <span>Decrease by one</span>
      </button>
    </FormControl>
  );
};

LargeNumberField.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  values: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  maxValue: PropTypes.func.isRequired,
  minValue: PropTypes.func.isRequired,
};

export default LargeNumberField;
