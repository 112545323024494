import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';

import useCalculator from '../../hooks/useCalculator';

const ExpensesSavings = (props) => {
  const { onFormChange, formData } = useCalculator(props);

  return (
    <Card
      subTitle="Answer all"
      title="Do you regularly put money into another account as savings?"
    >
      <CalculatorForm
        fields={[
          {
            id: 'hasSavings',
            name: 'hasSavings',
            type: 'radio',
            visuallyHidden: true,
            label:
              'Do you regularly put money into another account as savings?',
            options: [
              {
                id: 'yes',
                label: 'Yes',
                value: 'yes',
              },
              {
                id: 'no',
                label: 'No',
                value: 'no',
              },
            ],
          },
          {
            id: 'savingsAmount',
            name: 'savingsAmount',
            type: 'number',
            label: 'How much a month, roughly?',
            prefix: '£',
            suffix: 'per month',
          },
        ]}
        values={formData}
        onChange={onFormChange}
      />
    </Card>
  );
};

export default ExpensesSavings;
