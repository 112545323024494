import gql from 'graphql-tag';

const GET_TOKEN = gql`
  query GetToken {
    accessToken @client
    refreshToken @client
  }
`;

const IS_AUTHENTICATED = gql`
  query IsAuthenticated {
    isAuthenticated @client
  }
`;

const CREATE_USER = gql`
  mutation {
    anonymousRegistration {
      accessToken
    }
  }
`;

const COMPLETE_REGISTRATION = gql`
  mutation CompleteAnonymousRegistration(
    $email: String!
    $password: String!
    $acceptedTermsAt: DateTime!
  ) {
    completeAnonymousRegistration(
      email: $email
      password: $password
      acceptedTermsAt: $acceptedTermsAt
    )
  }
`;

const CREATE_TOKEN = gql`
  mutation LogIn($email: String!, $password: String!) {
    logIn(email: $email, password: $password) {
      accessToken
      refreshToken
      user {
        id
        email
        calculatorMeta
        anonymous
      }
    }
  }
`;

const REFRESH_TOKEN = gql`
  mutation ($refreshToken: String!) {
    refresh(refreshToken: $refreshToken) {
      accessToken
      refreshToken
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser(
    $calculatorMeta: JSONString
    $firstName: String
    $lastName: String
  ) {
    updateUser(
      calculatorMeta: $calculatorMeta
      firstName: $firstName
      lastName: $lastName
    ) {
      id
      calculatorMeta
      firstName
      lastName
      anonymous
    }
  }
`;

const GET_META = gql`
  query GetMeta {
    user {
      id
      email
      calculatorMeta
      anonymous
      firstName
    }
  }
`;

export {
  GET_TOKEN,
  IS_AUTHENTICATED,
  CREATE_USER,
  COMPLETE_REGISTRATION,
  CREATE_TOKEN,
  REFRESH_TOKEN,
  UPDATE_USER,
  GET_META,
};
