import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import clsx from 'clsx';
import Card from '../Card';

import { isArrayWithLenth, formatCurrency } from '../../utils';

const useStyles = makeStyles((theme) => ({
  noDataMessage: {
    textAlign: 'center',
    color: theme.palette.grey[500],
  },
  list: {
    padding: 0,
    margin: 0,

    '& li': {
      display: 'flex',
      margin: 0,
      padding: '10px',
      fontSize: 12,
      fontWeight: 500,
      alignItems: 'center',
      borderBottom: 'solid 1px #E5E5E5',

      '&:last-child': {
        borderBottom: 'none',
        paddingBottom: 0,
      },

      '&:first-child': {
        paddingTop: 0,
      },
    },

    '& strong': {
      display: 'block',
      marginRight: 10,
      color: '#4797D2',
    },

    '& span': {
      marginLeft: 'auto',
      fontSize: 10,
      fontWeight: 400,
      lineHeight: 1,
    },
  },
  highlight: {
    backgroundColor: '#EBF5FA',
    fontWeight: '700!important',
    paddingBottom: '10px!important',

    '& span': {
      fontWeight: 700,
    },
  },
}));

const ValueList = ({ data, noDataMessage, showIndex, valueKey, labelKey }) => {
  const classes = useStyles();

  if (!isArrayWithLenth(data)) {
    return (
      <Card smallMargin>
        <div className={classes.container}>
          <p className={classes.noDataMessage}>{noDataMessage}</p>
        </div>
      </Card>
    );
  }

  return (
    <Card smallMargin>
      <ol className={classes.list}>
        {data.map((entry, i) => {
          const value = entry[valueKey];
          return (
            <li
              key={entry.id}
              className={clsx({ [classes.highlight]: entry.highlight })}
            >
              {showIndex && <strong>{i + 1}</strong>}
              {entry[labelKey].replace('{{a}}', '')}
              {(!!value || value === 0) && (
                <span>£{formatCurrency(value)}</span>
              )}
            </li>
          );
        })}
      </ol>
    </Card>
  );
};

ValueList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  noDataMessage: PropTypes.string,
  showIndex: PropTypes.bool,
  valueKey: PropTypes.string,
  labelKey: PropTypes.string,
};

ValueList.defaultProps = {
  noDataMessage: 'No data',
  showIndex: false,
  valueKey: 'value',
  labelKey: 'id',
};

export default ValueList;
