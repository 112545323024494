import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import Button from '../Button';

const useStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.common.white,
    display: 'flex',
    justifyContent: 'space-between',
  },
  center: {
    justifyContent: 'center',
  },
}));

const CalculatorNavigation = ({
  goToNextStep,
  goToPreviousStep,
  disableNextButton,
  onlyShowBackButton,
}) => {
  const classes = useStyles();

  if (onlyShowBackButton) {
    return (
      <footer className={classes.header}>
        <Button medium onClick={goToPreviousStep} secondary>
          Prev
        </Button>
      </footer>
    );
  }

  return (
    <footer className={classes.header}>
      <Button medium secondary onClick={goToPreviousStep}>
        Prev
      </Button>
      <Button medium onClick={goToNextStep} disabled={disableNextButton}>
        Next
      </Button>
    </footer>
  );
};

CalculatorNavigation.propTypes = {
  goToNextStep: PropTypes.func.isRequired,
  goToPreviousStep: PropTypes.func.isRequired,
  disableNextButton: PropTypes.bool,
  onlyShowBackButton: PropTypes.bool,
};

CalculatorNavigation.defaultProps = {
  disableNextButton: false,
  onlyShowBackButton: false,
};

export default CalculatorNavigation;
