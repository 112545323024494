import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Label from '../Label';

const useStyles = makeStyles({
  formControl: {
    marginTop: '10px',
    marginBottom: '10px',
    width: '100%',
  },
  label: {
    marginBottom: '8px',
    lineHeight: 1.4,
  },
  radio: {
    width: '100%',
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    borderRadius: '10px',
    minHeight: '50px',
    margin: '5px 0',
    display: 'flex',
    alignItems: 'center',
    color: '#343858',
    padding: '15px 15px 15px 6px',

    '& .MuiTypography-body1': {
      fontWeight: 500,
      fontSize: '16px',
    },
  },
  radioActive: {
    padding: '16px 16px 16px 7px',
    background: '#4797D2',
    color: '#ffffff',
    border: 'none',
  },
  radioIcon: {
    marginRight: 6,
    '& div': {
      background: '#ffffff',
      border: 'solid 2px #4797D2',
      borderRadius: '100px',
      width: 20,
      height: 20,
      position: 'relative',

      '&:after': {
        content: "''",
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        opacity: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        backgroundImage: `url('data:image/svg+xml,%3Csvg width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M8.73602 0.131241C8.46268 -0.0729121 8.08684 -0.0388861 7.88183 0.233318L2.96169 6.56207L1.01413 4.8608C0.774961 4.62262 0.364949 4.65664 0.159943 4.92885C-0.0792306 5.16703 -0.0450627 5.57533 0.228278 5.77949L2.62001 7.85505C2.75668 7.95712 2.92752 8.02517 3.06419 7.99115C3.2692 7.99115 3.4742 7.9231 3.61088 7.75297L8.90686 0.947855C9.0777 0.743702 9.00936 0.36942 8.73602 0.131241Z" fill="%234797D2"/%3E%3C/svg%3E%0A')`,
      },
    },
    '& svg': {
      display: 'none',
    },
  },

  radioIconActive: {
    '& div': {
      background: '#ffffff',
      borderColor: '#ffffff',

      '&:after': {
        opacity: 1,
      },
    },
  },
});

const RadioField = ({
  options,
  name,
  disabled,
  readOnly,
  values,
  ...props
}) => {
  const classes = useStyles();
  const value = `${values[name]}`;

  return (
    <FormControl fullWidth className={classes.formControl}>
      {/* eslint-disable-next-line */}
      <Label {...props} />

      <RadioGroup value={value} row {...props}>
        {options &&
          options.map(({ id, value: optionValue, label: optionLabel }) => (
            <FormControlLabel
              key={id}
              id={id}
              name={name}
              value={optionValue}
              control={
                <Radio
                  className={clsx(classes.radioIcon, {
                    [classes.radioIconActive]: value === optionValue,
                  })}
                />
              }
              label={optionLabel}
              labelPlacement="end"
              className={clsx(classes.radio, {
                [classes.radioActive]: value === optionValue,
              })}
              disabled={disabled || readOnly}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
};

RadioField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  hintText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  values: PropTypes.shape({}).isRequired,
};

RadioField.defaultProps = {
  hintText: null,
  disabled: false,
  readOnly: false,
};

export default RadioField;
