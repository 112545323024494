import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';
import HelpCard from '../HelpCard';

import useCalculator from '../../hooks/useCalculator';

const JobDaysPerWeek = (props) => {
  const { onJobFormChange, jobFormData, job } = useCalculator(props);

  return (
    <Card
      header={job.name}
      title="Based on the last month, how many days per week do you do this work?"
    >
      <CalculatorForm
        fields={[
          {
            id: 'daysPerWeek',
            name: 'daysPerWeek',
            type: 'number',
            suffix: 'days',
            label:
              'Based on the last month, how many days per week do you do this work?',
            visuallyHidden: true,
          },
        ]}
        values={jobFormData}
        onChange={onJobFormChange}
      />

      {!!(jobFormData.daysPerWeek > 7) && (
        <HelpCard
          red
          marginBottom
          text={`You work an total of ${jobFormData.daysPerWeek} days per week, are you sure this is correct?`}
        />
      )}
    </Card>
  );
};

export default JobDaysPerWeek;
