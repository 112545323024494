import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import useLocalStorageStateBase from 'use-local-storage-state/src/useLocalStorageStateBase';
import { navigate } from '@reach/router';

import Layout from '../../components/Layout';
import Button from '../../components/Button';
import Scrollable from '../../components/Scrollable';
import ScoreVisualisation from '../../components/ScoreVisualisation';
import BackHeader from '../../components/BackHeader';
import TextLink from '../../components/TextLink';
import ResultsTabs from '../../components/ResultsTabs';
import LockedButton from '../../components/LockedButton';

import useTrackPage from '../../hooks/useTrackPage';

import { GET_INCOME_SCORE, GET_SPENDING_SCORE } from '../../graph/clarity';
import { GET_CUSTOM_JOBS } from '../../graph/jobs';

import getUniqueIndustriesFromJobs from '../../utils/getUniqueIndustriesFromJobs';

import { CalculatorFormContext } from '../../providers/calculator';

const Results = () => {
  const { formData, onUpdateFormData } =
    useContext(CalculatorFormContext) || {};

  const defaultTab =
    !formData.g_productivity &&
    !formData.g_incomeAndExpense &&
    !formData.g_taxes
      ? 'spendingHabits'
      : 'income';

  const [activeTab, setActiveTab] = useLocalStorageStateBase(
    'activeTab',
    defaultTab,
  );

  useTrackPage('/results/net-hourly-wage');

  const {
    data: { jobs },
  } = useQuery(GET_CUSTOM_JOBS, {
    variables: {
      isCommonToUsers: false,
    },
    fetchPolicy: 'cache-first',
  });

  const uniqueIndustryList = getUniqueIndustriesFromJobs(jobs);

  const { data: incomeScoreData, loading: loadingIncome } = useQuery(
    GET_INCOME_SCORE,
    {
      variables: {
        industry: uniqueIndustryList[0].id,
      },
      skip: activeTab !== 'income',
    },
  );

  const { data: spendingScoreData, loading: loadingSpending } = useQuery(
    GET_SPENDING_SCORE,
    {
      variables: {
        industry: uniqueIndustryList[0].id,
      },
      skip: activeTab !== 'spendingHabits',
    },
  );

  const score =
    incomeScoreData?.score?.incomeScore ||
    spendingScoreData?.score?.spendingScore;
  const potentialScore =
    incomeScoreData?.score?.potentialIncomeScore ||
    spendingScoreData?.score?.potentialSpendingScore;

  const onAddIncomeAndExpense = () => {
    onUpdateFormData({ g_incomeAndExpense: true });
    navigate('/calculator');
  };

  const onAddProductivity = () => {
    onUpdateFormData({ g_productivity: true });
    navigate('/calculator');
  };

  const onAddTaxes = () => {
    onUpdateFormData({ g_taxes: true });
    navigate('/calculator');
  };

  return (
    <Layout isFetching={loadingIncome || loadingSpending}>
      <Scrollable>
        <BackHeader title="My Results" showSave backUrl="/calculator" />

        <ScoreVisualisation
          score={score}
          potentialScore={potentialScore}
          activeTab={activeTab}
          showWhatDoesThisMean
          locked={
            !formData.g_productivity &&
            !formData.g_incomeAndExpense &&
            !formData.g_taxes
          }
        />

        {activeTab === 'income' && (
          <div style={{ margin: '20px 0' }}>
            {formData.g_productivity ||
            formData.g_incomeAndExpense ||
            formData.g_taxes ? (
              <>
                <Button alt to="/results/income">
                  My income
                </Button>
                <Button alt to="/results/expenses">
                  My expenses
                </Button>
              </>
            ) : (
              <>
                <LockedButton onClick={onAddIncomeAndExpense}>
                  My income
                </LockedButton>
                <LockedButton onClick={onAddIncomeAndExpense}>
                  My expenses
                </LockedButton>
              </>
            )}
            {formData.g_productivity ? (
              <Button alt to="/results/my-time">
                My time
              </Button>
            ) : (
              <LockedButton onClick={onAddProductivity}>My time</LockedButton>
            )}

            {formData.g_productivity ||
            formData.g_incomeAndExpense ||
            formData.g_taxes ? (
              <>
                <Button alt to="/results/taxes">
                  Taxes
                </Button>
                <Button alt to="/results/improve-my-wellness">
                  How can I improve?
                </Button>
              </>
            ) : (
              <>
                <LockedButton onClick={onAddTaxes}>Taxes</LockedButton>
                <LockedButton onClick={onAddProductivity}>
                  How can I improve?
                </LockedButton>
              </>
            )}
          </div>
        )}

        {activeTab === 'spendingHabits' && (
          <div style={{ margin: '20px 0' }}>
            <Button alt to="/results/subscriptions">
              Subscriptions
            </Button>
            <Button alt to="/results/spending-habits">
              My spending habits
            </Button>
            <Button alt to="/results/high-risk-transactions">
              High risk transactions
            </Button>

            {formData.g_productivity ||
            formData.g_incomeAndExpense ||
            formData.g_taxes ? (
              <>
                <Button alt to="/results/improve-my-score">
                  How can I improve?
                </Button>
              </>
            ) : (
              <>
                <LockedButton onClick={onAddProductivity}>
                  High risk transactions
                </LockedButton>
              </>
            )}
          </div>
        )}

        <div style={{ textAlign: 'center' }}>
          <TextLink secondary small center to="/calculator">
            Edit answers
          </TextLink>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button small to="/coaching" marginTop inlineBlock autoHeight>
            Discuss your results with a financial coach
          </Button>
        </div>
      </Scrollable>

      <ResultsTabs setActiveTab={setActiveTab} activeTab={activeTab} />
    </Layout>
  );
};

export default Results;
