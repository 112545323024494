export const helpArticles = {
  1: {
    id: 'incomeChangeMonthOverMonth',
    title: 'Spread your income out more evenly.',
    description1: 'We noticed your income has been fluctuating lately.',
    description2: "Here's some content to help you manage irregular earnings.",
    articleTitle: 'How to budget on an irregular income',
    articleLink:
      'https://www.moneyhelper.org.uk/en/everyday-money/budgeting/how-to-budget-for-an-irregular-income',
    videoTitle: 'How to budget on an irregular income',
    videoLink: 'https://www.youtube.com/watch?v=Q-2LRAk1n5U',
  },
  2: {
    id: 'nonBusinessIncomePercentage',
    title: 'Keep non-sustainable borrowing to a minimum.',
    description1:
      "We noticed you've been getting income from sources outside work.",
    description2: 'You might find this content helpful.',
    articleTitle: 'Should you borrow money from friends and family?',
    articleLink:
      'https://www.moneyhelper.org.uk/en/everyday-money/credit-and-purchases/should-you-borrow-from-family-or-friends?source=mas?utm_campaign=webfeeds',
    videoTitle:
      'Things to consider when borrowing money from friends and family',
    videoLink: 'https://www.youtube.com/watch?v=WNB1ODx5CDw',
  },
  3: {
    id: 'minOfBusinessIncomeByJobPercentage',
    title: 'Maximise your earnings.',
    description1:
      "We noticed you have jobs that aren't contributing a lot to your overall income.",
    description2: 'You might find this content helpful.',
    articleTitle: 'Time management tips - how to optimise your time and work',
    articleLink: 'https://www.proschoolonline.com/blog/time-management-tips',
    videoTitle: 'How I Manage My Time - 10 Time Management Tips',
    videoLink: 'https://www.youtube.com/watch?v=iONDebHX9qk',
  },

  5: {
    id: 'phoneBillAsAPercentageOfAverage',
    title: 'Lower your phone bill.',
    description1: 'We noticed your phone bill is higher than average.',
    description2: "Here's some content to help you cut down phone costs.",
    articleTitle: 'Tips for reducing unnecessary phone costs',
    articleLink:
      'https://www.moneysavingexpert.com/mobiles/mobile-phone-cost-cutting/',
    videoTitle: 'Tips to reduce phone bill - what to say to reduce costs',
    videoLink: 'https://www.youtube.com/watch?v=VRHK-Ub1DTs',
  },
  6: {
    id: 'vehicleInsuranceMoreThanAverageOrZero',
    title: 'Lower your vehicle running costs.',
    description1:
      "We noticed you're paying more than average for vehicle insurance.",
    description2: 'You might find this content helpful.',
    articleTitle: 'Compare car insurance quotes',
    articleLink: 'https://www.gocompare.com/car-insurance/',
    videoTitle: 'Top tips for the best car insurance deals',
    videoLink: 'https://www.facebook.com/watch/?v=196259745035884&ref=sharing',
  },
  7: {
    id: 'accountantSoftwareMoreThanAverageOrZero',
    title: 'Keep your accountancy costs down.',
    description1: "It's easy to end up overpaying for accountancy help.",
    description2: "Here's some content to help you manage your taxes.",
    articleTitle: 'Congestion charges and discounts',
    articleLink:
      'https://www.simplybusiness.co.uk/knowledge/articles/2017/01/should-I-hire-an-accountant-5-benefits-of-having-an-accountant/',
    videoTitle: '7 reasons you need an accountant',
    videoLink: 'https://www.youtube.com/watch?v=pI6YxCXRob4',
  },
  8: {
    id: 'dvlaCosts',
    title: 'Lower your DVLA fees.',
    description1: 'We noticed your DVLA fees are higher than average.',
    description2: 'You might find this content helpful.',
    articleTitle: 'Discounts and exemptions in the Congestion charge zone',
    articleLink:
      'https://tfl.gov.uk/modes/driving/congestion-charge/discounts-and-exemptions',
    videoTitle: 'How to appeal against a parking fine',
    videoLink: 'https://www.youtube.com/watch?v=ENH1JpY7-4Y',
  },
  9: {
    id: 'hourlyNetIncomeDifference',
    title: 'Increase your overall hourly wage.',
    description1:
      'We noticed some of your jobs pay quite a bit more than others. ',
    description2: 'You might find this content helpful.',
    articleTitle: 'How to choose between two jobs',
    articleLink:
      'https://www.monster.com/career-advice/article/how-to-choose-between-two-jobs-0517',
  },
  10: {
    id: 'maxNonEarningTimePercentage',
    title: 'Cut down non-earning time between jobs.',
    description1:
      'We noticed some of your jobs have a high percentage of non-earning time.',
    description2: 'You might find this content helpful.',
    articleTitle:
      '5 Ways to Earn More Money as a Courier or Delivery Driver/Rider',
    articleLink:
      'https://www.nimblefins.co.uk/business-insurance/courier-insurance/5-ways-to-earn-more',
    videoTitle:
      'Running multiple food delivery apps: A strategy to maximise earnings',
    videoLink: 'https://www.youtube.com/watch?v=tqFEFBbo0g8',
  },

  11: {
    id: 'subscriptionPercentage',
    title: 'Cut down your subscription costs.',
    description1:
      'We think there might be ways you can lower your subscription costs.',
    description2: 'You might find this content helpful.',
    articleTitle: 'Why you should cut your subscription costs',
    articleLink:
      'https://likelyloans.com/news/why-you-should-cancel-your-subscription-costs',
  },
  12: {
    id: 'netSaverOrBorrower',
    title: 'Save more money.',
    description1: "We've noticed some savings opportunities for you.",
    description2: 'You might find this content helpful.',
    articleTitle: 'Tips to save money on your gas and electricity',
    articleLink:
      'https://www.moneysupermarket.com/gas-and-electricity/energy-saving-tips/',
    videoTitle: 'How to create short term saving goals',
    videoLink: 'https://www.youtube.com/watch?v=sUFBwozU4rc',
  },
  14: {
    id: 'totalPensionPaymentsLastSixMonths',
    title: 'Improve your pension.',
    description1:
      "We noticed there's more you could be doing to maximise your pension.",
    description2: 'You might find this content helpful.',
    articleTitle: 'Best pension plans for self-employed ',
    articleLink:
      'https://www.moneyhelper.org.uk/en/pensions-and-retirement/pensions-basics/pensions-for-self-employed-people',
    videoTitle: 'Pension options for the self-employed',
    videoLink: 'https://www.youtube.com/watch?v=AsbTQKTp89U',
  },

  15: {
    id: 'totalGamblingTransactionsLast6Months',
    title: 'Reduce your gambling spend.',
    description1: 'We noticed gambling transactions.',
    description2: 'You might find this content helpful.',
    articleTitle: 'Tips for safer gambling',
    articleLink: 'https://www.begambleaware.org/advice-for-safer-gambling',
    videoTitle: 'How to stop gambling: The 6 Stages of Change',
    videoLink: 'https://www.youtube.com/watch?v=1QnqKvxJtKE',
  },
  16: {
    id: 'totalBadFinancialServicesTransactions',
    title: 'Move away from relying on your overdraft.',
    description1: "We noticed you've made multiple overdraft payments.",
    description2: 'You might find this content helpful.',
    articleTitle: 'Where to start with paying off overdraft',
    articleLink:
      'https://www.thetimes.co.uk/money-mentor/article/how-to-pay-off-overdraft/',
    videoTitle: 'Tips to get out of your overdraft',
    videoLink: 'https://www.youtube.com/watch?v=qaJgN9kWQK0',
  },
  17: {
    id: 'averageDeliveryOrFastFoodPerWeek',
    title: 'Cut down your fast-food spend.',
    description1:
      'We noticed a higher than average number of fast food transactions.',
    description2: 'You might find this content helpful.',
    articleTitle: 'Supermarket shoppping tips to reduce your food bill',
    articleLink:
      'https://www.moneysavingexpert.com/shopping/cheap-supermarket-shopping/',
    videoTitle: 'Where to begin with meal prepping',
    videoLink: 'https://www.youtube.com/watch?v=r1OSDnCDoGQ',
  },
  18: {
    id: 'averageForeignTransactionsPerMonth',
    title: 'Learn how to spend smarter abroad.',
    description1:
      'We noticed a higher than average number of foreign transactions.',
    description2: 'You might find this content helpful.',
    articleTitle: "Spending while you're abroad: All you need to know",
    articleLink:
      'https://www.forbes.com/uk/advisor/credit-cards/spending-while-youre-abroad/',
    videoTitle: "3 do's and dont's for international payments",
    videoLink: 'https://www.youtube.com/watch?v=jmtm9sTzFj0',
  },
};
