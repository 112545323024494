import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';

import useCalculator from '../../hooks/useCalculator';

const TravelTime = (props) => {
  const { onFormChange, formData } = useCalculator(props);

  return (
    <Card
      subTitle="Select one answer"
      title="How do you calculate the hours you work?"
    >
      <CalculatorForm
        fields={[
          {
            id: 'travelTime',
            name: 'travelTime',
            type: 'radio',
            label: 'How do you calculate the hours you work?',
            visuallyHidden: true,
            options: [
              {
                id: 'From when I leave the house to when I get home',
                value: 'TRUE',
                label: 'From when I leave the house to when I get home',
              },
              {
                id: 'From when I get my first job until the end of my last job',
                value: 'FALSE',
                label:
                  'From when I get my first job until the end of my last job',
              },
            ],
          },
        ]}
        values={formData}
        onChange={onFormChange}
      />
    </Card>
  );
};

export default TravelTime;
