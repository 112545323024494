import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import Button from '../Button';

const useStyles = makeStyles(() => ({
  card: {
    background: '#ffffff',
    width: '100%',
    margin: '10px 0',
    textAlign: 'left',
    padding: '24px',
    position: 'relative',
    color: '#343858',
    border: '1px solid #E5E5E5',
    boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.05)',
    borderRadius: '16px',
  },
  complete: {
    background: '#E3F3EB',
    borderColor: '#53A467',
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  disabled: {
    opacity: 0.2,
    pointerEvents: 'none',
  },
  title: {
    fontWeight: '500',
    fontSize: '16px',
    margin: '0',
  },
  marginBottom: {
    marginBottom: '50px',
  },
}));

const SectionItem = ({ name, to, complete, disabled, marginBottom }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.card, {
        [classes.complete]: complete,
        [classes.marginBottom]: marginBottom,
      })}
    >
      <div
        className={clsx(classes.inner, {
          [classes.disabled]: disabled,
        })}
      >
        <h3 className={classes.title}>{name}</h3>

        <Button to={to} small green={complete}>
          {complete ? 'Edit' : 'Start'}
        </Button>
      </div>
    </div>
  );
};

SectionItem.propTypes = {
  name: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  complete: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  marginBottom: PropTypes.bool,
};

SectionItem.defaultProps = {
  marginBottom: false,
};

export default SectionItem;
