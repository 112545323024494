import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import useLocalStorageState from 'use-local-storage-state';

import { makeStyles } from '@material-ui/core/styles';

import Button from '../Button';
import SelectFieldAlt from '../SelectFieldAlt';

// import { ReactComponent as MenuIcon } from '../../assets/menu.svg';
import { ReactComponent as Chevron } from '../../assets/chevron-small.svg';

const useStyles = makeStyles((theme) => ({
  header: {
    color: '#343858',
    marginBottom: 30,
  },
  inner: {
    display: 'flex',
    // justifyContent: 'space-between',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginBottom: 20,

    '& svg': {
      display: 'block',
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',

    '& > button, & > a': {
      marginLeft: '10px',
    },
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
  },
  subTitle: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 18,
    marginTop: -14,
  },
  titleWrapper: {
    padding: '0 70px',
    position: 'relative',
  },
  backButton: {
    position: 'absolute',
    left: 0,
    top: 0,
    fontWeight: 600,
    fontSize: '14px',
    textDecoration: 'none',
    color: '#343858',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      marginRight: 6,
    },
  },
}));

const ResultsHeader = ({
  title,
  subTitle,
  refetchFunction,
  hideDateRangeSelector,
}) => {
  const classes = useStyles();

  const [dateRange, setDateRange] = useLocalStorageState('dateRange', '3');

  const handleDateRangeChange = (e) => {
    setDateRange(e.target.value);
    if (refetchFunction) refetchFunction();
  };

  return (
    <header className={classes.header}>
      <div className={classes.inner}>
        <div className={classes.buttons}>
          <Button secondary small to="/help">
            Help
          </Button>

          <Button to="/save-and-exit" small>
            Save & Exit
          </Button>
        </div>
      </div>

      {!!title && (
        <div className={classes.titleWrapper}>
          <Link to="/results" className={classes.backButton}>
            <Chevron />
            Back
          </Link>
          <h2 className={classes.title}>{title}</h2>
          {!!subTitle && <h3 className={classes.subTitle}>{subTitle}</h3>}
        </div>
      )}

      {!hideDateRangeSelector && (
        <SelectFieldAlt
          name="dateRange"
          id="dateRange"
          values={{ dateRange }}
          onChange={handleDateRangeChange}
          options={[
            {
              id: '1',
              label: 'The last month',
            },
            {
              id: '3',
              label: 'Last 3 months',
            },
            {
              id: '6',
              label: 'Last 6 months',
            },
            {
              id: '12',
              label: 'The last year',
            },
          ]}
        />
      )}
    </header>
  );
};

ResultsHeader.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  refetchFunction: PropTypes.string,
  hideDateRangeSelector: PropTypes.string,
};

ResultsHeader.defaultProps = {
  title: null,
  refetchFunction: undefined,
  subTitle: null,
  hideDateRangeSelector: false,
};

export default ResultsHeader;
