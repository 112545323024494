import React from 'react';
import { Link } from '@reach/router';

import Card from '../Card';

const Icon = () => (
  <svg
    width="52"
    height="52"
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.773 50.613C51.0909 50.93 51.0913 51.4448 50.7739 51.7622C50.4565 52.0796 49.9417 52.0792 49.6248 51.7613L34.0018 36.0915C30.1111 39.6938 24.9625 41.626 19.6624 41.473C14.3623 41.32 9.33379 39.094 5.65734 35.2733C1.98088 31.4526 -0.0500641 26.3422 0.00093786 21.0401C0.0519398 15.7381 2.18082 10.6676 5.93009 6.91837C9.67937 3.1691 14.7498 1.04022 20.0518 0.989219C25.3539 0.938217 30.4643 2.96916 34.285 6.64562C38.1058 10.3221 40.3318 15.3506 40.4848 20.6507C40.6377 25.9508 38.7055 31.0994 35.1032 34.9901L50.773 50.613ZM9.88558 36.7101C12.9488 38.7568 16.5501 39.8493 20.2341 39.8493C25.1743 39.8493 29.9121 37.8868 33.4053 34.3936C36.8985 30.9004 38.861 26.1625 38.861 21.2224C38.861 17.5383 37.7685 13.937 35.7218 10.8739C33.675 7.81069 30.7659 5.42324 27.3623 4.01341C23.9587 2.60359 20.2134 2.23471 16.6002 2.95344C12.9869 3.67216 9.66794 5.4462 7.06293 8.05121C4.45791 10.6562 2.68388 13.9752 1.96516 17.5885C1.24643 21.2017 1.61531 24.947 3.02513 28.3506C4.43495 31.7542 6.82241 34.6633 9.88558 36.7101ZM20.2469 7.46667C19.7996 7.46667 19.4371 7.82926 19.4371 8.27654C19.4371 8.72381 19.7996 9.0864 20.2469 9.0864C26.9561 9.0864 32.3949 14.5252 32.3949 21.2344C32.3949 21.6816 32.7575 22.0442 33.2047 22.0442C33.652 22.0442 34.0146 21.6816 34.0146 21.2344C34.0146 13.6307 27.8506 7.46667 20.2469 7.46667Z"
      fill="#4797D2"
    />
  </svg>
);

const IncomeAndExpense = () => {
  return (
    <Card icon={Icon} title="Understanding your money" center>
      <p>
        View useful insights about your income and spending habits after
        importing your bank transactions using Open Banking.
      </p>
      <p>
        Access granted is view-only, we can never change anything in your
        account.
      </p>

      <p>
        <Link to="/open-banking">Read about Open Banking</Link>
      </p>
    </Card>
  );
};

export default IncomeAndExpense;
