import React from 'react';
import PropTypes from 'prop-types';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip } from 'recharts';

import { makeStyles } from '@material-ui/core/styles';

import Card from '../Card';

import { isArrayWithLenth } from '../../utils';

import { baseColors } from '../../theme/base';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '240px',
    margin: '30px auto',
    '& path': {
      stroke: 'none',
    },

    '& h6': {
      fontSize: '10px',
      fontWeight: 500,
      letterSpacing: '0.05em',
      textTransform: 'uppercase',
      color: '#343858',
      margin: '15px 0 0 0',
    },
  },
  wrapper: {
    position: 'relative',
    height: 120,
  },
  legend: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
    fontSize: '10px',
    lineHeight: '10px',

    '& li': {
      display: 'flex',
      alignItems: 'center',
      margin: '15px 0',

      '&:first-child': {
        marginTop: '10px',
      },
    },

    '& span': {
      display: 'block',
      width: 10,
      height: 10,
      borderRadius: 10,
      marginRight: 10,
      flex: '0 0 10px',
    },
  },
  noDataMessage: {
    position: 'absolute',
    left: '30px',
    right: '30px',
    top: '50%',
    transform: 'translate(0, -100%)',
    textAlign: 'center',
    color: theme.palette.grey[500],
  },
  total: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '12px',
    textAlign: 'center',

    '& span': {
      fontWeight: 400,
      display: 'block',
      fontSize: '10px',
      lineHeight: '10px',
      marginTop: '4px',
    },
  },
}));

const colourMapping = {
  bright: {
    0: baseColors.yellow,
    1: baseColors.pink,
    2: baseColors.purple,
    3: baseColors.green,
    4: baseColors.grey,
  },
};

const TimeAcrossJobsChart = ({
  data,
  noDataMessage,
  theme,
  title,
  total,
  valueKey,
}) => {
  const classes = useStyles();

  if (!isArrayWithLenth(data)) {
    return (
      <Card smallMargin smallVerticalPadding>
        <div className={classes.container}>
          <p className={classes.noDataMessage}>{noDataMessage}</p>
        </div>
      </Card>
    );
  }

  const getColour = (i) => {
    return colourMapping[theme][i] || baseColors.grey;
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart height={100} width={100}>
            <Pie
              data={data}
              labelLine={false}
              dataKey={valueKey}
              innerRadius={40}
              outerRadius={50}
            >
              {data.map((entry, i) => {
                return <Cell key={entry.id} fill={getColour(i)} />;
              })}
            </Pie>
            <Tooltip
              formatter={(value, name) => [`${Math.round(value)} hours`, name]}
            />
          </PieChart>
        </ResponsiveContainer>

        {!!total && (
          <div className={classes.total}>
            {Math.round(total)}
            <span>hours total</span>
          </div>
        )}
      </div>

      {title && <h6>{title}</h6>}
      <ul className={classes.legend}>
        {data.map((entry, i) => {
          return (
            <li key={entry.id}>
              <span style={{ backgroundColor: getColour(i) }} />
              <div>
                <strong>{entry.name}</strong> ({Math.round(entry[valueKey])}{' '}
                hours: {Math.round((entry[valueKey] / total) * 100)}%)
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

TimeAcrossJobsChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  noDataMessage: PropTypes.string,
  theme: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.number,
  valueKey: PropTypes.string,
};

TimeAcrossJobsChart.defaultProps = {
  noDataMessage: 'No data',
  theme: 'bright',
  title: null,
  total: null,
  valueKey: 'value',
};

export default TimeAcrossJobsChart;
