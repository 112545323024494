import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Radio from '@material-ui/core/Radio';

import oneIcon from '../../assets/1.png';
import twoIcon from '../../assets/2.png';
import threeIcon from '../../assets/3.png';
import fourIcon from '../../assets/4.png';

const useStyles = makeStyles({
  label: {
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '16px',
    margin: '0 30px',
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '30px',
  },
  radio: {
    width: '25%',
    flex: '0 0 25%',
    padding: 0,
    margin: 0,
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',

    '& .MuiTypography-body1': {
      fontSize: '10px',
    },
  },

  radioNotActive: {
    opacity: 0.3,
  },

  radioActive: {
    opacity: 1,
  },

  radioIcon: {
    display: 'none',
  },
  icon: {
    width: '100px',
    height: '100px',
  },
  noBottomMargin: {
    marginBottom: '0',
  },
});

const RatingField = ({
  id,
  name,
  disabled,
  readOnly,
  values,
  onChange,
  label,
  ratingValues,
  hideLabels,
  noBottomMargin,
}) => {
  const classes = useStyles();
  const value = values[name];

  return (
    <>
      <p className={classes.label}>{label}</p>
      <FormControl
        fullWidth
        className={clsx(classes.container, {
          [classes.noBottomMargin]: noBottomMargin,
        })}
      >
        <FormControlLabel
          key={id}
          id={id}
          name={name}
          value={ratingValues[1]}
          control={
            <>
              <img src={oneIcon} alt="1" className={classes.icon} />

              <Radio
                checked={value === ratingValues[1]}
                id={id}
                name={name}
                onChange={onChange}
                value={ratingValues[1]}
                className={classes.radioIcon}
              />
            </>
          }
          label={!hideLabels && ratingValues[1]}
          labelPlacement="center"
          className={clsx(classes.radio, {
            [classes.radioNotActive]: !!value,
            [classes.radioActive]: value === ratingValues[1],
          })}
          disabled={disabled || readOnly}
        />

        <FormControlLabel
          key={id}
          id={id}
          name={name}
          value={ratingValues[2]}
          control={
            <>
              <img src={twoIcon} alt="2" className={classes.icon} />

              <Radio
                checked={value === ratingValues[2]}
                id={id}
                name={name}
                onChange={onChange}
                value={ratingValues[2]}
                className={classes.radioIcon}
              />
            </>
          }
          label={!hideLabels && ratingValues[2]}
          labelPlacement="center"
          className={clsx(classes.radio, {
            [classes.radioNotActive]: !!value,
            [classes.radioActive]: value === ratingValues[2],
          })}
          disabled={disabled || readOnly}
        />

        <FormControlLabel
          key={id}
          id={id}
          name={name}
          value={ratingValues[3]}
          control={
            <>
              <img src={threeIcon} alt="3" className={classes.icon} />

              <Radio
                checked={value === ratingValues[3]}
                id={id}
                name={name}
                onChange={onChange}
                value={ratingValues[3]}
                className={classes.radioIcon}
              />
            </>
          }
          label={!hideLabels && ratingValues[3]}
          labelPlacement="center"
          className={clsx(classes.radio, {
            [classes.radioNotActive]: !!value,
            [classes.radioActive]: value === ratingValues[3],
          })}
          disabled={disabled || readOnly}
        />

        <FormControlLabel
          key={id}
          id={id}
          name={name}
          value={ratingValues[4]}
          control={
            <>
              <img src={fourIcon} alt="4" className={classes.icon} />

              <Radio
                checked={value === ratingValues[4]}
                id={id}
                name={name}
                onChange={onChange}
                value={ratingValues[4]}
                className={classes.radioIcon}
              />
            </>
          }
          label={!hideLabels && ratingValues[4]}
          labelPlacement="center"
          className={clsx(classes.radio, {
            [classes.radioNotActive]: !!value,
            [classes.radioActive]: value === ratingValues[4],
          })}
          disabled={disabled || readOnly}
        />
      </FormControl>
    </>
  );
};

RatingField.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  values: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  ratingValues: PropTypes.shape({
    1: PropTypes.string.isRequired,
    2: PropTypes.string.isRequired,
    3: PropTypes.string.isRequired,
    4: PropTypes.string.isRequired,
  }),
  hideLabels: PropTypes.bool,
  noBottomMargin: PropTypes.bool,
};

RatingField.defaultProps = {
  disabled: false,
  readOnly: false,
  ratingValues: {
    1: '1',
    2: '2',
    3: '3',
    4: '4',
  },
  hideLabels: false,
  noBottomMargin: false,
};

export default RatingField;
