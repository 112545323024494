import React from 'react';

import Card from '../Card';

const Icon = () => (
  <svg
    width="59"
    height="51"
    viewBox="0 0 59 51"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M55.4541 42.8689C55.4541 45.4134 53.3597 47.4831 50.7867 47.4831H8.21326C5.64024 47.4831 3.54586 45.4134 3.54586 42.8689V8.13112C3.54586 5.58657 5.64024 3.51686 8.21326 3.51686H50.7867C53.3597 3.51686 55.4541 5.58657 55.4541 8.13112V42.8689ZM50.7867 1H8.21326C4.23663 1 1 4.19893 1 8.13112V42.8689C1 46.8011 4.23663 50 8.21326 50H50.7867C54.7642 50 58 46.8011 58 42.8689V8.13112C58 4.19893 54.7642 1 50.7867 1ZM9.70088 24.4479C12.5497 25.9371 15.6276 26.6922 18.6453 26.6922C20.7363 26.6922 22.7993 26.3297 24.7359 25.5965C28.1125 24.3188 30.7313 21.9412 33.5046 19.4251C37.5661 15.7379 41.7668 11.9249 48.7925 11.4441C49.4943 11.3963 50.0238 10.7956 49.9746 10.1018C49.9262 9.40884 49.3195 8.89037 48.6168 8.93316C40.7018 9.47428 35.9631 13.7756 31.7819 17.571C29.1936 19.9201 26.7488 22.14 23.8253 23.2457C19.7791 24.7777 15.0642 24.4043 10.8906 22.223C10.2695 21.8975 9.49976 22.1324 9.1705 22.7474C8.84208 23.3615 9.0797 24.1233 9.70088 24.4479ZM48.7051 15.5039C48.0024 15.5039 47.4322 16.0668 47.4322 16.7623V39.5525H40.375V19.3823C40.375 18.6877 39.8056 18.1239 39.1021 18.1239C38.3995 18.1239 37.8292 18.6877 37.8292 19.3823V39.5525H30.7729V27.5974C30.7729 26.9019 30.2027 26.339 29.5 26.339C28.7973 26.339 28.2271 26.9019 28.2271 27.5974V39.5525H21.1708V31.1973C21.1708 30.5027 20.6005 29.9389 19.8979 29.9389C19.1944 29.9389 18.625 30.5027 18.625 31.1973V39.5525H11.5687V29.9087C11.5687 29.2132 10.9984 28.6503 10.2958 28.6503C9.59311 28.6503 9.02284 29.2132 9.02284 29.9087V40.8109C9.02284 41.5056 9.59311 42.0694 10.2958 42.0694H48.7051C49.4077 42.0694 49.978 41.5056 49.978 40.8109V16.7623C49.978 16.0668 49.4077 15.5039 48.7051 15.5039Z"
      fill="#4797D2"
      stroke="white"
    />
  </svg>
);

const TransactionsReady = () => {
  return (
    <Card
      icon={Icon}
      title="Your bank transactions are in ✅"
      text="Now you can easily tag transactions to the job they relate to. This means we can work out useful insights about your income and spending habits."
      center
    >
      <p>
        We’ve already grouped similar transactions for you, so you can tag
        multiple transactions in one go.
      </p>
    </Card>
  );
};

export default TransactionsReady;
