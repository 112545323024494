import React, { useContext } from 'react';

import Layout from '../../components/Layout';
import Button from '../../components/Button';
import Card from '../../components/Card';

import CalculatorHeader from '../../components/CalculatorHeader';

import useTrackPage from '../../hooks/useTrackPage';

import { CalculatorFormContext } from '../../providers/calculator';

const Icon = () => (
  <svg
    width="59"
    height="59"
    viewBox="0 0 59 59"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.5 59C13.239 59 0 45.761 0 29.5C0 13.239 13.239 0 29.5 0C45.761 0 59 13.239 59 29.5C59 45.761 45.761 59 29.5 59ZM29.5 1.43902C14.0065 1.43902 1.43902 14.0065 1.43902 29.5C1.43902 44.9935 14.0065 57.561 29.5 57.561C44.9935 57.561 57.561 44.9935 57.561 29.5C57.561 14.0065 44.9935 1.43902 29.5 1.43902Z"
      fill="#4797D2"
    />
    <path
      d="M15.5413 37.606H10.8885C10.5047 37.606 10.1689 37.2703 10.1689 36.8865V31.2743C10.1689 30.8906 10.5047 30.5548 10.8885 30.5548H15.5413C15.925 30.5548 16.2608 30.8906 16.2608 31.2743V36.8865C16.2608 37.2703 15.925 37.606 15.5413 37.606ZM11.608 36.167H14.8218V31.9938H11.608V36.167Z"
      fill="#4797D2"
    />
    <path
      d="M48.1114 37.606H41.9236C41.5399 37.606 41.2041 37.2703 41.2041 36.8865V31.2743C41.2041 30.8906 41.5399 30.5548 41.9236 30.5548H48.1114C48.4952 30.5548 48.8309 30.8906 48.8309 31.2743V36.8865C48.8309 37.2703 48.5431 37.606 48.1114 37.606ZM42.6431 36.167H47.3919V31.9938H42.6431V36.167Z"
      fill="#4797D2"
    />
    <path
      d="M15.6374 48.8301H10.8406C10.4569 48.8301 10.1211 48.4943 10.1211 48.1106V42.5464C10.1211 42.1626 10.4569 41.8268 10.8406 41.8268H15.6374C16.0211 41.8268 16.3569 42.1626 16.3569 42.5464V48.1586C16.3569 48.5423 16.0211 48.8301 15.6374 48.8301ZM11.6081 47.3911H14.9658V43.2179H11.6081C11.6081 43.2659 11.6081 47.3911 11.6081 47.3911Z"
      fill="#4797D2"
    />
    <path
      d="M33.145 48.8301H24.367C23.9832 48.8301 23.6475 48.4943 23.6475 48.1106V42.5464C23.6475 42.1626 23.9832 41.8268 24.367 41.8268H33.145C33.5288 41.8268 33.8645 42.1626 33.8645 42.5464V48.1586C33.8645 48.5423 33.5288 48.8301 33.145 48.8301ZM25.0865 47.3911H32.4255V43.2179H25.0865C25.0865 43.2659 25.0865 47.3911 25.0865 47.3911Z"
      fill="#4797D2"
    />
    <path
      d="M24.3676 48.8301H15.6375C15.2537 48.8301 14.918 48.4943 14.918 48.1106V42.5464C14.918 42.1626 15.2537 41.8268 15.6375 41.8268H24.3196C24.7033 41.8268 25.0391 42.1626 25.0391 42.5464V48.1586C25.0871 48.5423 24.7513 48.8301 24.3676 48.8301ZM16.357 47.3911H23.6001V43.2179H16.357V47.3911Z"
      fill="#4797D2"
    />
    <path
      d="M42.0193 48.8301H33.1453C32.7616 48.8301 32.4258 48.4943 32.4258 48.1106V42.5464C32.4258 42.1626 32.7616 41.8268 33.1453 41.8268H42.0193C42.403 41.8268 42.7388 42.1626 42.7388 42.5464V48.1586C42.7388 48.5423 42.403 48.8301 42.0193 48.8301ZM33.8648 47.3911H41.2998V43.2179H33.8648V47.3911Z"
      fill="#4797D2"
    />
    <path
      d="M48.1112 48.8302H42.0193C41.6356 48.8302 41.2998 48.4944 41.2998 48.1106V42.4984C41.2998 42.1147 41.6356 41.7789 42.0193 41.7789H48.1592C48.5429 41.7789 48.8787 42.1147 48.8787 42.4984V48.1106C48.8307 48.5423 48.5429 48.8302 48.1112 48.8302ZM42.7388 47.3911H47.4396V43.218H42.7388V47.3911Z"
      fill="#4797D2"
    />
    <path
      d="M37.702 43.2181H28.7801C28.3963 43.2181 28.0605 42.8823 28.0605 42.4986V36.8864C28.0605 36.5026 28.3963 36.1669 28.7801 36.1669C29.1638 36.1669 29.4996 36.5026 29.4996 36.8864V41.7791H36.9345V36.8864C36.9345 36.5026 37.2703 36.1669 37.654 36.1669C38.0378 36.1669 38.3736 36.5026 38.3736 36.8864V42.4986C38.4215 42.8823 38.0857 43.2181 37.702 43.2181Z"
      fill="#4797D2"
    />
    <path
      d="M28.7801 43.2181H19.8582C19.4744 43.2181 19.1387 42.8823 19.1387 42.4986V36.8864C19.1387 36.5026 19.4744 36.1669 19.8582 36.1669C20.2419 36.1669 20.5777 36.5026 20.5777 36.8864V41.7791H28.1086V36.8864C28.1086 36.5026 28.4444 36.1669 28.8281 36.1669C29.2118 36.1669 29.5476 36.5026 29.5476 36.8864V42.4986C29.4996 42.8823 29.2118 43.2181 28.7801 43.2181Z"
      fill="#4797D2"
    />
    <path
      d="M19.8584 43.2181H10.8885C10.5047 43.2181 10.1689 42.8823 10.1689 42.4986V36.8864C10.1689 36.5026 10.5047 36.1669 10.8885 36.1669H19.8584C20.2421 36.1669 20.5779 36.5026 20.5779 36.8864V42.4986C20.5779 42.8823 20.2421 43.2181 19.8584 43.2181ZM11.608 41.7791H19.1389V37.6059H11.608V41.7791Z"
      fill="#4797D2"
    />
    <path
      d="M48.1109 43.2181H37.7019C37.3182 43.2181 36.9824 42.8823 36.9824 42.4986V36.8864C36.9824 36.5026 37.3182 36.1669 37.7019 36.1669H48.1588C48.5426 36.1669 48.8784 36.5026 48.8784 36.8864V42.4986C48.8304 42.8823 48.5426 43.2181 48.1109 43.2181ZM38.4214 41.7791H47.4393V37.6059H38.4214V41.7791Z"
      fill="#4797D2"
    />
    <path
      d="M21.7286 37.606H15.5408C15.1571 37.606 14.8213 37.2703 14.8213 36.8865V31.2743C14.8213 30.8906 15.1571 30.5548 15.5408 30.5548H21.7286C22.1123 30.5548 22.4481 30.8906 22.4481 31.2743C22.4481 31.6581 22.1123 31.9938 21.7286 31.9938H16.2603V36.167H21.7286C22.1123 36.167 22.4481 36.5028 22.4481 36.8865C22.4481 37.2703 22.1123 37.606 21.7286 37.606Z"
      fill="#4797D2"
    />
    <path
      d="M48.1109 31.994H37.2703C36.8866 31.994 36.5508 31.6582 36.5508 31.2745V25.6623C36.5508 25.2785 36.8866 24.9427 37.2703 24.9427H48.1589C48.5427 24.9427 48.8784 25.2785 48.8784 25.6623V31.2745C48.8305 31.7062 48.5427 31.994 48.1109 31.994ZM37.9898 30.5549H47.4394V26.3818H37.9898V30.5549Z"
      fill="#4797D2"
    />
    <path
      d="M41.9233 37.606H37.2224C36.8387 37.606 36.5029 37.2703 36.5029 36.8865C36.5029 36.5028 36.8387 36.167 37.2224 36.167H41.2037V31.9938H37.2704C36.8867 31.9938 36.5509 31.6581 36.5509 31.2743C36.5509 30.8906 36.8867 30.5548 37.2704 30.5548H41.9233C42.307 30.5548 42.6428 30.8906 42.6428 31.2743V36.8865C42.6428 37.2703 42.355 37.606 41.9233 37.606Z"
      fill="#4797D2"
    />
    <path
      d="M21.7291 31.9939H10.8885C10.5047 31.9939 10.1689 31.6581 10.1689 31.2744V25.7102C10.1689 25.3264 10.5047 24.9907 10.8885 24.9907H21.7291C22.1128 24.9907 22.4486 25.3264 22.4486 25.7102C22.4486 26.0939 22.1128 26.4297 21.7291 26.4297H11.608V30.6029H21.7291C22.1128 30.6029 22.4486 30.9386 22.4486 31.3224C22.4486 31.7061 22.1128 31.9939 21.7291 31.9939Z"
      fill="#4797D2"
    />
    <path
      d="M37.2698 37.6066H21.7283C21.3446 37.6066 21.0088 37.2708 21.0088 36.8871V21.9692C21.0088 21.5855 21.3446 21.2497 21.7283 21.2497H37.2698C37.6535 21.2497 37.9893 21.5855 37.9893 21.9692V36.8871C37.9893 37.2708 37.6535 37.6066 37.2698 37.6066ZM22.4478 36.1676H36.5503V22.6887H22.4478V36.1676Z"
      fill="#4797D2"
    />
    <path
      d="M29.4988 34.0105C29.1151 34.0105 28.7793 33.6748 28.7793 33.291V29.6935C28.7793 29.3097 29.1151 28.9739 29.4988 28.9739C29.8825 28.9739 30.2183 29.3097 30.2183 29.6935V33.291C30.2183 33.7227 29.9305 34.0105 29.4988 34.0105Z"
      fill="#4797D2"
    />
    <path
      d="M29.499 30.4595C27.9641 30.4595 26.6689 29.2123 26.6689 27.6774C26.6689 26.1424 27.9161 24.8953 29.499 24.8953C31.034 24.8953 32.3291 26.1424 32.3291 27.6774C32.3291 29.2123 31.034 30.4595 29.499 30.4595ZM29.499 26.2863C28.7315 26.2863 28.108 26.9099 28.108 27.6294C28.108 28.3489 28.7315 28.9725 29.499 28.9725C30.2665 28.9725 30.8901 28.3489 30.8901 27.6294C30.8901 26.9099 30.2665 26.2863 29.499 26.2863Z"
      fill="#4797D2"
    />
    <path
      d="M35.5908 22.6888C35.207 22.6888 34.8712 22.353 34.8712 21.9693V16.9327C34.8712 13.9587 32.4729 11.5604 29.4989 11.5604C26.5249 11.5604 24.1265 13.9587 24.1265 16.9327V21.9693C24.1265 22.353 23.7908 22.6888 23.407 22.6888C23.0233 22.6888 22.6875 22.353 22.6875 21.9693V16.9327C22.6875 13.1913 25.7574 10.1213 29.4989 10.1213C33.2403 10.1213 36.3103 13.1913 36.3103 16.9327V21.9693C36.3103 22.353 36.0225 22.6888 35.5908 22.6888Z"
      fill="#4797D2"
    />
  </svg>
);

const OpenBanking = () => {
  const { formData } = useContext(CalculatorFormContext) || {};

  useTrackPage();

  return (
    <Layout>
      <CalculatorHeader showFCA formData={formData} group="income" backButton />

      <Card title="About Open Banking" center icon={Icon}>
        <p>
          Clarity uses Open Banking to let you securely tag your transactions
          within the platform which, along with your answers, allows us to
          calculate your personalised net hourly wage.
        </p>

        <p>
          <strong>What’s Open Banking?</strong>
        </p>

        <p>
          Open Banking is a secure, regulated system that lets you pull your
          live bank transactions into another app, like Clarity.
        </p>

        <p>
          With Clarity, you can only view your banking activity from our app or
          portal. You can’t pay for anything or change anything in your bank
          account. And we can never action anything in your account, either.
        </p>

        <p>
          Alongside most major banks, some other companies that use Open Banking
          include Compare The Market, Experian, Klarna and PayPal.
        </p>

        <p>
          <a
            href="https://www.openbanking.org.uk/"
            target="_blank"
            rel="noopener noreferrer"
          >
            You can read more about Open Banking here.
          </a>
        </p>
      </Card>

      <div>
        <Button marginBottom onClick={() => window.history.back()}>
          Continue
        </Button>
      </div>
    </Layout>
  );
};

export default OpenBanking;
