import React from 'react';

import Card from '../Card';
import Button from '../Button';
import TimeAcrossJobsChart from '../TimeAcrossJobsChart';
import HelpCard from '../HelpCard';

import useCalculator from '../../hooks/useCalculator';

import calculateTimeAcrossJobs from '../../utils/calculateTimeAcrossJobs';

const TimeAcrossJobs = (props) => {
  const { jobs } = useCalculator(props);

  const { totalTime, jobArray, totalDays } = calculateTimeAcrossJobs(jobs);

  const hoursPerday = totalTime / totalDays;

  return (
    <Card title="Time across jobs">
      <p>
        Here’s a breakdown of how much time you spent on each job in the past
        month. Does this look about right?
      </p>

      <TimeAcrossJobsChart
        title="Industries"
        total={totalTime}
        data={jobArray}
        valueKey="hours"
      />
      {!!(hoursPerday > 14 && hoursPerday < 24) && (
        <HelpCard
          yellow
          marginBottom
          text={`You work an average of ${Math.round(
            hoursPerday,
          )} hours per day, this seems a little high.`}
        />
      )}

      {!!(hoursPerday > 24) && (
        <HelpCard
          red
          marginBottom
          text={`You work an average of ${Math.round(
            hoursPerday,
          )} hours per day, are you sure this is correct?`}
        />
      )}

      {!!(totalDays > 31) && (
        <HelpCard
          red
          marginBottom
          text={`You work an total of ${Math.round(
            totalDays,
          )} days per month, are you sure this is correct?`}
        />
      )}

      <Button small to="/calculator/jobs/4" center>
        Edit time across jobs
      </Button>
    </Card>
  );
};

export default TimeAcrossJobs;
