import React from 'react';

import SuggestedRulesList from '../SuggestedRulesList';

const SuggestedIncomeRules = (props) => {
  return (
    <SuggestedRulesList
      ruleType="INCOME"
      header="Income sources"
      title="You get income from these sources:"
      text=" Tag each one to the right job."
      searchText="Search income"
      {...props}
    />
  );
};

export default SuggestedIncomeRules;
