import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  scrollable: {
    flex: '1',
    overflow: 'auto',
    margin: '-30px',
    padding: '30px',
  },
});

const Scrollable = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.scrollable}>{children}</div>;
};

Scrollable.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Scrollable;
