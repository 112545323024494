import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import CalculatorField from '../CalculatorField';

import { isArrayWithLenth } from '../../utils';

const useStyles = makeStyles({
  form: {
    marginTop: 30,
  },
});

const CalculatorForm = ({ fields, ...props }) => {
  const classes = useStyles();

  return (
    <form className={classes.form}>
      {isArrayWithLenth(fields) &&
        fields.map((field) => (
          <CalculatorField key={field.id} {...props} {...field} />
        ))}
    </form>
  );
};

CalculatorForm.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({})),
};

CalculatorForm.defaultProps = {
  fields: null,
};

export default memo(CalculatorForm);
