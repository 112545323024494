import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';

import useCalculator from '../../hooks/useCalculator';

const JobWaitingTime = (props) => {
  const { onJobFormChange, jobFormData, job } = useCalculator(props);

  return (
    <Card
      header={job.name}
      title="Based on the last month, how much time do you have between each gig on average?"
    >
      <CalculatorForm
        fields={[
          {
            id: 'waitingTimeOnASlowDay',
            name: 'waitingTimeOnASlowDay',
            type: 'smallNumber',
            label: 'On a slow day',
            minValue: 0,
            maxValue: 1000,
            suffix: 'minutes',
          },
          {
            id: 'waitingTimeOnANormalDay',
            name: 'waitingTimeOnANormalDay',
            type: 'smallNumber',
            label: 'On a normal day',
            minValue: 0,
            maxValue: 1000,
            suffix: 'minutes',
          },
          {
            id: 'waitingTimeOnABusyDay',
            name: 'waitingTimeOnABusyDay',
            type: 'smallNumber',
            label: 'On a busy day',
            minValue: 0,
            maxValue: 1000,
            suffix: 'minutes',
          },
        ]}
        values={jobFormData}
        onChange={onJobFormChange}
      />
    </Card>
  );
};

export default JobWaitingTime;
