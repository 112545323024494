import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  container: {
    textAlign: 'center',
    margin: 'auto 0px',
    padding: '10px 0px 40px',

    '& h3': {
      fontSize: '20px',
      lineHeight: '28px',
    },

    '& p': {
      fontSize: '14px',
      lineHeight: '22px',
    },
  },
  noBottomPadding: {
    paddingBottom: '0px',
  },
}));

const IntroText = ({ title, children, noBottomPadding }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.container, {
        [classes.noBottomPadding]: noBottomPadding,
      })}
    >
      <h3>{title}</h3>
      <p>{children}</p>
    </div>
  );
};

IntroText.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  noBottomPadding: PropTypes.bool,
};

IntroText.defaultProps = {
  noBottomPadding: false,
};

export default IntroText;
