import React from 'react';

import Layout from '../../components/Layout';
import Footer from '../../components/Footer';
import FooterBar from '../../components/FooterBar';
import WhatDoYouWantToDoToday from '../../components/WhatDoYouWantToDoToday';

import useTrackPage from '../../hooks/useTrackPage';

const WhatDoYouWantToDoTodayView = () => {
  useTrackPage('/what-do-you-want-to-do-today');

  return (
    <Layout showIllustration showFCALogo>
      <WhatDoYouWantToDoToday />
      <FooterBar>
        <Footer />
      </FooterBar>
    </Layout>
  );
};

export default WhatDoYouWantToDoTodayView;
