import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  quote: {
    fontSize: '14px',
    lineHeight: '22px',
    textAlign: 'center',
    color: ' #343858',
    marginTop: 30,
    marginBottom: 0,

    '& strong': {
      display: 'block',
      margin: '10px auto 0',
    },
  },
});

const Quote = () => {
  const classes = useStyles();

  return (
    <p className={classes.quote}>
      "With this tool I'll be able to pinpoint areas to spend less and save more
      money"
      <strong>Billy, Parcel Delivery, Urb-it!.</strong>
    </p>
  );
};

export default Quote;
