import IncomeIntro from '../components/IncomeIntro';
import ConnectBank from '../components/ConnectBank/ConnectBank';
import SelectIndustries from '../components/SelectIndustries';
import PaymentTypes from '../components/PaymentTypes';
import JobsList from '../components/JobsList';
import SuggestedIncomeRules from '../components/SuggestedIncomeRules';
import Expenses from '../components/Expenses';
import ExpensesVehicle from '../components/ExpensesVehicle';
import ExpensesAccountant from '../components/ExpensesAccountant';
import ExpensesPhone from '../components/ExpensesPhone';
import ExpensesSavings from '../components/ExpensesSavings';
import SuggestedExpenseRules from '../components/SuggestedExpenseRules';
import IncomeAndExpense from '../components/IncomeAndExpense';
import ConnectSuccess from '../components/ConnectSuccess';
import ExpensesIntro from '../components/ExpensesIntro';
import TransactionsReady from '../components/TransactionsReady';
import TravelTime from '../components/TravelTime';
import LunchBreak from '../components/LunchBreak';
import TimeAcrossJobs from '../components/TimeAcrossJobs';
import WorkingHoursIntro from '../components/WorkingHoursIntro';
import QuestionReview from '../components/QuestionReview';

const getCalculatorSteps = (formData, group) => {
  if (group === 'jobs') {
    return [
      {
        id: 'IncomeIntro',
        component: IncomeIntro,
        hideNavigtion: true,
      },
      {
        id: 'SelectIndustries',
        component: SelectIndustries,
        isValid: ({ formData: fd }) => fd?.industries?.length,
      },
      {
        id: 'PaymentTypes',
        component: PaymentTypes,
        hideNavigtion: true,
      },
      {
        id: 'JobsList',
        component: JobsList,
        hideNavigtion: true,
        scrollable: true,
      },
      {
        id: 'TimeAcrossJobs',
        component: TimeAcrossJobs,
      },
    ];
  }

  if (group === 'income') {
    return [
      {
        id: 'IncomeAndExpense',
        component: IncomeAndExpense,
        showFCA: true,
      },
      {
        id: 'ConnectBank',
        component: ConnectBank,
        hideNavigtion: true,
      },
      {
        id: 'ConnectSuccess',
        component: ConnectSuccess,
        showQuote: true,
      },
      ...(formData.g_productivity
        ? [
            {
              id: 'WorkingHoursIntro',
              component: WorkingHoursIntro,
            },
            {
              id: 'TravelTime',
              component: TravelTime,
              isValid: ({ formData: fd }) => !!fd?.travelTime,
            },
            {
              id: 'LunchBreak',
              component: LunchBreak,
              isValid: ({ formData: fd }) =>
                !!fd?.lunchBreakLength || !!fd?.noLunchBreak,
            },
          ]
        : []),
      ...(formData.g_productivity ||
      formData.g_incomeAndExpense ||
      formData.g_taxes
        ? [
            {
              id: 'TransactionsReady',
              component: TransactionsReady,
            },

            {
              id: 'SuggestedIncomeRules',
              component: SuggestedIncomeRules,
              scrollable: true,
              hideNavigtion: true,
            },
            {
              id: `QuestionReview`,
              component: QuestionReview,
              section: 2,
            },
          ]
        : []),
    ];
  }

  if (group === 'expenses') {
    return [
      {
        id: 'ExpensesIntro',
        component: ExpensesIntro,
      },
      ...(formData.g_productivity || formData.g_incomeAndExpense
        ? [
            {
              id: 'Expenses',
              component: Expenses,
              scrollable: true,
            },
          ]
        : []),
      ...(formData?.expenses_workVehicle
        ? [
            {
              id: 'ExpensesVehicle',
              component: ExpensesVehicle,
              isValid: ({ formData: fd }) =>
                !!fd.vehiclePercentageUsedForWork &&
                !!fd.vehicleType?.length &&
                !!fd.vehicleMilesPerMonth,
            },
          ]
        : []),
      ...(formData?.expenses_accountant
        ? [
            {
              id: 'ExpensesAccountant',
              component: ExpensesAccountant,
              isValid: ({ formData: fd }) =>
                !!fd.accountantType && !!fd.accountantExpense,
            },
          ]
        : []),

      ...(formData?.expenses_phone
        ? [
            {
              id: 'ExpensesPhone',
              component: ExpensesPhone,
              isValid: ({ formData: fd }) => !!fd.phoneType,
            },
          ]
        : []),

      ...(formData.g_productivity || formData.g_incomeAndExpense
        ? [
            {
              id: 'ExpensesSavings',
              component: ExpensesSavings,
              isValid: ({ formData: fd }) =>
                !!fd.hasSavings &&
                ((fd.hasSavings === 'yes' && !!fd.savingsAmount) ||
                  (fd.hasSavings === 'no' && !fd.savingsAmount)),
            },
          ]
        : []),

      ...(formData.g_taxes || formData?.expenses_other
        ? [
            {
              id: 'SuggestedExpenseRules',
              component: SuggestedExpenseRules,
              scrollable: true,
              hideNavigtion: true,
            },
          ]
        : []),

      {
        id: `QuestionReview`,
        component: QuestionReview,
        section: 2,
      },
    ];
  }

  return [];
};

export default getCalculatorSteps;
