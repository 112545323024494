export const groups = {
  jobs: {
    label: 'Jobs',
    order: 1,
    totalSteps: 4,
  },
  income: {
    label: 'Income',
    order: 2,
    totalSteps: 3,
  },
  expenses: {
    label: 'Expenses',
    order: 3,
    totalSteps: 5,
  },
};
