import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  footer: {
    color: '#343858',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    margin: '30px -30px -30px',
    backgroundColor: 'rgba(255,255,255,0.3)',
    fontSize: '10px',
    padding: '10px 30px',
    lineHeight: '16px',

    '& a': {
      color: '#343858',
    },
  },
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <p>
        Clarity is a brand owned and operated by Finmo Limited. <br /> Copyright
        © 2017 - 2022
        <br />
        <a
          href="https://www.goclarity.co.uk/terms-and-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>
        {' | '}
        <a
          href="https://www.goclarity.co.uk/privacy-policy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
      </p>
    </div>
  );
};

export default Footer;
