import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@material-ui/core/Grid';

import BankListItem from '../BankListItem';

const BankList = ({ banks, ...props }) => {
  return (
    <Grid container justify="center" spacing={1}>
      {banks?.map(
        (bank) =>
          !!(bank && bank.logo && !bank.logo.includes('Blank')) && (
            <Grid key={bank.id} item xs={6}>
              <BankListItem {...props} {...bank} />
            </Grid>
          ),
      )}
    </Grid>
  );
};

BankList.propTypes = {
  banks: PropTypes.arrayOf(PropTypes.shape({})),
};

BankList.defaultProps = {
  banks: [],
};

export default BankList;
