import React, { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { makeStyles } from '@material-ui/core/styles';

import CalculatorForm from '../CalculatorForm';
import { CalculatorFormContext } from '../../providers/calculator';

import { UPDATE_USER } from '../../graph/auth';

const useStyles = makeStyles({
  wrapper: {
    margin: '60px 0 0 0',
  },
});

const ResultFeedback = ({ section }) => {
  const classes = useStyles();
  const { onFormChange, formData } = useContext(CalculatorFormContext) || {};

  const [handleUpdateUser] = useMutation(UPDATE_USER);

  const handleBlur = () => {
    handleUpdateUser({
      variables: { calculatorMeta: JSON.stringify(formData) },
    });
  };

  const handleChange = (e) => {
    onFormChange(e);

    handleUpdateUser({
      variables: {
        calculatorMeta: JSON.stringify({
          formData,
          [e.target.name]: e.target.value,
        }),
      },
    });
  };

  return (
    <div className={classes.wrapper}>
      <CalculatorForm
        fields={[
          {
            id: `${section}-rating`,
            name: `${section}-Rating`,
            type: 'rating',
            hideLabels: true,
            noBottomMargin: true,
            label: 'How helpful was this section?',
          },
          {
            id: `${section}-feedback`,
            name: `${section}-feedback`,
            type: 'text',
            label: 'Tell us why, if you want to :)',
            visuallyHidden: true,
            placeholder: 'Tell us why, if you want to :)',
            alt: true,
          },
        ]}
        values={formData}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
};

export default ResultFeedback;
