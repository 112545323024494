import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'formik';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginBottom: '20px',
  },
  checkbox: {
    alignItems: 'center',

    '& .MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#4797D2',
    },
  },
  alignStart: {
    alignItems: 'flex-start',
  },
  checkboxLabel: {
    fontSize: '12px',
    color: theme.palette.grey[700],

    '& a': {
      color: theme.palette.grey[700],
    },
  },
}));

const CheckboxField = ({ name, hintText, disabled, label, alignStart }) => {
  const classes = useStyles();

  return (
    <Field
      name={name}
      render={({ field, form: { errors, touched, submitCount } }) => {
        const errorMessage =
          ((touched && touched[field.name]) || submitCount > 0) &&
          errors &&
          errors[field.name];

        return (
          <FormControl
            fullWidth
            error={!!errorMessage}
            className={classes.formControl}
          >
            <FormControlLabel
              classes={{
                root: clsx(classes.checkbox, {
                  [classes.alignStart]: alignStart,
                }),
                label: classes.checkboxLabel,
              }}
              control={<Checkbox {...field} disabled={disabled} />}
              label={label}
            />

            {errorMessage && (
              <FormHelperText error>{errorMessage}</FormHelperText>
            )}

            {hintText && <FormHelperText>{hintText}</FormHelperText>}
          </FormControl>
        );
      }}
    />
  );
};

CheckboxField.propTypes = {
  name: PropTypes.string.isRequired,
  hintText: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  disabled: PropTypes.bool,
  alignStart: PropTypes.bool,
};

CheckboxField.defaultProps = {
  disabled: false,
  hintText: null,
  alignStart: false,
};

export default CheckboxField;
