import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';

import useCalculator from '../../hooks/useCalculator';

import { industries } from '../../config/industries';

const SelectIndustries = (props) => {
  const { onFormChange, formData } = useCalculator(props);

  return (
    <>
      <Card subTitle="Select all industries that apply" title="What do you do?">
        <CalculatorForm
          fields={[
            {
              id: 'industries',
              name: 'industries',
              type: 'groupedCheckboxes',
              options: industries,
              label: 'What do you do?',
              visuallyHidden: true,
            },
          ]}
          values={formData}
          onChange={onFormChange}
        />
      </Card>
    </>
  );
};

export default SelectIndustries;
