import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import * as yup from 'yup';
import PropTypes from 'prop-types';

import Form from '../Form';
import Card from '../Card';

import { validate, requiredString } from '../../utils/validate';

import { COMPLETE_REGISTRATION, UPDATE_USER } from '../../graph/auth';

const saveForm = [
  {
    id: 'name',
    name: 'name',
    type: 'text',
    label: 'Name',
  },
  {
    id: 'email',
    name: 'email',
    type: 'email',
    label: 'Email',
  },
  {
    id: 'password',
    name: 'password',
    type: 'password',
    label: 'Password',
  },
  {
    id: 'agreeTerms',
    name: 'agreeTerms',
    type: 'checkbox',
    label: (
      <>
        I agree to the{' '}
        <a
          href="https://finmo.co.uk/terms-and-conditions/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a>{' '}
        and{' '}
        <a
          href="https://finmo.co.uk/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a>
        .
      </>
    ),
  },
];

const saveFormValidationSchema = validate({
  email: requiredString.email(),
  password: requiredString,
  agreeTerms: yup
    .bool()
    .required(
      'You must accept our terms and privacy policy to register with Finmo.',
    )
    .oneOf(
      [true],
      'You must accept our terms and privacy policy to register with Finmo.',
    ),
});

const saveFormInitialValues = {
  email: '',
  password: '',
};

const SignUpForm = ({ onSuccess, title, text, submitButtonText, children }) => {
  const [handleSave, { loading, error }] = useMutation(COMPLETE_REGISTRATION);

  const [handleUpdateUser] = useMutation(UPDATE_USER);

  const handleSaveSubmit = async ({ variables: { ...variables } }) => {
    try {
      const response = await handleSave({
        variables: {
          ...variables,
          acceptedTermsAt: new Date(),
        },
      });

      if (!response?.data?.completeAnonymousRegistration) return;

      await handleUpdateUser({
        variables: {
          firstName: variables.name.split(' ')[0],
          lastName: variables.name.split(' ')[1],
        },
      });

      onSuccess();
    } catch (err) {
      // eslint-disable-next-line
      console.error(err);
    }
  };

  return (
    <Card title={title} text={text}>
      <Form
        fields={saveForm}
        loading={loading}
        error={error}
        onSubmit={handleSaveSubmit}
        validationSchema={saveFormValidationSchema}
        initialValues={saveFormInitialValues}
        buttonLabel={submitButtonText}
      />

      {children}
    </Card>
  );
};

SignUpForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  children: PropTypes.node,
};

SignUpForm.defaultProps = {
  children: undefined,
};

export default SignUpForm;
