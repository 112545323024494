import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import OutlinedInput from '@material-ui/core/OutlinedInput';

import Label from '../Label';

const useStyles = makeStyles({
  field: {
    marginTop: '8px',
  },
  input: {
    borderRadius: '10px',
  },
  outline: {
    borderWidth: '1px',
    borderColor: '#343858',
    '& legend': {
      display: 'none',
    },
  },
});

const SelectField = ({
  options,
  name,
  disabled,
  readOnly,
  values,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth>
      {/* eslint-disable-next-line */}
      <Label {...props} />

      <NativeSelect
        id={name}
        name={name}
        input={
          <OutlinedInput
            shrink={false}
            classes={{ root: classes.input, notchedOutline: classes.outline }}
            {...props}
          />
        }
        className={classes.field}
        value={`${values[name] || ''}`}
        disabled={disabled}
        readOnly={readOnly}
        {...props}
      >
        <option value="">Select</option>
        {options &&
          options.map(
            ({ id: optionId, value, label: optionLabel, name: optionName }) => (
              <option key={optionId} value={value || optionId}>
                {optionLabel || optionName}
              </option>
            ),
          )}
      </NativeSelect>
    </FormControl>
  );
};

SelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  values: PropTypes.shape({}).isRequired,
};

SelectField.defaultProps = {
  label: null,
  readOnly: false,

  disabled: false,
};

export default SelectField;
