const isJobComplete = ({
  paymentType,
  gigsPerDay,
  daysPerMonth,
  hoursPerDay,
  waitingTimeOnASlowDay,
  waitingTimeOnANormalDay,
  waitingTimeOnABusyDay,
  numberOfShiftsPerDay,
  daysPerWeek,
  payePaymentAmount,
  payePaymentFrequency,
  employmentPaymentType,
}) => {
  if (paymentType === 'gig') {
    return (
      !!employmentPaymentType &&
      !!gigsPerDay &&
      !!daysPerMonth &&
      !!hoursPerDay &&
      (!!waitingTimeOnASlowDay ||
        !!waitingTimeOnANormalDay ||
        !!waitingTimeOnABusyDay)
    );
  }

  if (paymentType === 'shift') {
    return (
      !!employmentPaymentType &&
      !!numberOfShiftsPerDay &&
      !!daysPerMonth &&
      !!hoursPerDay
    );
  }

  if (paymentType === 'dayRate') {
    return !!employmentPaymentType && !!hoursPerDay && !!daysPerMonth;
  }

  if (paymentType === 'PAYE') {
    return (
      !!employmentPaymentType &&
      !!hoursPerDay &&
      !!daysPerWeek &&
      !!payePaymentAmount &&
      !!payePaymentFrequency
    );
  }

  return false;
};

export default isJobComplete;
