import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';

import useCalculator from '../../hooks/useCalculator';

const ExpensesVehicle = (props) => {
  const { onFormChange, formData } = useCalculator(props);

  return (
    <Card
      subTitle="Answer all"
      title="Tell us more about your work vehicle usage"
      text="Do you use any of these for work?"
    >
      <CalculatorForm
        fields={[
          {
            id: 'vehicleType',
            name: 'vehicleType',
            type: 'radio',
            visuallyHidden: true,
            label: 'Do you use any of these for work?',
            options: [
              {
                id: 'Bicycle',
                label: 'Bicycle',
                value: 'Bicycle',
              },
              {
                id: 'Motorbike',
                label: 'Motorbike',
                value: 'Motorbike',
              },
              {
                id: 'Gas car',
                label: 'Gas car',
                value: 'Gas car',
              },
              {
                id: 'Electic car',
                label: 'Electic car',
                value: 'Electic car',
              },
              {
                id: 'Van / truck',
                label: 'Van / truck',
                value: 'Van / truck',
              },
            ],
          },
          {
            id: 'vehicleMilesPerMonth',
            name: 'vehicleMilesPerMonth',
            type: 'number',
            label: 'Roughly how many miles do you drive/ride a month?',
            suffix: 'miles per month',
          },
          {
            id: 'vehiclePercentageUsedForWork',
            name: 'vehiclePercentageUsedForWork',
            type: 'number',
            label: 'How much do you use your vehicle for work?',
            suffix: '% used for work',
          },
        ]}
        values={formData}
        onChange={onFormChange}
      />
    </Card>
  );
};

export default ExpensesVehicle;
