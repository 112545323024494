import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
  wrapperActive: {
    marginBottom: '15px',
  },

  group: {
    width: '100%',
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    borderRadius: '10px',
    minHeight: '50px',
    margin: '5px 0',
    display: 'flex',
    alignItems: 'center',
    color: '#343858',
    padding: '15px 6px 15px 25px',

    '& .MuiTypography-body1': {
      fontWeight: 500,
      fontSize: '16px',
      flex: 1,
    },
  },

  groupActive: {
    padding: '16px 7px 16px 26px',
    background: '#4797D2',
    color: '#ffffff',
    border: 'none',
  },

  groupIcon: {
    margin: '0 15px',
    display: 'block',
    background: '#EBF5FA',
    borderRadius: '100px',
    width: 22,
    height: 22,
    position: 'relative',

    '&:after': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.50638 5.55071L8.64477 2.5262C8.92049 2.26048 8.92049 1.82282 8.64477 1.55711C8.36905 1.29139 7.91491 1.29139 7.63919 1.55711L5.00359 4.08925L2.36799 1.54929C2.09227 1.28357 1.63814 1.28357 1.36241 1.54929C1.22455 1.68215 1.15157 1.8619 1.15157 2.03384C1.15157 2.21359 1.22455 2.38552 1.36241 2.51838L4.5008 5.55071C4.77652 5.81643 5.23066 5.81643 5.50638 5.55071Z' fill='%234797D2' stroke='%234797D2'/%3E%3C/svg%3E%0A")`,
    },

    '& svg': {
      display: 'none',
    },
  },

  groupIconActive: {
    background: '#ffffff',

    '&:after': {
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.54107 1.18117L0.687994 3.93073C0.437335 4.17229 0.437335 4.57016 0.687994 4.81172C0.938652 5.05329 1.3515 5.05329 1.60216 4.81172L3.99816 2.50977L6.39416 4.81883C6.64481 5.06039 7.05766 5.06039 7.30832 4.81883C7.43365 4.69805 7.5 4.53464 7.5 4.37833C7.5 4.21492 7.43365 4.05862 7.30832 3.93783L4.45524 1.18117C4.20458 0.939609 3.79173 0.939609 3.54107 1.18117Z' fill='%23429CDB' stroke='%234797D2'/%3E%3C/svg%3E%0A")`,
    },
  },

  radio: {
    width: '100%',
    background: '#FFFFFF',
    border: '1px solid #E5E5E5',
    borderRadius: '10px',
    minHeight: '50px',
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center',
    color: '#343858',
    padding: '15px 15px 15px 6px',

    '& .MuiTypography-body1': {
      fontWeight: 500,
      fontSize: '16px',
    },
  },

  radioActive: {
    borderColor: '#4797D2',
  },

  radioIcon: {
    margin: '0 15px',
    display: 'block',
    background: '#ffffff',
    border: 'solid 2px #4797D2',
    borderRadius: '100px',
    width: 20,
    height: 20,
    position: 'relative',

    '&:after': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      opacity: 0,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='9' height='8' viewBox='0 0 9 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.73602 0.131241C8.46268 -0.0729121 8.08684 -0.0388861 7.88183 0.233318L2.96169 6.56207L1.01413 4.8608C0.774961 4.62262 0.364949 4.65664 0.159943 4.92885C-0.0792306 5.16703 -0.0450627 5.57533 0.228278 5.77949L2.62001 7.85505C2.75668 7.95712 2.92752 8.02517 3.06419 7.99115C3.2692 7.99115 3.4742 7.9231 3.61088 7.75297L8.90686 0.947855C9.0777 0.743702 9.00936 0.36942 8.73602 0.131241Z' fill='white'/%3E%3C/svg%3E%0A")`,
    },

    '& svg': {
      display: 'none',
    },
  },

  radioIconActive: {
    background: '#4797D2',

    '&:after': {
      opacity: 1,
    },
  },
});

const GroupedCheckbox = ({
  disabled,
  readOnly,
  values,
  onChange,
  name: rootName,
  groupId,
  groupName,
  subIndustries,
}) => {
  const classes = useStyles();
  const groupValues = values[rootName] || [];

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div
      className={clsx(classes.wrapper, {
        [classes.wrapperActive]: isExpanded,
      })}
    >
      <FormControlLabel
        key={groupId}
        id={groupId}
        name={groupName}
        control={
          <Checkbox
            id={groupId}
            name={groupName}
            className={clsx(classes.groupIcon, {
              [classes.groupIconActive]: isExpanded,
            })}
            onClick={handleToggleExpand}
          />
        }
        label={groupName}
        labelPlacement="start"
        disabled={disabled || readOnly}
        className={clsx(classes.group, {
          [classes.groupActive]: isExpanded,
        })}
      />

      {isExpanded &&
        subIndustries &&
        subIndustries.map(({ id, name, ...subIndustry }) => {
          const value = groupValues.find(({ id: i }) => i === id);

          return (
            <FormControlLabel
              key={id}
              id={id}
              name={name}
              value={!values[name]}
              control={
                <Checkbox
                  checked={value}
                  id={id}
                  name={name}
                  onChange={(e) =>
                    onChange({
                      ...e,
                      target: {
                        ...e.target,
                        name: rootName,
                        value: e.target.value
                          ? [...groupValues, { id, name, ...subIndustry }]
                          : groupValues.filter(({ id: i }) => i !== id),
                      },
                    })
                  }
                  value={value ? '' : 'true'}
                  className={clsx(classes.radioIcon, {
                    [classes.radioIconActive]: value,
                  })}
                />
              }
              label={name}
              labelPlacement="end"
              className={clsx(classes.radio, {
                [classes.radioActive]: value,
              })}
              disabled={disabled || readOnly}
            />
          );
        })}
    </div>
  );
};

GroupedCheckbox.propTypes = {
  groupId: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  subIndustries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  values: PropTypes.shape({}).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

GroupedCheckbox.defaultProps = {
  disabled: false,
  readOnly: false,
};

export default GroupedCheckbox;
