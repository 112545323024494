import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import Button from '../../components/Button';
import Quote from '../../components/Quote';
import Footer from '../../components/Footer';
import WelcomeText from '../../components/WelcomeText';
import FooterBar from '../../components/FooterBar';

import { GET_META, IS_AUTHENTICATED } from '../../graph/auth';

const Welcome = ({ partner }) => {
  const {
    data: { isAuthenticated },
  } = useQuery(IS_AUTHENTICATED);

  const { data } = useQuery(GET_META, { skip: !isAuthenticated });

  return (
    <Layout showIllustration showFCALogo>
      <WelcomeText
        partner={partner}
        isLoggedIn={isAuthenticated}
        userName={data?.user?.firstName}
      />

      <Quote />

      <FooterBar>
        {isAuthenticated ? (
          <Button marginBottom to="/calculator">
            Jump back in
          </Button>
        ) : (
          <>
            <Button marginBottom to="/what-do-you-want-to-do-today">
              I’m new here
            </Button>
            <Button secondary to="/login">
              I’m returning
            </Button>
          </>
        )}

        <Footer />
      </FooterBar>
    </Layout>
  );
};

Welcome.propTypes = {
  partner: PropTypes.string,
};

Welcome.defaultProps = {
  partner: null,
};

export default Welcome;
