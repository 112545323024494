import React from 'react';

import Card from '../Card';
import CalculatorForm from '../CalculatorForm';

import useCalculator from '../../hooks/useCalculator';

const JobPaymentType = (props) => {
  const { onJobFormChange, jobFormData, job } = useCalculator(props);

  return (
    <Card header={job.name} title="How do you get paid for this kind of work?">
      <CalculatorForm
        fields={[
          {
            id: 'employmentPaymentType',
            name: 'employmentPaymentType',
            type: 'radio',
            visuallyHidden: true,
            label: 'How do you get paid for this kind of work?',
            options: [
              {
                id: 'Self employed',
                label: 'Self employed',
                value: 'Self employed',
              },
              {
                id: 'PAYE',
                label: 'PAYE',
                value: 'PAYE',
              },
            ],
          },
        ]}
        values={jobFormData}
        onChange={onJobFormChange}
      />
    </Card>
  );
};

export default JobPaymentType;
