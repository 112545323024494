import React, { useState, useContext } from 'react';
import { useQuery, useApolloClient } from '@apollo/react-hooks';
import { navigate } from '@reach/router';

import Layout from '../../components/Layout';

import Card from '../../components/Card';
import BackHeader from '../../components/BackHeader';
import Button from '../../components/Button';
import SignUpForm from '../../components/SignUpForm';

import { GET_META } from '../../graph/auth';

import useTrackPage from '../../hooks/useTrackPage';

import {
  CalculatorFormContext,
  defaultFormValues,
} from '../../providers/calculator';

const Icon = () => (
  <svg
    width="49"
    height="54"
    viewBox="0 0 49 54"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.8155 1.93833C34.8155 1.22742 35.3928 0.651078 36.105 0.651078C36.8171 0.651078 37.3945 1.22742 37.3945 1.93833V9.66184C37.3945 11.7887 35.6566 13.5235 33.526 13.5235H15.4734C13.3428 13.5235 11.605 11.7887 11.605 9.66184V1.93833C11.605 1.22742 12.1823 0.651078 12.8945 0.651078C13.6066 0.651078 14.1839 1.22742 14.1839 1.93833V9.66184C14.1839 10.3669 14.7671 10.949 15.4734 10.949H33.5261C34.2323 10.949 34.8155 10.3669 34.8155 9.66184V1.93833ZM20.6937 39.0034L37.4654 22.2607C37.969 21.7581 38.7854 21.7581 39.289 22.2607C39.4086 22.3795 39.5036 22.5212 39.5684 22.6775C39.6333 22.8337 39.6667 23.0015 39.6667 23.1709C39.6667 23.3404 39.6333 23.5082 39.5684 23.6644C39.5036 23.8207 39.4086 23.9624 39.289 24.0812L21.4986 41.8408C20.9552 42.3833 20.06 42.3338 19.5799 41.7347L11.8868 32.1349C11.4419 31.5798 11.5321 30.7697 12.0882 30.3256C12.6443 29.8815 13.4557 29.9715 13.9006 30.5266L20.6937 39.0034ZM43.8318 2.57445H5.1682C3.71995 2.57445 2.57897 3.7172 2.57897 5.15155V48.8485C2.57897 50.2824 3.72984 51.4255 5.1682 51.4255H43.8318C45.28 51.4255 46.4211 50.2829 46.4211 48.8485V5.15155C46.4211 3.7186 45.2686 2.57445 43.8318 2.57445ZM43.8318 0C46.6897 0 49 2.29354 49 5.1516V48.8484C49 51.7028 46.7063 54 43.8318 54H5.1682C2.30843 54 0 51.7072 0 48.8484V5.1516C0 2.29716 2.29373 0 5.1682 0L43.8318 0Z"
      fill="#4797D2"
    />
  </svg>
);

const Save = () => {
  const client = useApolloClient();

  const { data } = useQuery(GET_META, {
    fetch: 'cache-only',
  });
  useTrackPage('/save-for-later');

  const [showSuccess, setShowSuccess] = useState(!data.user.anonymous);

  const { onReplaceFormData } = useContext(CalculatorFormContext) || {};

  const handleLogOut = async () => {
    await client.clearStore();

    onReplaceFormData(defaultFormValues);

    navigate('/');
  };

  const handleSuccess = () => {
    setShowSuccess(true);
  };

  if (showSuccess) {
    return (
      <Layout>
        <BackHeader title="Back" />
        <Card
          center
          icon={Icon}
          title="Progress saved"
          text="We've saved your answers so you can pick up where you left off anytime. "
        />
        <Button onClick={() => window.history.back()} marginBottom>
          Back to the tool
        </Button>
        <Button marginTop textLink small center onClick={handleLogOut}>
          Log out
        </Button>
      </Layout>
    );
  }

  return (
    <Layout scrollable>
      <BackHeader title="Back" />
      <SignUpForm
        title="Save progress"
        text="To save your answers so far, please log in or create an account. "
        submitButtonText="Save"
        onSuccess={handleSuccess}
      />

      <Button textLink small center onClick={handleLogOut}>
        Log out
      </Button>
    </Layout>
  );
};

export default Save;
