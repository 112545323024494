import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.common.white,
    boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.05)',
    borderRadius: '16px',
    padding: 30,
    overflow: 'auto',
    margin: '30px 0',
    border: '1px solid #E5E5E5',

    '& > svg': {
      display: 'block',
      margin: ' 0 auto 20px',
    },

    '& > ul': {
      listStyle: 'none',
      textAlign: 'left',
      margin: '30px 0 0 0',
      padding: 0,

      '& li': {
        display: 'flex',
        width: '100%',
        padding: 0,
        margin: '20px 0',

        '&:last-child': {
          marginBottom: 0,
        },

        '&:first-child': {
          marginTop: 0,
        },

        '& svg': {
          flex: '0 0 37px',
          marginRight: '25px',
        },
      },
    },
    '& > p a': {
      color: '#4797D2',
    },
  },
  header: {
    margin: '-30px -30px 30px -30px',
    padding: 20,
    backgroundColor: '#4797D2',
    color: '#ffffff',
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: 16,
    letterSpacing: '0.05em',
  },
  subTitle: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    color: '#7D7D7D',
    margin: '0 0 10px 0',
  },
  title: {
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '28px',
    color: '#343858',
    margin: '0 0 10px 0',
  },
  text: {
    fontSize: '14px',
    lineHight: '22px',
    margin: '0 0 10px 0',
  },
  center: {
    textAlign: 'center',
    '& button': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  minHeight: {
    minHeight: '220px',
  },
  noLiFlex: {
    '& > ul': {
      '& li': {
        display: 'block',
      },
    },
  },
  smallMargin: {
    margin: '10px 0',
  },
  noMargin: {
    margin: '0',
  },
  smallVerticalPadding: {
    padding: '15px 30px',
  },
  smallPadding: {
    padding: '20px',
  },
  smallTitle: {
    fontSize: '14px',
    lineHeight: '20px',
  },
  blue: {
    backgroundColor: '#4797D2',
    color: '#ffffff',
    borderColor: '#4797D2',
  },
  overflow: {
    overflow: 'visible',
  },
  white: {
    color: '#ffffff',
  },
  height100: {
    height: '100%',
  },
  flex: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const Card = ({
  children,
  header,
  subTitle,
  title,
  text,
  center,
  icon,
  minHeight,
  noLiFlex,
  smallMargin,
  noMargin,
  smallVerticalPadding,
  smallPadding,
  smallTitle,
  blue,
  overflow,
  height100,
  flex,
}) => {
  const classes = useStyles();
  const Icon = icon;

  return (
    <div
      className={clsx(classes.card, {
        [classes.center]: center,
        [classes.minHeight]: minHeight,
        [classes.noLiFlex]: noLiFlex,
        [classes.smallMargin]: smallMargin,
        [classes.noMargin]: noMargin,
        [classes.smallVerticalPadding]: smallVerticalPadding,
        [classes.smallPadding]: smallPadding,
        [classes.blue]: blue,
        [classes.overflow]: overflow,
        [classes.height100]: height100,
        [classes.flex]: flex,
      })}
    >
      {!!header && <h2 className={classes.header}>{header}</h2>}
      {!!icon && <Icon />}
      {!!subTitle && <p className={classes.subTitle}>{subTitle}</p>}
      {!!title && (
        <h2
          className={clsx(classes.title, {
            [classes.smallTitle]: smallTitle,
            [classes.white]: blue,
          })}
        >
          {title}
        </h2>
      )}
      {!!text && <p className={classes.text}>{text}</p>}

      {children}
    </div>
  );
};

Card.propTypes = {
  children: PropTypes.node,
  header: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  center: PropTypes.bool,
  icon: PropTypes.node,
  minHeight: PropTypes.bool,
  noLiFlex: PropTypes.bool,
  smallMargin: PropTypes.bool,
  smallVerticalPadding: PropTypes.bool,
  smallPadding: PropTypes.bool,
  smallTitle: PropTypes.bool,
  blue: PropTypes.bool,
  overflow: PropTypes.bool,
  noMargin: PropTypes.bool,
  height100: PropTypes.bool,
  flex: PropTypes.bool,
};

Card.defaultProps = {
  header: null,
  subTitle: null,
  title: null,
  text: null,
  children: undefined,
  center: false,
  icon: null,
  minHeight: false,
  noLiFlex: false,
  smallMargin: false,
  smallVerticalPadding: false,
  smallPadding: false,
  smallTitle: false,
  blue: false,
  overflow: false,
  noMargin: false,
  height100: false,
  flex: false,
};

export default Card;
