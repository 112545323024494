import React, { useState } from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';

import Label from '../Label';
import GroupedCheckbox from '../GroupedCheckbox';
import SearchField from '../SearchField';

const GroupedCheckboxField = ({ options, ...props }) => {
  const [searchValue, setSearchValue] = useState('');

  const filteredOptions = searchValue?.length
    ? options.filter((option) => {
        return (
          option.name.includes(searchValue) ||
          option.subIndustries.some((subIndustry) =>
            subIndustry.name.includes(searchValue),
          )
        );
      })
    : options;

  return (
    <FormControl fullWidth>
      {/* eslint-disable-next-line */}
      <Label {...props} />

      <SearchField
        name="search"
        id="search"
        value={searchValue}
        placeholder="Search industries"
        onChange={(e) => setSearchValue(e.target.value)}
      />

      {filteredOptions &&
        filteredOptions.map(
          ({ id: groupId, name: groupName, subIndustries }) => (
            <GroupedCheckbox
              {...props}
              key={groupId}
              groupId={groupId}
              groupName={groupName}
              subIndustries={subIndustries}
            />
          ),
        )}
    </FormControl>
  );
};

GroupedCheckboxField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default GroupedCheckboxField;
