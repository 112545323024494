import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  footerBar: {
    marginTop: 'auto',
    paddingTop: '30px',
  },
  noPadding: {
    paddingTop: '0px',
  },
}));

const FooterBar = ({ children, noPadding }) => {
  const classes = useStyles();

  return (
    <footer
      className={clsx(classes.footerBar, { [classes.noPadding]: noPadding })}
    >
      {children}
    </footer>
  );
};

FooterBar.propTypes = {
  children: PropTypes.node.isRequired,
  noPadding: PropTypes.bool,
};

FooterBar.defaultProps = {
  noPadding: false,
};

export default FooterBar;
