import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  link: {
    fontWeight: '500',
    fontSize: '12px',
    lineHeight: '18px',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: '#4797D2',
    padding: '0px',
    margin: '0px',
    background: 'none',
    border: 'none',

    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

const TextLink = ({ children, to, ...props }) => {
  const classes = useStyles();

  if (to) {
    return (
      <Link className={classes.link} to={to} {...props}>
        {children}
      </Link>
    );
  }

  return (
    <a className={classes.link} {...props}>
      {children}
    </a>
  );
};

TextLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
};

TextLink.defaultProps = {
  children: undefined,
  to: undefined,
};

export default TextLink;
