/**
 * @format
 * @flow
 */

import gql from 'graphql-tag';

export const ACCOUNT = gql`
  fragment AccountData on AccountType {
    id
    currentBalance
    bank {
      id
      name
      icon
      logo
      tint
      truelayerProvider
    }
    lastFourAcctNum
    name
    isBusiness
    isIncludedInBalance
    accountType
    needsSetup
    needsAuth
    rule {
      id
      shouldNotify
    }
    tags {
      id
      name
      displayName
      tagType
    }
  }
`;

const GET_ACCOUNTS = gql`
  query GetAccounts($new: Boolean) {
    accounts(new: $new) {
      ...AccountData
    }
  }
  ${ACCOUNT}
`;

const GET_ACCOUNT = gql`
  query GetAccount($accountId: ID!) {
    account(accountId: $accountId) {
      ...AccountData
    }
  }
  ${ACCOUNT}
`;

const UPDATE_ACCOUNT_SETTINGS = gql`
  mutation UpdateAccountSettings(
    $accountId: ID!
    $isIncludedInBalance: Boolean!
  ) {
    updateAccountSettings(
      settings: {
        accountId: $accountId
        isIncludedInBalance: $isIncludedInBalance
      }
    ) {
      ...AccountData
    }
  }
  ${ACCOUNT}
`;

const DISCONNECT_ACCOUNT = gql`
  mutation DisconnectAccount($accountId: ID!) {
    disconnectAccount(accountId: $accountId) {
      success
    }
  }
`;

const TRANSACTION = gql`
  fragment TransactionData on TransactionType {
    id
    transactionType
    description
    currency
    amount
    madeOn
    merchantName
    account {
      id
      name
    }
    tag {
      id
      name
      displayName
      tagType
    }
    job {
      id
      name
      jobType {
        name
        id
      }
    }
    hmrcCategory {
      id
      displayName
    }
    approved
  }
`;

const SEARCH_TRANSACTIONS = gql`
  query SearchTransactions($search: String!, $first: Int, $skip: Int) {
    searchTransactions(search: $search, first: $first, skip: $skip) {
      ...TransactionData
    }
  }
  ${TRANSACTION}
`;

const GET_TRANSACTIONS = gql`
  query GetTransactions(
    $accountId: ID
    $first: Int
    $skip: Int
    $transactionType: String
    $dateFrom: DateTime
    $dateTo: DateTime
    $amountFrom: Float
    $amountTo: Float
    $untagged: Boolean
  ) {
    accounts {
      ...AccountData
    }
    transactionsByDate(
      accountId: $accountId
      first: $first
      skip: $skip
      transactionType: $transactionType
      dateFrom: $dateFrom
      dateTo: $dateTo
      amountFrom: $amountFrom
      amountTo: $amountTo
      untagged: $untagged
    ) {
      id
      sum
      data: transactions {
        ...TransactionData
      }
    }
  }
  ${TRANSACTION}
  ${ACCOUNT}
`;

const GET_ALL_TRANSACTIONS = gql`
  query GetAllTransactions(
    $jobs: [ID]
    $hmrcCategories: [ID]
    $transactionType: String
    $untagged: Boolean
    $dateFrom: DateTime
    $dateTo: DateTime
    $first: Int
    $skip: Int
  ) {
    transactions(
      jobs: $jobs
      hmrcCategories: $hmrcCategories
      transactionType: $transactionType
      untagged: $untagged
      dateFrom: $dateFrom
      dateTo: $dateTo
      first: $first
      skip: $skip
    ) {
      ...TransactionData
    }
  }
  ${TRANSACTION}
`;

const SEARCH_ALL_TRANSACTIONS = gql`
  query AdvancedTransactionSearch(
    $searchTerms: [AdvancedTransactionSearchInputType]
    $first: Int
    $skip: Int
    $selfAssessmentId: ID
  ) {
    advancedTransactionSearch(
      searchTerms: $searchTerms
      first: $first
      skip: $skip
      selfAssessmentId: $selfAssessmentId
    ) {
      ...TransactionData
    }
  }
  ${TRANSACTION}
`;

const UPDATE_TRANSACTION_TAG = gql`
  mutation tagTransaction($transactionId: ID!, $tagId: ID, $jobId: ID) {
    tagTransaction(
      transactionId: $transactionId
      tagId: $tagId
      jobId: $jobId
    ) {
      ...TransactionData
    }
  }
  ${TRANSACTION}
`;

const REMOVE_TRANSACTION_TAG = gql`
  mutation untagTransaction($transactionId: ID!) {
    untagTransaction(transactionId: $transactionId) {
      ...TransactionData
    }
  }
  ${TRANSACTION}
`;

const APPROVE_TRANSACTIONS = gql`
  mutation approveTransactions($transactionIds: [ID]) {
    approveTransactions(transactionIds: $transactionIds) {
      ...TransactionData
    }
  }
  ${TRANSACTION}
`;

const UPDATE_TRANSACTION_CATEGORY = gql`
  mutation categoriseTransactions($categoryId: ID!, $transactionIds: [ID]!) {
    categoriseTransactions(
      categoryId: $categoryId
      transactionIds: $transactionIds
    ) {
      ...TransactionData
    }
  }
  ${TRANSACTION}
`;

const ADD_NEW_TRANSACTION = gql`
  mutation addManualTransaction(
    $madeOn: Date!
    $transactionType: String!
    $merchantName: String!
    $description: String!
    $currency: String!
    $amount: Decimal!
  ) {
    addManualTransaction(
      madeOn: $madeOn
      transactionType: $transactionType
      merchantName: $merchantName
      description: $description
      currency: $currency
      amount: $amount
    ) {
      ...TransactionData
    }
  }
  ${TRANSACTION}
`;

const REMOVE_MANUAL_TRANSACTION = gql`
  mutation removeManualTransaction($transactionId: ID!) {
    removeManualTransaction(transactionId: $transactionId) {
      ...TransactionData
    }
  }
  ${TRANSACTION}
`;

export {
  GET_ACCOUNTS,
  GET_ACCOUNT,
  UPDATE_ACCOUNT_SETTINGS,
  DISCONNECT_ACCOUNT,
  GET_TRANSACTIONS,
  GET_ALL_TRANSACTIONS,
  SEARCH_TRANSACTIONS,
  UPDATE_TRANSACTION_TAG,
  REMOVE_TRANSACTION_TAG,
  APPROVE_TRANSACTIONS,
  UPDATE_TRANSACTION_CATEGORY,
  ADD_NEW_TRANSACTION,
  SEARCH_ALL_TRANSACTIONS,
  REMOVE_MANUAL_TRANSACTION,
};
