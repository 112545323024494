import React, { useContext } from 'react';

import IntroText from '../IntroText';
import OptionCards from '../OptionCards';
import Button from '../Button';

import { CalculatorFormContext } from '../../providers/calculator';

const WhatDoYouWantToDoToday = () => {
  const { onUpdateFormData, formData } =
    useContext(CalculatorFormContext) || {};

  const estimatedTime =
    (formData.g_productivity ? 5 : 0) +
    (formData.g_personalSpending ? 2 : 0) +
    (formData.g_incomeAndExpense ? 5 : 0) +
    (formData.g_taxes ? 5 : 0);

  return (
    <>
      <IntroText noBottomPadding title="What do you want to do today?" />
      <OptionCards onChange={onUpdateFormData} data={formData} />

      {!!estimatedTime && (
        <>
          <p style={{ textAlign: 'center', marginBottom: '30px' }}>
            This usually takes people <br />{' '}
            <strong>around {estimatedTime} minutes</strong> to complete.
          </p>
          <Button to="/register">Continue</Button>
        </>
      )}
    </>
  );
};

export default WhatDoYouWantToDoToday;
