import React from 'react';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import CalculatorHeader from '../../components/CalculatorHeader';
import CalculatorNavigation from '../../components/CalculatorNavigation';
import Quote from '../../components/Quote';
import Scrollable from '../../components/Scrollable';
import CalculatorMenu from '../../components/CalculatorMenu';
import WhatDoYouWantToDoToday from '../../components/WhatDoYouWantToDoToday';

import useCalculator from '../../hooks/useCalculator';
import useTrackPage from '../../hooks/useTrackPage';

const Calculator = ({ step, jobId, group }) => {
  const {
    Step,
    goToNextStep,
    goToPreviousStep,
    currentStep,
    saveFormData,
    saving,
    disableNextButton,
    hideNavigtion,
    onlyShowBackButton,
    showFCA,
    loading,
    hideHeader,
    showQuote,
    pageUrl,
    formData,
    scrollable,
  } = useCalculator({ step, jobId, group });

  useTrackPage(pageUrl);

  const renderContent = () => {
    if (
      !formData.g_productivity &&
      !formData.g_personalSpending &&
      !formData.g_incomeAndExpense &&
      !formData.g_taxes
    ) {
      return <WhatDoYouWantToDoToday />;
    }

    if (!step) {
      return (
        <>
          <CalculatorHeader
            saveFormData={saveFormData}
            saving={saving}
            formData={formData}
            group={group}
            title="About you"
          />
          <CalculatorMenu step={step} jobId={jobId} group={group} />
        </>
      );
    }

    return (
      <>
        {!hideHeader && (
          <CalculatorHeader
            saveFormData={saveFormData}
            saving={saving}
            showFCA={showFCA}
            formData={formData}
            group={group}
            backButton
          />
        )}

        <Step
          currentStep={currentStep}
          step={step}
          jobId={jobId}
          group={group}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
        />

        {!hideNavigtion && (
          <CalculatorNavigation
            goToNextStep={goToNextStep}
            goToPreviousStep={goToPreviousStep}
            disableNextButton={disableNextButton}
            onlyShowBackButton={onlyShowBackButton}
          />
        )}

        {showQuote && <Quote />}
      </>
    );
  };

  if (scrollable || !step) {
    return <Layout fetching={loading}>{renderContent()}</Layout>;
  }

  return (
    <Layout fetching={loading}>
      <Scrollable>{renderContent()}</Scrollable>
    </Layout>
  );
};

Calculator.propTypes = {
  step: PropTypes.string.isRequired,
  jobId: PropTypes.string.isRequired,
  group: PropTypes.string.isRequired,
};

export default Calculator;
