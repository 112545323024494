const getAnswerValue = ({
  job,
  answerKey,
  answerFormatter,
  formData,
  jobFormData,
}) => {
  if (answerFormatter) {
    return answerFormatter(job ? jobFormData : formData);
  }

  return job ? jobFormData[answerKey] : formData[answerKey];
};

export default getAnswerValue;
