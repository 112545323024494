import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Helmet } from 'react-helmet';
import { useSpring, animated } from 'react-spring';

import { makeStyles } from '@material-ui/core/styles';

import Loader from '../Loader';

import { CookieProvider } from '../../providers/cookies';

import fca from '../../assets/fca.png';
import backgroundImage from '../../assets/backgroundImage.png';
import Scrollable from '../Scrollable';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    flex: '1 0 100%',
    background: '#EBF5FA',
    position: 'relative',
  },
  content: {
    display: 'flex',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    flex: '1 0 100%',
    maxWidth: 480,
    padding: 30,
    margin: '0 auto',
  },
  animated: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    flex: '1 0 100%',
    height: '100%',
  },
  fca: {
    margin: '0 auto',
    display: 'block',
    maxWidth: '300px',
  },
  illustration: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: '407px 400px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '-190px -190px',
  },
});

const Layout = ({
  children,
  fetching,
  animateIn,
  fadeIn,
  showIllustration,
  showFCALogo,
  scrollable,
}) => {
  const classes = useStyles();

  const style = useSpring({
    opacity: 1,
    transform: 'none',
    ...(animateIn
      ? {
          opacity: fetching ? 0 : 1,
          transform: fetching ? 'translate(0, 40px)' : 'translate(0, 1px)',
        }
      : {}),
    ...(fadeIn
      ? {
          opacity: fetching ? 0 : 1,
        }
      : {}),
  });

  const renderContent = () => {
    if (scrollable) {
      return (
        <Scrollable>
          {showFCALogo && (
            <img className={classes.fca} alt="Regulated by the FCA" src={fca} />
          )}

          {children}
        </Scrollable>
      );
    }

    return (
      <>
        {showFCALogo && (
          <img className={classes.fca} alt="Regulated by the FCA" src={fca} />
        )}

        {children}
      </>
    );
  };

  return (
    <CookieProvider>
      <div className={classes.root}>
        <Helmet>
          <title>Clarity - Financial clarity for independent workers</title>
        </Helmet>

        <main
          className={clsx(classes.content, {
            [classes.illustration]: showIllustration,
          })}
        >
          {fetching && <Loader />}

          <animated.div className={classes.animated} style={style}>
            {renderContent()}
          </animated.div>
        </main>
      </div>
    </CookieProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  fetching: PropTypes.bool,
  animateIn: PropTypes.bool,
  fadeIn: PropTypes.bool,
  showIllustration: PropTypes.bool,
  showFCALogo: PropTypes.bool,
  scrollable: PropTypes.bool,
};

Layout.defaultProps = {
  fetching: false,
  children: <></>,
  animateIn: false,
  fadeIn: false,
  showIllustration: false,
  showFCALogo: false,
  scrollable: false,
};

export default Layout;
