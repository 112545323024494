import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from '@reach/router';

import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import Button from '../Button';

import fca from '../../assets/fca.png';
import { groups } from '../../config/groups';

const useStyles = makeStyles(() => ({
  header: {
    color: '#343858',
    marginBottom: 30,
  },
  smallMargin: {
    marginBottom: 10,
  },
  inner: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',

    '& > button': {
      marginLeft: '10px',
    },
  },
  text: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '16px',
  },
  progressWrapper: {
    height: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    backgroundColor: '#ffffff',
    height: 4,
    flex: '1 0 auto',
  },
  fca: {
    margin: '20px auto -10px',
    display: 'block',
    maxWidth: '300px',
  },
  stepDot: {
    display: 'block',
    width: '10px',
    height: '10px',
    borderRadius: '100%',
    backgroundColor: '#ffffff',
    border: 'solid 4px transparent',
    flex: '0 0 10px',
    transform: 'scale(1.05)',
  },
  stepDotActive: {
    borderColor: '#4797D2',
    width: '12px',
    height: '12px',
    flex: '0 0 12px',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px',
    background: 'transparent',
    border: 'none',
    textDecoration: 'none',
    color: '#343858',

    '& > svg': {
      marginRight: '10px',
    },
  },
}));

const CalculatorHeader = ({
  saveFormData,
  saving,
  formData,
  group,
  showFCA,
  title,
  backButton,
}) => {
  const classes = useStyles();

  const activeGroupIndex = groups[formData.activeGroup]?.order || 1;
  const activeStep = formData.activeStep || 0;

  const showExpensesAndJobs =
    formData.g_productivity || formData.g_incomeAndExpense || formData.g_taxes;

  const adjustSteps = (groupName, totalSteps) => {
    if (
      groupName === 'expenses' &&
      (formData.g_productivity || formData.g_incomeAndExpense)
    ) {
      return (
        totalSteps +
        (formData?.expenses_workVehicle ? 1 : 0) +
        (formData?.expenses_accountant ? 1 : 0) +
        (formData?.expenses_phone ? 1 : 0) +
        (formData?.expenses_other ? 1 : 0)
      );
    }

    if (groupName === 'expenses' && formData.g_taxes) {
      return 4;
    }

    if (groupName === 'income') {
      return (
        totalSteps +
        (formData?.g_productivity ? 3 : 0) +
        (formData.g_productivity ||
        formData.g_incomeAndExpense ||
        formData.g_taxes
          ? 3
          : 0)
      );
    }

    if (groupName === 'jobs') {
      return totalSteps + (formData?.g_productivity ? 1 : 0);
    }

    return totalSteps;
  };

  const getValue = (groupName) => {
    const { order: groupIndex, totalSteps } = groups[groupName] || {};

    const adjustedTotalSteps = adjustSteps(groupName, totalSteps);

    if (activeGroupIndex > groupIndex) return 100;

    if (activeGroupIndex === groupIndex) {
      return Math.min(100, (activeStep / adjustedTotalSteps) * 100);
    }

    return 0;
  };

  const getActive = (groupName) => {
    if (groupName === 'results') {
      const { totalSteps } = groups.expenses || {};

      const adjustedTotalSteps = adjustSteps(groupName, totalSteps);

      if (activeStep === adjustedTotalSteps) {
        return true;
      }

      return false;
    }

    const { order: groupIndex } = groups[groupName] || {};

    if (activeGroupIndex >= groupIndex) return true;
    return false;
  };

  return (
    <header
      className={clsx(classes.header, { [classes.smallMargin]: showFCA })}
    >
      <div className={classes.inner}>
        {backButton ? (
          <Link to="/calculator" className={classes.link}>
            <svg
              width="7"
              height="13"
              viewBox="0 0 7 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.317052 7.35229L5.12877 12.6509C5.55151 13.1164 6.24778 13.1164 6.67052 12.6509C7.09325 12.1854 7.09325 11.4186 6.67052 10.9531L2.6421 6.50342L6.68295 2.05371C7.10568 1.5882 7.10568 0.821486 6.68295 0.355977C6.47158 0.123223 6.18561 1.02744e-06 5.91208 1.02418e-06C5.62611 1.02077e-06 5.35258 0.123223 5.14121 0.355977L0.317052 5.65455C-0.105684 6.12006 -0.105684 6.88678 0.317052 7.35229Z"
                fill="#4797D2"
              />
            </svg>
            <span className={classes.text}>
              {title || groups[group]?.label}
            </span>
          </Link>
        ) : (
          <span className={classes.text}>{title || groups[group]?.label}</span>
        )}

        <div className={classes.buttons}>
          <Button secondary small to="/help">
            Help
          </Button>

          <Button loading={saving} small onClick={saveFormData}>
            {saving ? 'Saving...' : 'Save'}
          </Button>
        </div>
      </div>

      <Link to="/calculator" className={classes.progressWrapper}>
        {showExpensesAndJobs && (
          <>
            <span
              className={clsx(classes.stepDot, {
                [classes.stepDotActive]: getActive('jobs'),
              })}
            />

            <LinearProgress
              className={classes.progress}
              variant="determinate"
              value={getValue('jobs')}
              color="primary"
            />
          </>
        )}
        <span
          className={clsx(classes.stepDot, {
            [classes.stepDotActive]: getActive('income'),
          })}
        />

        <LinearProgress
          className={classes.progress}
          variant="determinate"
          value={getValue('income')}
          color="primary"
        />

        {showExpensesAndJobs && (
          <>
            <span
              className={clsx(classes.stepDot, {
                [classes.stepDotActive]: getActive('expenses'),
              })}
            />

            <LinearProgress
              className={classes.progress}
              variant="determinate"
              value={getValue('expenses')}
              color="primary"
            />
          </>
        )}

        <span
          className={clsx(classes.stepDot, {
            [classes.stepDotActive]: getActive('results'),
          })}
        />
      </Link>

      {showFCA && (
        <img className={classes.fca} alt="Regulated by the FCA" src={fca} />
      )}
    </header>
  );
};

CalculatorHeader.propTypes = {
  saveFormData: PropTypes.func.isRequired,
  saving: PropTypes.bool,
  showFCA: PropTypes.bool,
  formData: PropTypes.shape({
    activeGroup: PropTypes.string.isRequired,
    activeStep: PropTypes.number.isRequired,
  }).isRequired,
  group: PropTypes.string.isRequired,
  title: PropTypes.string,
  backButton: PropTypes.bool,
};

CalculatorHeader.defaultProps = {
  saving: false,
  showFCA: false,
  title: null,
  backButton: false,
};

export default CalculatorHeader;
