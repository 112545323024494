/**
 * @format
 * @flow
 */

import gql from 'graphql-tag';

const GET_BANKS = gql`
  query GetBanks {
    banks {
      id
      name
      logo
      icon
      tint
      truelayerProvider
    }
  }
`;

const GET_TRUELAYER_URL = gql`
  query GetTruelayerURL($provider: String!) {
    truelayerUrl(provider: $provider, domain: LIVINGWAGE_MOBILE) {
      url
    }
  }
`;

export { GET_BANKS, GET_TRUELAYER_URL };
